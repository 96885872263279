import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export interface ConfirmationDialogForRemovingMonitorProps {
    id?: string;
    monitor: any;
    keepMounted: boolean;
    value?: string;
    open: boolean;
    onCancel: (value?: string) => void;
    onConfirm: (value?: string) => void;
}

export const ConfirmationDialogForRemovingMonitor = (props: ConfirmationDialogForRemovingMonitorProps): JSX.Element => {
    const { monitor, onCancel, onConfirm, value: valueProp, open, ...other } = props;

    const cancelHandler = () => {
        onCancel();
    };

    const deleteHandler = () => {
        onConfirm();
    };

    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={open}
                {...other}
            >
                <DialogTitle>Delete confirmation</DialogTitle>
                <DialogContent dividers>
                    <p>Do you really want to delete spy<b>{monitor?.title}</b>?</p>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={cancelHandler}>
                        Cancel
                    </Button>
                    <Button
                        color="error"
                        size="small"
                        variant="contained"
                        onClick={deleteHandler}>Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}