
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const MinPriceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 36 36" fill="none">
            <path d="M11.3877 13.0664C5.10168 13.0549 1.93284e-05 18.1759 1.93284e-05 24.5024C-0.0113683 30.8863 5.01058 35.9843 11.2966 35.9958C17.3092 36.168 22.7183 31.0586 22.7297 24.6287C22.7525 18.2448 17.6622 13.0664 11.3877 13.0664ZM13.2666 30.7371C12.4467 30.783 12.4467 30.783 12.4353 31.6327C12.4353 32.8383 12.5264 32.689 11.3535 32.7005C11.2738 32.7005 11.2055 32.7005 11.1257 32.7005C10.2944 32.7005 10.2944 32.7005 10.2944 31.8393C10.2944 30.7486 10.2944 30.7486 9.21262 30.7486C8.66601 30.7486 8.10802 30.7371 7.56141 30.7486C7.25394 30.7486 7.1059 30.6223 7.1059 30.3008C7.1059 29.8645 7.11729 29.4167 7.1059 28.9803C7.09452 28.6244 7.27672 28.5211 7.59557 28.5211C8.52936 28.5326 9.45176 28.5211 10.3855 28.5211C11.2624 28.5211 12.1278 28.5326 13.0047 28.5211C13.9726 28.5096 14.5534 27.924 14.542 27.0055C14.5306 26.1214 13.9499 25.6047 12.9819 25.5817C11.8318 25.5473 10.6702 25.6965 9.52008 25.4669C8.18773 25.2143 6.6504 23.8365 6.7415 21.8271C6.82121 20.1048 7.90304 18.7615 9.64534 18.4285C10.135 18.3366 10.3058 18.1185 10.2944 17.6247C10.2831 16.3388 10.135 16.4191 11.4788 16.4191C12.4239 16.4191 12.4239 16.4191 12.4239 17.4066C12.4239 18.2907 12.4239 18.2907 13.3008 18.2907C13.7335 18.2907 14.1776 18.3022 14.6104 18.2907C14.895 18.2907 15.0317 18.417 15.0203 18.704C15.0203 18.7385 15.0203 18.7844 15.0203 18.8189C15.0772 20.5297 15.0772 20.5297 13.3805 20.5297C12.4695 20.5297 11.5585 20.5297 10.6475 20.5297C9.58841 20.5297 8.90515 21.0693 8.89376 21.9075C8.88237 22.7457 9.56563 23.3083 10.6133 23.3198C11.4446 23.3198 12.2873 23.3083 13.1186 23.3198C15.157 23.3542 16.774 24.9846 16.7968 27.0055C16.8196 28.9918 15.2481 30.6337 13.2666 30.7371Z" />
            <path d="M33.8555 10.3222H29.6079V2.95085C29.6079 1.32042 28.2983 0 26.6926 0H25.44C23.823 0 22.5134 1.32042 22.5134 2.95085V10.3911C22.4109 10.4141 22.3198 10.4485 22.2287 10.483C20.7369 10.483 19.3134 10.483 18.1747 10.483C16.7626 10.483 16.569 11.8149 17.1042 12.0904C18.9377 13.009 20.2131 14.2376 20.2131 14.2376C24.9048 17.9003 24.7681 24.8354 24.7681 24.8354C25.5197 26.328 27.6036 26.4313 28.4919 25.0191L35.6547 13.652C36.5771 12.2053 35.5408 10.3222 33.8555 10.3222Z" />
        </SvgIcon>
  );
}