import type { DatePeriod } from 'shared/types'

export const DATE_PERIODS: DatePeriod[] = [
    {
        id: 0,
        name: 'Daily',
        value: {
            offset: 0,
            period: 1
        },
        enabled: true,
    },
    {
        id: 1,
        name: 'Tomorrow',
        value: {
            offset: 1,
            period: 1
        },
        enabled: false,
    },
    {
        id: 2,
        name: 'Next 7 days',
        value: {
            offset: 1,
            period: 7
        },
        enabled: false,
    },
    {
        id: 1000,
        name: 'Custom',
        value: undefined,
        enabled: true,
    },
];