
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const CompetitorsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 36 33" fill="none">
            <path d="M4.39971 30.9583C3.46056 30.9583 2.53162 30.9684 1.59247 30.9583C0.520616 30.9481 0 30.4225 0 29.3713C0 28.0977 0 26.8139 0 25.5403C0.0102081 23.4176 1.55164 21.6487 3.73618 21.2646C5.68594 20.9209 7.77861 22.1238 8.5238 24.0039C8.72797 24.5093 8.84026 25.0349 8.84026 25.5807C8.84026 26.8544 8.85046 28.1381 8.84026 29.4117C8.83005 30.3821 8.2686 30.9481 7.28862 30.9583C6.32905 30.9684 5.35928 30.9583 4.39971 30.9583Z" />
            <path d="M31.584 30.958C30.6449 30.958 29.7159 30.9681 28.7768 30.958C27.7356 30.9479 27.1639 30.392 27.1639 29.371C27.1537 28.1277 27.1537 26.8844 27.1639 25.6411C27.1843 23.4073 28.7972 21.5777 31.0328 21.2542C33.1459 20.951 35.2385 22.3257 35.8715 24.4282C35.9327 24.6404 35.9837 24.8527 35.9837 25.0751C35.994 26.6216 36.0144 28.1682 35.9837 29.7147C35.9735 30.4425 35.3713 30.9479 34.524 30.958C33.5542 30.9681 32.564 30.958 31.584 30.958Z" />
            <path d="M22.5094 0.0879377C23.4894 0.108154 24.3877 0.381073 25.1737 0.947126C25.7147 1.33123 25.8372 1.92761 25.5208 2.45323C25.2145 2.94853 24.5918 3.10015 23.9998 2.79691C23.6323 2.60485 23.275 2.4128 22.8769 2.32183C22.6217 2.26118 22.3767 2.25107 22.1215 2.33194C21.7948 2.43302 21.4784 2.55431 21.4375 2.92831C21.3865 3.33264 21.7642 3.39329 22.0398 3.50448C22.734 3.7875 23.4383 4.04021 24.1223 4.34345C25.1227 4.79831 25.9087 5.47556 26.1027 6.60766C26.3579 8.12388 25.4085 9.57944 23.8773 10.0141C22.1011 10.5195 20.5494 10.0646 19.2224 8.85166C18.6099 8.29572 18.5792 7.67912 19.0692 7.16361C19.5184 6.70874 20.1819 6.71885 20.7434 7.24447C21.3252 7.7802 22.0092 8.00258 22.7952 7.96215C23.0912 7.94193 23.3669 7.86107 23.5914 7.66901C24.01 7.31523 23.9998 6.9008 23.5608 6.57734C23.2546 6.35496 22.8973 6.23366 22.5502 6.09215C21.9888 5.85966 21.4171 5.63729 20.8557 5.4048C19.7123 4.9095 19.1407 4.0301 19.1917 2.83734C19.2326 1.74567 20.039 0.684316 21.1109 0.320424C21.56 0.168802 22.0194 0.0475053 22.5094 0.0879377Z" />
            <path d="M15.2917 32.0799C14.4342 32.0698 13.9034 31.342 14.1892 30.594C14.6077 29.5124 15.0773 28.441 15.5367 27.3796C17.4048 23.0635 19.2729 18.7473 21.141 14.4312C21.3451 13.9561 21.682 13.6528 22.223 13.6528C22.7232 13.6528 23.0499 13.9258 23.2541 14.3503C23.397 14.6636 23.3357 14.9669 23.203 15.2802C22.1005 17.8477 20.998 20.4253 19.8854 22.9927C18.7012 25.7522 17.4967 28.5016 16.3125 31.251C16.0981 31.7362 15.7715 32.0597 15.2917 32.0799Z" />
            <path d="M13.5665 6.03176C14.1178 4.38414 14.6282 2.87803 15.1284 1.38203C15.1896 1.18998 15.2509 0.997923 15.3325 0.805869C15.5673 0.249923 16.1696 -0.0229956 16.7106 0.169058C17.2925 0.37122 17.6192 0.95749 17.415 1.54376C16.5575 4.15165 15.6796 6.75954 14.8017 9.35732C14.6282 9.87284 14.1076 10.2165 13.587 10.2266C13.0561 10.2367 12.5559 9.90316 12.3518 9.33711C11.7699 7.71981 11.2085 6.09241 10.6368 4.47511C10.2999 3.52495 9.96306 2.56468 9.64661 1.61452C9.43224 0.977706 9.70786 0.381329 10.2897 0.15895C10.8614 -0.063428 11.4841 0.219599 11.7189 0.866517C12.2395 2.29176 12.7295 3.72711 13.2399 5.15235C13.3318 5.39495 13.4236 5.64765 13.5665 6.03176Z" />
            <path d="M31.6045 19.253C30.206 19.253 29.0218 18.1107 29.0423 16.7765C29.0525 15.3917 30.1651 14.2798 31.5739 14.2596C33.003 14.2393 34.1361 15.3411 34.1565 16.7664C34.1667 18.1107 32.9928 19.253 31.6045 19.253Z" />
            <path d="M4.4195 19.2529C3.05161 19.2529 1.87767 18.141 1.86747 16.8169C1.84705 15.3916 2.94953 14.2798 4.4093 14.2595C5.80781 14.2393 6.99196 15.4119 6.98175 16.7967C6.96133 18.1309 5.7976 19.2428 4.4195 19.2529Z"  />
        </SvgIcon>
  );
}