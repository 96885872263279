import { useState } from 'react';
import { useEffect } from 'react';
import { LoadingDataDialog } from './../../components/LoadingDataDialog';
import { useReadMonitorCurrentlaunchStatusQuery } from 'services/monitor';

type LoadingDataDialogWidgetProps = {
    monitorId?: number,
    open?: boolean,
    onOpenChange?: Function,
}

export const LoadingDataDialogWidget = (props: LoadingDataDialogWidgetProps): JSX.Element => {
    const {
        monitorId,
        onOpenChange,
    } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [readMonitorCurrentlaunchStatusQueryParams, setReadMonitorCurrentlaunchStatusQueryParams] = useState<any>(undefined);
    const [readMonitorCurrentlaunchStatusQueryOptions, setReadMonitorCurrentlaunchStatusQueryOptions] = useState<any>(
        {
            skip: true,
            refetchOnMountOrArgChange: true,
            pollingInterval: 5000,
        }
    );

    const {
        data: readMonitorCurrentlaunchStatusQueryData,
        isLoading: readMonitorCurrentlaunchStatusQueryIsLoading
    } = useReadMonitorCurrentlaunchStatusQuery(readMonitorCurrentlaunchStatusQueryParams, readMonitorCurrentlaunchStatusQueryOptions);

    useEffect(() => {
        setReadMonitorCurrentlaunchStatusQueryParams({
            ...readMonitorCurrentlaunchStatusQueryParams,
            monitorId: monitorId
        });

        setReadMonitorCurrentlaunchStatusQueryOptions(
            {
                ...readMonitorCurrentlaunchStatusQueryOptions,
                skip: monitorId === undefined,
            }
        );
    }, [monitorId]);

    useEffect(() => {
        if (readMonitorCurrentlaunchStatusQueryData === undefined) {
            setOpen(false);
            return;
        }

        if (readMonitorCurrentlaunchStatusQueryData !== undefined && readMonitorCurrentlaunchStatusQueryData.status === 2){
            handleOpenChange(false);
            setOpen(false);
            return;
        }

        setOpen(true);
    }, [readMonitorCurrentlaunchStatusQueryData]);

    const handleOpenChange = (value: any) => {
        setOpen(value);
    }

    return (
        <>
            <LoadingDataDialog
                open={open}
                value={readMonitorCurrentlaunchStatusQueryData?.completed + 1}
                maxValue={readMonitorCurrentlaunchStatusQueryData?.total}
                status={readMonitorCurrentlaunchStatusQueryData?.status}
                onOpenChange={handleOpenChange}
            />
        </>
    )
}