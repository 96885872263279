
import routes from './routes';
import { useRoutes } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from 'shared/hooks';
import { monitorRunResultApi } from 'services/monitorRunResult';


const Application = () => {
     //xs: 0,
     // sm: 600,
     // md: 900,
     // lg: 1200,
    // xl: 1536,
    const theme = useTheme();
    const isNarrowScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));
    const content = useRoutes(routes);

    const dispatch = useAppDispatch();

    navigator.serviceWorker.onmessage = (event) => {
        const msgType: string = event.data.type
        switch (msgType) {
            case 'SUCCESS_MONITOR_RUN': {
                dispatch(monitorRunResultApi.util.invalidateTags(['MonitorRunsGrouped']));
                break;
            }
            default: {
                break;
            }
        }
    };

    return content;
};

export default Application;