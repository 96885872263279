import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'queries'

export interface ReadMonitorRunCountParams {
    viewed?: boolean
}

export const monitorRunApi = createApi({
    reducerPath: 'monitorRunApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['MonitorRunsCount'],
    endpoints: (builder) => ({
        readMonitorRunCount: builder
            .query({
                query: (params: ReadMonitorRunCountParams, ) => {
                    let url = `/monitorruns/count`;

                    if (params?.viewed !== undefined) {
                        url += `?viewed=${params?.viewed}`
                    }

                    return url;                  
                }
            })    
    }),
});

export const { useReadMonitorRunCountQuery } = monitorRunApi;
