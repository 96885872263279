import * as React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { Monitor, MonitorFilterItem, MonitorViewModel } from '../../shared/types';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BedIcon from '@mui/icons-material/Bed';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';
import FlakyIcon from '@mui/icons-material/Flaky';
import DescriptionIcon from '@mui/icons-material/Description';
import Switch from '@mui/material/Switch';
import { updateMonitorFilter } from 'domain/monitor';
import { useUpdateMonitorMutation } from 'services/monitor';

type props = {
    monitor: MonitorViewModel;
    onDelete: (monitor: Monitor) => void;
};

export const MonitorCard = (props: props): JSX.Element => {
    const { monitor, onDelete, ...other } = props;
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [updateMonitorMutation] = useUpdateMonitorMutation();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const onDeleteHandler = async () => {
        onDelete(monitor);
    }

    const recalculateMonitor = async (monitor: Monitor, name: string, value: any): Promise<Monitor> => {
        const monitorFilterItem: MonitorFilterItem = {
            name: name,
            value: value
        };

        const newMonitor: Monitor = updateMonitorFilter({ monitor: monitor, monitorFilterItem });
        return newMonitor;
    } 

    const handleEnabledValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;

        if (monitor !== null) {
            const newMonitor: Monitor = await recalculateMonitor(monitor, 'enabled', newValue);
            const updateMonitorByIdParams: any = {
                monitor: newMonitor
            }
            await updateMonitorMutation(updateMonitorByIdParams);
        }
    };

    return (
        <>
            <Card variant="outlined" sx={{ maxWidth: 385 }}>
                <CardContent sx={{ p: 0 }}>  
                    <Toolbar sx={{
                        bgcolor: '#648AAF',
                        paddingRight: { xs: '12px', md: '24px' },
                        paddingLeft: { xs: '12px', md: '24px' },
                    }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Link to={`/spy/${monitor.id}/monitorrun`} style={{color: '#FFF'}}>
                                <Box sx={{ fontWeight: '700', m: 0, color: '#FFF', fontSize: { xs: '16px', md: '18px' } }}>
                                    {monitor.title}
                                </Box>
                            </Link>
                        </Box>
                        <IconButton
                            ref={anchorRef}
                            size="large"
                            edge="start"
                            color="default"
                            aria-label="menu"
                            sx={{ mr: 0 }}
                            onClick={handleToggle}
                        >
                            <MoreVertIcon sx={{ color: '#FFF' }} />
                        </IconButton>
                    </Toolbar>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <List component="nav">
                                            <ListItemButton onClick={onDeleteHandler}>
                                                <ListItemIcon>
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Delete" />
                                            </ListItemButton>
                                            <ListItemButton component={Link} to={`/monitor/${monitor.id}`}>
                                                <ListItemIcon>
                                                    <EditIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Edit" />
                                            </ListItemButton>
                                            <ListItemButton component={Link} to={`/monitor/${monitor.id}/monitorrun`}>
                                                <ListItemIcon>
                                                    <AnalyticsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Results" />
                                            </ListItemButton>
                                        </List>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DescriptionIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText secondaryTypographyProps={{
                                sx: {
                                    minHeight: '22px'
                                }
                            }} primary="Description" secondary={monitor.description} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PlaceIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Location" secondary={ monitor.locations.join(', ') } />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <WorkIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Platform" secondary={monitor.platform.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <BeachAccessIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Bedrooms" secondary={monitor.bedroomsLabel} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <BedIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Beds" secondary={monitor.bedsLabel} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PetsIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Pets" secondary={monitor.petsLabel} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Period" secondary={monitor.period} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <FlakyIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Enabled" secondary={
                                <Switch
                                    color="success"
                                    checked={monitor.enabled}
                                    onChange={handleEnabledValueChange}
                                />
                            }
                            />
                        </ListItem>
                    </List>
                    <Divider variant="middle" />
                </CardContent>
                <CardActions>
                    <Stack direction="row" spacing={2}>
                        <Button
                            color="error"
                            size="small"
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={onDeleteHandler}
                            sx={{
                                minWidth: {
                                    sx: 70,
                                    md: 110
                                }
                            }}
                        >
                            Delete
                        </Button >
                        <Link to={`/spy/${monitor.id}`}>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                startIcon={<EditIcon />}
                                sx={{
                                    minWidth: {
                                        sx: 70,
                                        md: 110
                                    }
                                }}
                            >
                                Edit
                            </Button >
                        </Link>
                        <Link to={`/spy/${monitor.id}/monitorrun`}>
                            <Button
                                color="success"
                                size="small"
                                variant="contained"
                                startIcon={<AnalyticsIcon />}
                                sx={{
                                    minWidth: {
                                        sx: 70,
                                        md: 110
                                    }
                                }}
                            >
                                Results
                            </Button >
                        </Link>
                    </Stack>
                </CardActions>
            </Card>
        </>
    )
}