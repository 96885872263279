import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { CalendarProps, SubCalendarProps } from '../../Calendar';
import { CircularProgress } from '@mui/material';
import { Backdrop } from '@mui/material';

export const DesktopCalendar = (props: SubCalendarProps): JSX.Element => {
    const {
        onMonthDecrease,
        onMonthIncrease,
        startDate,
        endDate,
        loading = false,
        sxCalendar,
        slots
    } = props;

    const onMonthDecreaseHandler = (date: any) => {
        if (onMonthDecrease === undefined) {
            return;
        }
        
        onMonthDecrease(date);
    }

    const onMonthIncreaseHandler = (date: any) => {
        if (onMonthIncrease === undefined) {
            return;
        }

        onMonthIncrease(date);
    }

    const Day = slots?.day ?? PickersDay;

    return (
        <>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'max-content max-content',
                position: 'relative',
                gap: '60px'
            }}>
                <Box sx={{
                    display: 'grid',
                }}>
                    <Box sx={
                        {
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }>
                        <IconButton
                            onClick={ onMonthDecreaseHandler }
                            sx={
                                {
                                    position: 'absolute',
                                    left: '0'
                                }
                            }
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <h2>{startDate.toFormat('MMMM yyyy') }</h2>
                    </Box>
                    <Box>
                    <DateCalendar
                        //disableHighlightToday
                            //onMonthChange={ startCalendarHandleMonthChange }
                            dayOfWeekFormatter={(day, date) => {
                                return date.toFormat('ccc').substring(0, 2);
                            }}
                        sx={sxCalendar}
                        value={startDate}
                        loading={loading}
                        slots={{
                            calendarHeader: () => (null),
                            day: Day
                        }}
                        slotProps={{
                            //TODO
                        }}
                        />
                    </Box>
                </Box>
                <Box>
                    <Box sx={
                        {
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }>
                        <h2>{endDate.toFormat('MMMM yyyy')}</h2>
                        <IconButton
                            onClick={onMonthIncreaseHandler }
                            sx={
                                {
                                    position: 'absolute',
                                    right: '0'
                                }
                            }
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <DateCalendar
                            //disableHighlightToday
                            dayOfWeekFormatter={(day, date) => {
                                return date.toFormat('ccc').substring(0, 2);
                            }}
                        sx={sxCalendar}
                        loading={loading}
                        value={endDate}
                        slots={{
                            calendarHeader: () => (null),
                            day: Day 
                        }}
                        slotProps={{
                            calendarHeader: {

                            },
                        }}
                        ></DateCalendar>
                    </Box>
                </Box>
        
                    <Backdrop
                        sx={{
                            color: '#fff',  height: '100%', position: 'absolute', width: '100%',
                             }}
                        open={loading}    
                    >
                        <CircularProgress size="6rem" color="success" />
                    </Backdrop>               
            
            </Box>
        </>
    )
}