import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IosShareIcon from '@mui/icons-material/IosShare';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Button } from '@mui/material';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


type IphoneAndIpadInstallInstructionProps = {
    mode: string,
    subscribed?: boolean,
    onRequestEnableNotifications?: Function,
    onRequestDisableNotifications?: Function,
    onModeChanged?: Function,
}

export const IphoneAndIpadInstallInstruction = (props: IphoneAndIpadInstallInstructionProps): JSX.Element => {
    const {
        subscribed = false,
        onRequestEnableNotifications,
        onRequestDisableNotifications,
        onModeChanged,
        mode = 'short'
    } = props;

    const handleEnableNotificationChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (typeof onRequestEnableNotifications === "function") {
                onRequestEnableNotifications();
            }    

            return;
        }

        if (typeof onRequestDisableNotifications === "function") {
            onRequestDisableNotifications();
        }
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: 'max(20vh, 400px)'
    }

    const imageListContainerStyle = {
        display: 'flex',
        gap: '20px'
    }

    const handleModeChanged = (event: any) => {
        if (typeof onModeChanged === "function") {
            onModeChanged({
                value: mode === 'short' ? 'detailed' : 'short'
            });
        }    
    }

    const renderActionButton = () => {
        return (
            <IconButton aria-label="" onClick={handleModeChanged}>
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    startIcon={<ReadMoreIcon />}
                    sx={{
                        minWidth: {
                            sx: 70,
                            md: 110
                        }
                    }}
                >
                    {mode === 'short' ? 'More visual details' : 'Less visual details'}
                </Button >
            </IconButton>
        )
    }

    const renderContent = () => {
        if (mode === 'short') {
            return (
                <List sx={{ listStyle: "decimal", pl: 4 }}>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <Box component="p" sx={{ m: 0 }}>On your iPhone or iPad, open Chrome or Safari</Box>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <Box component="p" sx={{ m: 0 }}>Go to a <a href="https://app.strspy.com">https://app.strspy.com</a>.</Box>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <Box component="p" sx={{ m: 0 }}>Tap Share Icon <IosShareIcon fontSize={'large'} color={'primary'} /></Box>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <Box component="p" sx={{ m: 0 }}>Find and tap Add to Home Screen.</Box>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <Box component="p" sx={{ m: 0 }}>Confirm or edit the website details and tap Add.</Box>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <Box component="p" sx={{ m: 0 }}>To run STRSpy please touch this icon</Box>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", m: 0 }}>
                        <FormControlLabel sx={{ m: 0 }} labelPlacement="start" control={<Switch checked={subscribed} color="success" onChange={handleEnableNotificationChanged} />} label="Enable Notifications" />
                    </ListItem>
                </List>
            )
        }

        return (
            <List sx={{ listStyle: "decimal", pl: 4 }}>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <Box component="p" sx={{ m: 0 }}>On your iPhone or iPad, open Chrome or Safari</Box>
                </ListItem>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <Box component="p" sx={{ m: 0 }}>Go to a <a href="https://app.strspy.com">https://app.strspy.com</a>.</Box>
                    <picture>
                        <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/ios/go-to-a-app-site-0001-0000-0000-1x.png" alt="" loading="lazy" />
                    </picture>
                </ListItem>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <Box component="p" sx={{ m: 0 }}>Tap Share Icon <IosShareIcon fontSize={'large'} color={'primary'} /></Box>

                    <div style={imageListContainerStyle}>
                        <picture>
                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/ios/tap-share-ipad-0000-0000-0000-1x.png" alt="" loading="lazy" />
                        </picture>
                        <picture>
                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/ios/tap-share-iphone-0000-0000-0000-1x.png" alt="" loading="lazy" />
                        </picture>
                    </div>
                </ListItem>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <Box component="p" sx={{ m: 0 }}>Find and tap Add to Home Screen.</Box>
                    <div style={imageListContainerStyle}>
                        <picture>
                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/ios/find-and-tap-add-to-home-screen-ipad-0000-0000-0000-1x.png" alt="" loading="lazy" />
                        </picture>
                    </div>

                </ListItem>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <Box component="p" sx={{ m: 0 }}>Confirm or edit the website details and tap Add.</Box>
                    <div style={imageListContainerStyle}>
                        <picture>
                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/ios/confirm-add-to-home-screen-0000-0000-0000-1x.png" alt="" loading="lazy" />
                        </picture>
                    </div>
                </ListItem>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <Box component="p" sx={{ m: 0 }}>To run STRSpy please touch this icon</Box>
                </ListItem>
                <ListItem sx={{ display: "list-item", m: 0 }}>
                    <FormControlLabel sx={{ m: 0 }} labelPlacement="start" control={<Switch checked={subscribed} color="success" onChange={handleEnableNotificationChanged} />} label="Enable Notifications" />
                </ListItem>
            </List>
        )
    }

    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="div" sx={{ fontWeight: 600, mb: 3 }}>
                        How to install StrSpy on your iPhone & iPad
                    </Typography>
                    <Accordion defaultExpanded>
                        <AccordionSummary id="panel-header" aria-controls="panel-content">
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                Instructions
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderContent()}
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
                <CardActions disableSpacing>
                    {renderActionButton()}
                </CardActions>
            </Card>
        </Box>
    )
}