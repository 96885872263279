import { http } from 'apiClient';

export interface ReadHostServiceByIdParams {
    hostServiceId: number;
    monitorId: number;
}

export const readHostServiceById = async ({ hostServiceId, monitorId }: ReadHostServiceByIdParams): Promise<any> => {
    let url = `/hostservices/${hostServiceId}?monitorId=${monitorId}`;

    const response = await http.get(url);
    return response.data;
}

export interface ReadHostServicePricesParams {
    hostServiceId: number;
    monitorId?: number
}

export const readHostServicePrices = async ({ hostServiceId, monitorId }: ReadHostServicePricesParams): Promise<any> => {
    if (monitorId !== undefined) {
        const url = `monitor/${monitorId}/hostservices/${hostServiceId}/prices`;
        const response = await http.get(url);
        return response.data;
    }
}

export const hostServiceService = {
    readHostServiceById,
    readHostServicePrices
}
