import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAppDispatch } from 'shared/hooks';
import { useAppSelector } from 'shared/hooks';
import { readHostServicePrices } from 'store/hostServiceDetailSlice';
import { selectHostServiceDetail } from 'store/hostServiceDetailSlice';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useReadHostServicePricesByMonitorIdQuery } from 'services/hostService';
import { DateTime } from 'luxon';
import { ToggleButton } from '@mui/material';
import { ToggleButtonGroup } from '@mui/material';
import { HostServiceDetailPriceListChart } from './HostServiceDetailPriceListChart';
import type { ReadHostServicePricesThunkParams } from 'store/hostServiceDetailSlice';
import type { GridColDef } from '@mui/x-data-grid';
import type { GridValueGetterParams } from '@mui/x-data-grid';
import type { ReadHostServicePricesByMonitorIdParams } from 'services/hostService';

export type HostServiceDetailPricesProps = {
    hostServiceId: number,
    monitorId?: number
}

export const HostServiceDetailPrices = ({ hostServiceId, monitorId }: HostServiceDetailPricesProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const hostServiceDetail = useAppSelector(selectHostServiceDetail);
    const [skip] = useState<boolean>(monitorId === undefined || monitorId === -1);
    const [viewMode, setViewMode] = useState<string>('table');


    const columns: GridColDef[] = [
        {
            field: 'period',
            headerName: 'Period',
            width: 210,
            valueGetter: (params: GridValueGetterParams) => {
                const dateTimeCheckIn = DateTime.fromISO(params.row.checkin).toFormat('M/dd/yyyy');
                const dateTimeCheckOut = DateTime.fromISO(params.row.checkout).toFormat('M/dd/yyyy');

                return `${dateTimeCheckIn} - ${dateTimeCheckOut}`
            }
        },
        {
            field: 'priceTotal',
            minWidth: 170,
            flex: 1,
            headerName: 'Price Total',
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.priceTotal === undefined || params.row.priceTotal === null) {
                    return '';
                }

                return `$${(params.row.priceTotal)}`;
            }
        },
        {
            field: 'originalPrice',
            minWidth: 170,
            flex: 1,
            headerName: 'Original Price',
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.originalPrice === undefined || params.row.originalPrice === null) {
                    return '';
                }

                return `$${(params.row.originalPrice)}`;
            }
        },
        {
            field: 'discountedPrice',
            minWidth: 170,
            flex: 1,
            headerName: 'Discounted Price',
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.discountedPrice === undefined || params.row.discountedPrice === null) {
                    return '';
                }

                return `$${(params.row.discountedPrice)}`;
            }
        },
    ];

    const rows: any = hostServiceDetail.hostServicePrices || [];

    useEffect(() => {
        if (hostServiceId === undefined) {
            return;
        }

        const readHostServicePricesThunkParams: ReadHostServicePricesThunkParams = {
            hostServiceId: hostServiceId
        }

        if (monitorId !== undefined) {
            readHostServicePricesThunkParams.monitorId = monitorId;
        }

        dispatch(readHostServicePrices(readHostServicePricesThunkParams))
    }, [])

    const toggleSelectorRender = () => {
        return (
            <>
                <ToggleButtonGroup
                    color="primary"
                    value={viewMode}
                    exclusive
                    onChange={viewModeChanged}
                    aria-label="view"
                >
                    <ToggleButton value="table">Table</ToggleButton>
                    <ToggleButton value="chart">Chart</ToggleButton>
                </ToggleButtonGroup>
            </>
        )
    }


    const parametersViewRender = () => {
        if (viewMode === 'table') {
            return (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    sx={{
                        maxHeight: 400,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            },
                            fontWeight: '600'
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            }
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    />
            );
        }

        if (viewMode === 'chart') {
            return (
                <>
                    <HostServiceDetailPriceListChart
                        data={rows}
                    ></HostServiceDetailPriceListChart>
                </>
            )
        }

        return (
            <>

            </>
        )
    }


    const viewModeChanged = (
        event: React.MouseEvent<HTMLElement>,
        newViewMode: string,
    ) => {
        setViewMode(newViewMode);
    };

    return (
        <>
            <Container>
                <h2>Prices</h2>
                <Box p={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        toggleSelectorRender()
                    }
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    {
                        parametersViewRender()
                    }              
                </Box>
            </Container>
        </>
    )
}
