import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Container } from '@mui/material';
import { Calendar } from 'components/Calendar';
import { useAppSelector } from 'shared/hooks';
import { readHostService } from 'store/hostServiceDetailSlice';
import { selectHostServiceDetail } from 'store/hostServiceDetailSlice';
import { DateTime } from 'luxon';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Typography';

export type HostServiceDetailAvailabilityProps = {

}

export const HostServiceDetailAvailability = ({ }: HostServiceDetailAvailabilityProps): JSX.Element => {
    const hostServiceDetail = useAppSelector(selectHostServiceDetail);
    const [available, setAvailable] = useState<DateTime[]>([]);
    const [unavailable, setUnavailable] = useState<DateTime[]>([]);

    const isSameDay = (a: DateTime, b: DateTime): boolean => {
        return a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
    };

    useEffect(() => {
        if (hostServiceDetail === undefined
            || hostServiceDetail.hostService === undefined
            || hostServiceDetail.hostService.datesAvailable === undefined
            || hostServiceDetail.hostService.datesUnavailable === undefined
        ) {
            return;
        }

        const available: DateTime[] = [];
        hostServiceDetail.hostService.datesAvailable.forEach((date) => {
            available.push(DateTime.fromISO(date));
        });

        const unvailable: DateTime[] = [];
        hostServiceDetail.hostService.datesUnavailable.forEach((date) => {
            unvailable.push(DateTime.fromISO(date));
        });

        setAvailable(available);
        setUnavailable(unvailable)
    }, [hostServiceDetail])

    const customDay = (props: PickersDayProps<DateTime>) => {
        const updatedProps = { ...props, selected: false }

        const isAvailableDay = available.some((date) => {
            return isSameDay(date, props.day);
        });

        if (isAvailableDay) {      
            const matchedStyles = { backgroundColor: "#2e7d32", color: '#FFF' }
            return <PickersDay {...updatedProps} sx={{ ...matchedStyles }} />;
        }

        const isUnvailableDay = unavailable.some((date) => {
            return isSameDay(date, props.day);
        });

        if (isUnvailableDay) {
            const matchedStyles = { backgroundColor: "#c62828", color: '#FFF' }
            return <PickersDay {...updatedProps} sx={{ ...matchedStyles }} />;
        }

        return <PickersDay {...updatedProps} />;
    };

    return (
        <>
           <Container>
                <h2>Availability</h2>
                <Calendar
                    loading={hostServiceDetail===undefined}
                    slots={{
                        calendarHeader: () => (null),
                        day: customDay
                    }}
                />
                <Box component="div">
                    <Box component="ul" sx={{
                        listStyle: 'none',
                        display: 'flex',
                        gap: '10px'
                    } }>
                        <Box component="li" sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                height: '30px',
                                width: '30px',
                                background: '#2e7d32',
                                //border: '2px solid black'
                            }}>
                            </Box>
                            <Typography component="span" sx={{marginLeft: '10px'}}>Available</Typography>
                        </Box>
                        <Box component="li" sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                height: '30px',
                                width: '30px',
                                background: '#c62828',
                                //border: '2px solid black'
                            }}>
                            </Box>
                            <Typography component="span" sx={{ marginLeft: '10px' }}>Booked</Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
