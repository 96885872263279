import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Layout } from './components/Layout';
import { MainLayout } from './layouts/MainLayout';

import {
    AboutPage,
    HomePage,
    HostServiceDetailPage,
    MonitorListPage,
    MonitorCreatePage,
    MonitorEditPage,
    MonitorRunResultDetailPage,
    MonitorRunResultListPage,
    MonitorsRunResultListPage,
    RecommendationsSettingsPage,
    NotificationsSettingsPage,
    NotificationListPage,
    InstallInstructionPage
} from './pages';

import type { RouteObject } from 'react-router';


const routes: RouteObject[] = [
    {
        element: <MainLayout />,
        children: [
        {
            path: "/",
                element: <HomePage />,
        },
        ],
    },
    {
        element: <MainLayout />,
        loader: async () => {
            function timeout(ms: any) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            async function sleep() {
                await timeout(5000);

            }

            await sleep();
        },
        children: [
            {
                path: "about",
                element: <AboutPage />,
            },          
            {
                path: "hostservice/:hostServiceId",
                element: <HostServiceDetailPage />,
            },
            {
                path: "recommendations",
                element: <RecommendationsSettingsPage />,
            },
            {
                path: "notifications",
                element: <NotificationListPage />,
            },
            {
                path: "notifications/settings",
                element: <NotificationsSettingsPage />,
            },
            {
                path: "spy",
                element: <MonitorListPage />,
            },
            {
                path: "spy/:id",
                element: <MonitorEditPage />,
            },
            {
                path: "spy/create",
                element: <MonitorCreatePage />,
            },
            {
                path: "spy/edit",
                element: <MonitorEditPage />,
            },
            {
                path: "spy/:monitorId/monitorrun",
                element: <MonitorRunResultListPage />,
            },
            {
                path: "results",
                element: <MonitorsRunResultListPage />,
            },
            {
                path: "results/:monitorId",
                element: <MonitorRunResultDetailPage />,
            },
            {
                path: "/install/instruction",
                element: <InstallInstructionPage />,
            },

        ],
    },
    ...ApiAuthorzationRoutes,
];

export default routes;
