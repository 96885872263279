import { mainApi } from 'services/mainApi';

export const locationApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        readLocationTips: builder.query({
            query: () => {
                let url = `/locations`;
                return url;
            },
            providesTags: (result, error, arg) => ['Monitor'],  
        }),      
    }),
});

export const {
    useReadLocationTipsQuery,
} = locationApi;
