import { http } from 'apiClient';

import { readMonitorById } from 'services/monitor'
import { readMonitors } from 'services/monitor'
import { readMonitorRuns } from 'services/monitorRun'

import type { ReadMonitorRunsParams } from 'services/monitorRun'
import type { Monitor } from 'shared/types'
import type { MonitorRun } from 'shared/types'
import type { MonitorRunResult } from 'shared/types'
import type { MonitorRunResultDetail } from 'shared/types'
import type { ReadMonitorRunsResponse } from 'shared/types'
import type { ReadMonitorsResponse } from 'shared/types'
import type { ReadMonitorRunResultsResponse } from 'shared/types'
import type { ReadMonitorRunResultHostServicesResponse } from 'shared/types'
import type { ReadMonitorRunResultHostServicesRawResponse } from 'shared/types'
import type { ReadMonitorRunResultsByMonitorIdResponse } from 'shared/types'

export interface ReadMonitorRunResultsParams {
    pagination: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRunResults = async (params: ReadMonitorRunResultsParams | undefined = undefined): Promise<ReadMonitorRunResultsResponse> => {
    let readMonitorRunsParams: ReadMonitorRunsParams | undefined = undefined;

    if (params?.pagination) {
        readMonitorRunsParams = {
            pagination: params.pagination
        }
    }

    const readMonitorRunsResponse: ReadMonitorRunsResponse = await readMonitorRuns(readMonitorRunsParams);
    const readMonitorsResponse: ReadMonitorsResponse = await readMonitors();
    const result: ReadMonitorRunResultsResponse = {
        count: readMonitorRunsResponse.count,
        list: [],
    }

    for (let i = 0; i < readMonitorRunsResponse.list.length; i++) {
        const monitorRun: MonitorRun = readMonitorRunsResponse.list[i];
        const foundMonitor: Monitor | undefined = readMonitorsResponse.list.find((monitor: Monitor) => monitor.id === monitorRun.monitorId);

        const monitorRunResult: MonitorRunResult = {
            id: monitorRun.id,
            averagePrice: monitorRun.averagePrice,
            maxPrice: monitorRun.maxPrice,
            minPrice: monitorRun.minPrice,
            checkinUtc: monitorRun.checkinUtc,
            checkOutUtc: monitorRun.checkOutUtc,
            monitor: foundMonitor,
            monitorId: monitorRun.monitorId,
            resultsCount: monitorRun.resultsCount,
        };

        result.list.push(monitorRunResult);
    }

    return result;
};

export const readMonitorRunResultsByMonitorId = async ({ monitorId }: any): Promise<ReadMonitorRunResultsByMonitorIdResponse> => {
    const response = await http.get(`/monitor/${monitorId}/monitorruns`);
    const monitorrunsResultsReponse = response.data;
    const readMonitorByIdResponse: Monitor = await readMonitorById({ id: monitorId });

    const result: ReadMonitorRunResultsResponse = {
        count: monitorrunsResultsReponse.count,
        list: [],
    }

    for (let i = 0; i < monitorrunsResultsReponse.list.length; i++) {
        const monitorRun: MonitorRun = monitorrunsResultsReponse.list[i];

        const monitorRunResult: MonitorRunResult = {
            id: monitorRun.id,
            averagePrice: monitorRun.averagePrice,
            maxPrice: monitorRun.maxPrice,
            minPrice: monitorRun.minPrice,
            checkinUtc: monitorRun.checkinUtc,
            checkOutUtc: monitorRun.checkOutUtc,
            monitor: readMonitorByIdResponse,
            monitorId: monitorRun.monitorId,
            resultsCount: monitorRun.resultsCount,
        };

        result.list.push(monitorRunResult)
    }

    return result;
};

export const readMonitorRunResult = async ({ monitorRunId }: any): Promise<any> => {
    const response = await http.get(`/monitorruns/${monitorRunId}/results`);
    const result: ReadMonitorRunResultsByMonitorIdResponse = response.data;

    return result;
}

export interface ReadMonitorRunResultHostServicesByMonitorRunResultIdParams {
    monitorRunId: number,
    pagination?: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRunResultHostServicesByMonitorRunResultId =
    async (params: ReadMonitorRunResultHostServicesByMonitorRunResultIdParams): Promise<ReadMonitorRunResultHostServicesResponse> => {
        let url = `/monitorruns/${params.monitorRunId}/results`;

        if (params?.pagination) {
            const skip: number = params.pagination.page * params.pagination.pageSize;
            const take: number = params.pagination.pageSize;
            url = `/monitorruns/${params.monitorRunId}/results?skip=${skip}&take=${take}`;
        }

        const response = await http.get(url);
        const data: ReadMonitorRunResultHostServicesRawResponse = response.data;

        const result: ReadMonitorRunResultHostServicesResponse = {
            count: data.count,
            list: data.list
        };

        return result;
}

export const readMonitorRunResultFullInformation = async ({ id }: any): Promise<MonitorRunResultDetail> => {
    const monitorRunResultDetail: MonitorRunResultDetail = {
        id: id,
    }

    return monitorRunResultDetail;
}

export const monitorRunResultService = {
    readMonitorRunResultFullInformation,
    readMonitorRunResultsByMonitorId,
    readMonitorRunResults,
    readMonitorRunResultHostServicesByMonitorRunResultId
}