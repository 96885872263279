import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { Calendar } from 'components/Calendar'; 
import { DateTime } from 'luxon';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { MonitorRunResultHostService } from 'shared/types';

export interface CalendarDialogProps {
    id?: string;
    monitor?: any;
    keepMounted: boolean;
    loading?: boolean;
    value?: MonitorRunResultHostService;
    open: boolean;
    fullScreen: boolean,
    onClose: () => void;
}

export const CalendarDialog = (props: CalendarDialogProps): JSX.Element => {
    const { monitor, onClose, value: valueProp, open, keepMounted, loading = false, ...other } = props;

    const [available, setAvailable] = useState<DateTime[]>([]);
    const [unavailable, setUnavailable] = useState<DateTime[]>([]);
    const isSameDay = (a: DateTime, b: DateTime): boolean => {
        return a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
    };

    useEffect(() => {
        if (valueProp === undefined
            || valueProp.datesAvailable === undefined
            || valueProp.datesUnavailable === undefined
        ) {
            return;
        }

        const available: DateTime[] = [];
        valueProp.datesAvailable.forEach((date) => {
            available.push(DateTime.fromISO(date));
        });

        const unvailable: DateTime[] = [];
        valueProp.datesUnavailable.forEach((date) => {
            unvailable.push(DateTime.fromISO(date));
        });

        setAvailable(available);
        setUnavailable(unvailable)
    }, [valueProp])

    const customDay = (props: PickersDayProps<DateTime>) => {
        const updatedProps = { ...props, selected: false }

        const isAvailableDay = available.some((date) => {
            return isSameDay(date, props.day);
        });


        if (isAvailableDay) {
            const matchedStyles: any = { backgroundColor: "#2e7d32", color: '#FFF' }
            matchedStyles['&: hover'] = {
                backgroundColor: '#648AAF'
            };
            return <PickersDay {...updatedProps} sx={{ ...matchedStyles }} />;
        }

        const isUnvailableDay = unavailable.some((date) => {
            return isSameDay(date, props.day);
        });

        if (isUnvailableDay) {
            const matchedStyles:any = { backgroundColor: "#c62828", color: '#FFF' }
            matchedStyles['&: hover'] = {
                backgroundColor: '#648AAF'
            };

            return <PickersDay {...updatedProps} sx={{ ...matchedStyles }} />;
        }

        return <PickersDay {...updatedProps} />;
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: 'auto', maxHeight: '590px' } }}
            keepMounted
            maxWidth="xs"
            open={open}

            {...other}
        >
            <DialogContent dividers>
                <Calendar
                    loading={loading}
                    slots={{
                    calendarHeader: () => (null),
                    day: customDay,

                }}></Calendar>
                <Box component="div">
                    <Box component="ul" sx={{
                        listStyle: 'none',
                        display: 'flex',
                        gap: '10px'
                    }}>
                        <Box component="li" sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                height: '30px',
                                width: '30px',
                                background: '#2e7d32',
                                //border: '2px solid black'
                            }}>
                            </Box>
                            <Typography component="span" sx={{ marginLeft: '10px' }}>Available</Typography>
                        </Box>
                        <Box component="li" sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                height: '30px',
                                width: '30px',
                                background: '#c62828',
                                //border: '2px solid black'
                            }}>
                            </Box>
                            <Typography component="span" sx={{ marginLeft: '10px' }}>Booked</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    )
}