import React from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { MonitorRunResultDetailCardHeader } from './components/MonitorRunResultDetailCardHeader';
import { MonitorWidget } from './widgets/MonitorCardWidget';
import { RecommendationListWidget } from './widgets/RecommendationListWidget';
import { MonitorRunResultDetailCardHostServices } from './components/MonitorRunResultDetailCardHostServices/MonitorRunResultDetailCardHostServices';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAppDispatch } from 'shared/hooks';
import { setMonitorRunForCompetitors } from 'store/applicationSlice';
import { MonitorLaunchTask } from './components/MonitorLaunchTask';
import { createBlankUserSettings } from 'domain/userSettings';
import { useReadUserSettingsQuery } from 'services/user';
import { LoadingDataDialog } from './components/LoadingDataDialog';
import { CalendarDialogWidget } from './widgets/CalendarDialogWidget';
import { LoadingDataDialogWidget } from './widgets/LoadingDataDialogWidget';
import { MonitorRunResultDetailCardParameters } from './components/MonitorRunResultDetailCardParameters';
import { useReadMonitorRunResultGroupedByMonitorIdQuery } from 'services/monitorRunResult';
import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import type { ReadMonitorRunResultParams } from 'services/monitorRunResult';
import type { MonitorRunsResultDetailCard, UserSettings } from 'shared/types';
import type { MonitorRun } from 'shared/types';

type MonitorRunResultDetailCardProps = {
    loading?: boolean,
    monitorId?: number,
    monitorRunsResultDetailCard: MonitorRunsResultDetailCard
}

export const MonitorRunResultDetailCard = (props: MonitorRunResultDetailCardProps): JSX.Element => {
    const {
        monitorId,
        monitorRunsResultDetailCard
    } = props;

    const [showCompetitors, setShowCompetitors] = useState<Boolean>(false);
    const [selectedMonitorRun, setSelectedMonitorRun] = useState<MonitorRun | undefined>(undefined);
    const dispatch = useAppDispatch();
    const [monitorLaunchTask, setMonitorLaunchTask] = useState<any>(undefined);
    const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined);
    const [hasUserSettings, setHasUserSettings] = useState<boolean>(false);
    const [skip, setSkip] = useState<boolean>(true);
    const [open, setOpen] = useState(true);
    const [openCalendarDialog, setOpenCalendarDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [firstInit, setFirstInit] = useState<Boolean>(false);
    const summaryRef = useRef<null | any>(null);

    const onCompetitorsVisibileChangedHandler = (param: any) => {
        setSelectedMonitorRun(param.value.monitorRun);
        setShowCompetitors(param.value.visible)
    }

    const useReadUserSettingsQueryOptions = {
        skip: skip
    }

    const scrollToSummary = () => {
        if(summaryRef !== null && summaryRef.current !== undefined) {
            window.scrollTo({
                top: summaryRef.current.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
        }
    }

    const {
        data: readUserSettingsData,
        isLoading: readUserSettingsIsLoading,
        isFetching: readUserSettingsIsFetching,
        error: readUserSettingsError
    } = useReadUserSettingsQuery(useReadUserSettingsQueryOptions);


    const handleSelectedMonitorLaunchTaskChange = (params: any) => {
        if (params === undefined) {
            setMonitorLaunchTask(undefined);
            return;
        }

        if (monitorRunsResultDetailCard === undefined) {
            setMonitorLaunchTask(undefined);
            return;
        }

        setMonitorLaunchTask({
            monitor: monitorRunsResultDetailCard,
            monitorRunsResult: params.monitorLaunchTask || params
        });
    }   

    useEffect(() => {
        setShowCompetitors(false);
        dispatch(setMonitorRunForCompetitors(undefined));
    }, [monitorRunsResultDetailCard]);


    useEffect(() => {
        if (readUserSettingsError !== undefined
            && 'data' in readUserSettingsError
            && readUserSettingsError.status === 404
            && readUserSettingsIsFetching === false) {
            setHasUserSettings(false);
            setUserSettings(createBlankUserSettings());
        } else {
            setHasUserSettings(true)
            setUserSettings(readUserSettingsData)
        }
    }, [readUserSettingsIsFetching, readUserSettingsError]);

    const readMonitorRunResultByMonitorIdQueryParams: ReadMonitorRunResultParams = {
        monitorId: monitorId,
    };

    const readMonitorRunResultByMonitorIdQueryOptions = {
        skip: skip,
        refetchOnMountOrArgChange: true,
        pollingInterval: 5000,
    };

    const {
        data: readMonitorRunResultGroupedByMonitorIdData,
        isLoading: readMonitorRunsResultDataIsLoading,
        isFetching: readMonitorRunsResultDataIsFetching,
        refetch
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        readMonitorRunResultByMonitorIdQueryParams,
        readMonitorRunResultByMonitorIdQueryOptions
        );

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData !== undefined && readMonitorRunResultGroupedByMonitorIdData.list.length === 0) {
            setOpen(true);
            return;
        }

        setOpen(false);
    }, [readMonitorRunResultGroupedByMonitorIdData]);


    useEffect(() => {
        setSkip(monitorId === undefined || monitorId === -1);
        setFirstInit(false);
    }, [monitorId]);


    const [progressValue, setProgressValue] = useState<any>({
        value: 0,
        maxValue: 0,
    });

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData !== undefined && readMonitorRunResultGroupedByMonitorIdData.list.length === 0) {
            
        }

        setProgressValue({
            ...progressValue, 
            value: 5
        });

    }, [readMonitorRunResultGroupedByMonitorIdData]);


    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData !== undefined) {
            setFirstInit(true);
        }
    }, [readMonitorRunResultGroupedByMonitorIdData]);

    const findClosest = (data: any, accessor: any, target = Date.now()) =>
        data.reduce((prev: any, curr: any) => {
            const a = Math.abs(accessor(curr).toMillis() - target);
            const b = Math.abs(accessor(prev).toMillis() - target);
            return a - b < 0 ? curr : prev;
        });

    const processDateString = (dateString: any) => {
        return DateTime.fromISO(dateString);
    };

    const findSuitableDay = () => {
        const closest = findClosest(readMonitorRunResultGroupedByMonitorIdData.list, ({ checkinUtc }: { checkinUtc: any }) => processDateString(checkinUtc));
        return closest;
    }



    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData === undefined) {
            return;
        }

        if (monitorLaunchTask !== undefined) {
            return;
        }

        if (readMonitorRunResultGroupedByMonitorIdData.list.length === 0) {
            return;
        }

        const selectedValue = findSuitableDay();
        handleSelectedMonitorLaunchTaskChange(selectedValue);
    }, [readMonitorRunResultGroupedByMonitorIdData]);

    useEffect(() => {
        if (firstInit === true) {
            if (readMonitorRunResultGroupedByMonitorIdData !== undefined && readMonitorRunResultGroupedByMonitorIdData.list.length > 0) {

                const monitorRunIdStr = searchParams.get('monitorRunId');
                if (monitorRunIdStr !== null) {
                    const monitorRunId = parseInt(monitorRunIdStr)
                    const foundRow: any = readMonitorRunResultGroupedByMonitorIdData.list
                        .find((row: any) => {
                            return row.id === monitorRunId
                        })

                    handleSelectedMonitorLaunchTaskChange(foundRow);
                    return;
                }

                const checkInDateString = searchParams.get('checkInDate');
                if (checkInDateString !== null) {
                    const checkInDate = DateTime.fromISO(checkInDateString);

                    const foundRow: any = readMonitorRunResultGroupedByMonitorIdData.list
                        .find((row: any) => {
                            const rowCheckinUtc = DateTime.fromISO(row.checkinUtc);      

                            return (checkInDate.day === rowCheckinUtc.day
                                && checkInDate.month === rowCheckinUtc.month
                                && checkInDate.year === rowCheckinUtc.year)
                        })

                    if (foundRow !== undefined) {
                        handleSelectedMonitorLaunchTaskChange(foundRow);
                    }
                   
                    return;
                }


                const selectedValue = findSuitableDay();
                handleSelectedMonitorLaunchTaskChange(selectedValue);
            };
        }
    }, [firstInit]);


    const handleTitleClick = () => {
        setOpenCalendarDialog(true);
    }

    const handleCalendarOpenChange = () => {
        setOpenCalendarDialog(false)
    }

    const handleSelectedMonitorLaunchTaskChangeFromDialog = (params: any) => {
        handleSelectedMonitorLaunchTaskChange(params);
        setOpenCalendarDialog(false)
    }

    const handleSelectedMonitorLaunchTaskChangeFromUserAction = (params: any) => {
        handleSelectedMonitorLaunchTaskChange(params);
        setOpenCalendarDialog(false);
        scrollToSummary();
    }


    return (
        <>
            <Card variant="outlined" sx={{
                width: 1,
                "& .MuiCardContent-root:last-child": {
                    paddingBottom: '0'
                },
            }}>
                <CardContent sx={{ p: 0 }}>
                    <MonitorRunResultDetailCardHeader
                        monitorRunsResultDetailCard={monitorRunsResultDetailCard}
                    />
                    <MonitorWidget
                        monitorId={monitorId}
                    />

                    <MonitorRunResultDetailCardParameters
                        onCompetitorsVisibileChanged={onCompetitorsVisibileChangedHandler}
                        onSelectedMonitorLaunchTaskChange={handleSelectedMonitorLaunchTaskChangeFromUserAction}
                        monitorId={monitorRunsResultDetailCard?.id}
                        monitorRunsResultDetailCard={monitorRunsResultDetailCard}
                        selectedMonitorLaunchTask={monitorLaunchTask}
                    /> 
                    <RecommendationListWidget
                        monitorId={monitorId}
                        onSelectedMonitorLaunchTaskChange={handleSelectedMonitorLaunchTaskChangeFromUserAction}
                    />
                    <MonitorLaunchTask
                        monitorLaunchTask={monitorLaunchTask}
                        monitorId={monitorId}
                        onTitleClick={handleTitleClick}
                        ref={summaryRef}
                    />
                    <MonitorRunResultDetailCardHostServices
                        show={showCompetitors}
                        monitorId={monitorRunsResultDetailCard?.id}
                        monitorLaunchTaskResult={monitorLaunchTask}
                        onTitleClick={handleTitleClick}
                    />
                </CardContent>
            </Card>
            <LoadingDataDialogWidget
                monitorId={monitorId}
            />
            <CalendarDialogWidget
                monitorId={monitorId}
                progress={progressValue}
                open={openCalendarDialog}
                onOpenChange={handleCalendarOpenChange}
                onSelectedMonitorLaunchTaskChange={handleSelectedMonitorLaunchTaskChangeFromDialog}
            />
        </>
    )
}

            
