import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import * as z from 'zod';
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import { UserSettings } from 'shared/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormGroup,
    FormControl,
    InputLabel,
    Button,
    TextField,
    Typography,
    Stack,
    MenuItem,
    Checkbox,
    FormLabel,
    Select,
    OutlinedInput
} from '@mui/material';
import { createBlankUserSettings } from 'domain/userSettings';

const FormSchema = z.object({
}).passthrough();

export type UserSettingsFormProps = {
    value?: UserSettings,
    onSubmit?: Function,
    onBack?: Function,
    loading?: boolean
};

export const UserSettingsForm = (props: UserSettingsFormProps): JSX.Element => {
    const {
        loading = false,
        value = createBlankUserSettings(),
        onSubmit
    } = props;

    const {
        handleSubmit,
        formState: { errors, submitCount, isSubmitting },
        control,
        getValues,
        reset,
        trigger
    } = useForm<UserSettings>({
        resolver: zodResolver(FormSchema),
        //defaultValues: defaultValue,
        values: value
    });

    const submitFormHandler = (value: UserSettings, event: any) => {
        event.preventDefault();

        if (typeof onSubmit === "function") {
            onSubmit({
                event: event,
                value: value
            });
        }
    }

    const render = () => {
        if (loading === true || value === undefined) {
            return (
                <>
                    <Skeleton variant="text" width={270} height={50} animation={false} />
                </>
            )
        }

        return (
            <Box sx={{
                display: 'Grid',
                rowGap: '20px'
            }}
                component="form"
                onSubmit={handleSubmit(submitFormHandler)}
            >
                <Controller
                    name="warningPriceDeviationPercent"
                    control={control}
                    render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                        <FormControl>
                            <FormLabel id="deviations-radio-buttons-group-label">The percentage above is the deviation from mean. Please set it accordingly to your personality.</FormLabel>
                            <RadioGroup
                                aria-labelledby="deviations-radio-buttons-group-label"
                                defaultValue={value}
                                name="deviations-radio-buttons-group"
                                onChange={onChange}
                                value={value}
                            >
                                <FormControlLabel value="30" control={<Radio />} label="Aggresive (30%)" />
                                <FormControlLabel value="20" control={<Radio />} label="Average (20%)" />
                                <FormControlLabel value="10" control={<Radio />} label="Conservative (10%)" />
                            </RadioGroup>
                        </FormControl>
                    )}
                />

                <Box>
                    <Controller
                        name="warningPriceDeviationPercent"
                        control={control}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { dirtyFields },
                        }) => (
                            <FormControl required>
                                <TextField
                                    label="Price deviation"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        inputProps: { min: 0, max: 100} 
                                    }}
                                    type="number"
                                    error={!!errors['warningPriceDeviationPercent']}
                                    helperText={errors['warningPriceDeviationPercent'] ? errors['warningPriceDeviationPercent'].message : ''}
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                />
                            </FormControl>
                        )}
                    />
                </Box>
                <FormControl>
                    <Stack direction="row">
                        <Button type="submit" variant="contained" color="primary">Save</Button>
                    </Stack>
                </FormControl>
            </Box>
        )
    }

    return (
        <>
            { render() }
        </>
    )

}
