import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import authService from 'components/api-authorization/AuthorizeService';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { selectDrawerOpen, setDrawerOpen } from 'store/applicationSlice';
import { ApplicationMainBarProfile } from './ApplicationMainBarProfile';
import { LogoIcon } from 'shared/icons/LogoIcon';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import type { User } from 'shared/types';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useLayoutEffect } from 'react'; 

export const ApplicationMainBar = () => {
    const drawerOpen = useAppSelector(selectDrawerOpen);
    const dispatch = useAppDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [user, setUser] = useState<User | undefined>(undefined)
    const [subscription, setSubscription] = useState<number | undefined>(undefined)

    const fetchData = async () => {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        setIsAuthenticated(isAuthenticated);
        setUser(user);
    }

    useEffect(() => {
        const subscriptionId = authService.subscribe(() => fetchData())
        setSubscription(subscriptionId)
        fetchData()
    }, []);

    const renderMenu = () => {
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return anonymousView(registerPath, loginPath);
        } 

        if (user != undefined) {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };
            return authenticatedView(user.name, profilePath, logoutPath, logoutState);
        }
    }

    const authenticatedView = (userName: any, profilePath: any, logoutPath: any, logoutState: any) => {
        return (
            <>
                <ul style={{
                    listStyle: 'none', display: 'flex', margin: '0', columnGap: '20px'
                }}>
                    <li>
                        <Link to={profilePath}>
                            {userName}
                        </Link>
                    </li>
                    <li>
                        <Link  to={logoutPath}>
                            Logout
                        </Link>
                    </li>
                </ul>
            </>
        );
    }

    const anonymousView = (registerPath: any, loginPath: any) => {
        return (
            <>
                <ul style={{
                    listStyle: 'none', display: 'flex', margin: '0', columnGap: '20px'
                }}>
                    <li>
                        <Link to={registerPath}>
                            Register
                        </Link>
                    </li>
                    <li>
                        <Link to={loginPath}>
                            Login
                        </Link>
                    </li>
                </ul>
            
            </>
        );
    }

    const handleDrawerToggle = () => {   
        dispatch(setDrawerOpen(!drawerOpen))
    }


    const [isStandaloneforAndroid, setIsStandaloneforAndroid] = useState<boolean>(window.matchMedia('(display-mode: standalone)').matches);
    // @ts-ignore
    const [isStandaloneforIOS, setIsStandaloneforIOS] = useState<boolean>(window.navigator.standalone !== undefined && window.navigator.standalone === true);


    window.addEventListener("resize", () => {
        setIsStandaloneforAndroid(window.matchMedia('(display-mode: standalone)').matches);
        // @ts-ignore
        setIsStandaloneforIOS(window.navigator.standalone !== undefined && window.navigator.standalone === true)
    });

    const renderInstallButton = () => {
        if (isStandaloneforIOS || isStandaloneforAndroid) {
            return ('');
        }

        return (
            <Link to="/install/instruction">
                How To Install
            </Link>
        )
    }

    const styleButtonsContainer = {
        display: 'flex',
        gap: '20px',
        alignItems: 'center'
    }


    const installstyleButtonContainer = {
        display: {
            xs: 'none',
            md: 'flex'
        },
        gap: '20px',
        alignItems: 'center'
    }


    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "#FFF", py: '10px' }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon sx={{ color: '#000' }} />
                    </IconButton>
                    <Box sx={
                        {
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: {
                                xs: 'center',
                                md: 'left',
                            }
                        }
                    }>
                        <Typography component="div" sx={
                            {
                                maxWidth: '285px',
                                px: '20px'
                            }
                        }
                        >
                            <Link to='/'>                                
                                <LogoIcon sx={{
                                    width: '100%',
                                    height: 'auto'
                                }} />
                            </Link>
                        </Typography>
                    </Box>
                    <Box sx={styleButtonsContainer}>
                        <Box sx={installstyleButtonContainer}>
                            {renderInstallButton()}
                        </Box>                       
                        <ApplicationMainBarProfile></ApplicationMainBarProfile>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
