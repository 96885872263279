import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type ComputerInstallInstructionProps = {
    subscribed?: boolean,
    onRequestEnableNotifications?: Function,
    onRequestDisableNotifications?: Function,
}

export const ComputerInstallInstruction = (props: ComputerInstallInstructionProps): JSX.Element => {
    const {
        subscribed = false,
        onRequestEnableNotifications,
        onRequestDisableNotifications
    } = props;  

    const handleEnableNotificationChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (typeof onRequestEnableNotifications === "function") {
                onRequestEnableNotifications();
            }

            return;
        }

        if (typeof onRequestDisableNotifications === "function") {
            onRequestDisableNotifications();
        }
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: 'max(20vh, 400px)'
    }

    const imageListContainerStyle = {
        display: 'flex',
        gap: '20px'
    }

    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="div" sx={{ fontWeight: 600, mb: 3 }}>
                        How to install StrSpy on your Computer
                    </Typography>
                    <Accordion>
                        <AccordionSummary id="panel-header" aria-controls="panel-content">
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                Instructions
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List sx={{ listStyle: "decimal", pl: 4 }}>
                                <ListItem sx={{ display: "list-item", m: 0 }}>
                                    <Box component="p" sx={{ m: 0 }}>On your computer, open Chrome.</Box>
                                    <div style={imageListContainerStyle}>
                                        <picture>
                                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/desktop/open-chrome-0000-0000-0000-1x.png" alt="" loading="lazy" />
                                        </picture>
                                    </div>
                                </ListItem>
                                <ListItem sx={{ display: "list-item", m: 0 }}>
                                    <Box component="p" sx={{ m: 0 }}>Go to a <a href="https://app.strspy.com">https://app.strspy.com</a>.</Box>
                                    <div style={imageListContainerStyle}>
                                        <picture>
                                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/desktop/go-to-a-app-site-0000-0000-0000-1x.png" alt="" loading="lazy" />
                                        </picture>
                                    </div>
                                </ListItem>
                                <ListItem sx={{ display: "list-item", m: 0 }}>
                                    <Box component="p" sx={{ m: 0 }}>At the top right of the address bar, click Install</Box>
                                    <div style={imageListContainerStyle}>
                                        <picture>
                                            <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/desktop/at-the-top-right-of-the-address-bar-click Install-0000-0000-0000.png" alt="" loading="lazy" />
                                        </picture>
                                    </div>       
                                </ListItem>
                                <ListItem sx={{ display: "list-item", m: 0 }}>
                                    <Box component="p" sx={{ m: 0 }}>Follow the on-screen instructions.</Box>
                                    <picture>
                                        <img className="main-nav-logo__image" style={imageStyle} src="/images/install-pwa/desktop/follow-the-on-screen-instructions-0000-0000-0000-1x.png" alt="" loading="lazy" />
                                    </picture>                  
                                </ListItem>
                                <ListItem sx={{ display: "list-item", m: 0 }}>
                                    <Box component="p" sx={{ m: 0 }}>To run STRSpy please touch this icon</Box>
                                </ListItem>  
                                <ListItem sx={{ display: "list-item", m: 0 }}>
                                    <FormControlLabel sx={{ m: 0 }} labelPlacement="start" control={<Switch checked={subscribed} color="success" onChange={handleEnableNotificationChanged} />} label="Enable Notifications" />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Box>
    )
}