import { http } from 'apiClient';

import type { Platform } from 'shared/types'

export const readPlatforms = async (): Promise<any> => {
    let url = `/platform`;
    const response = await http.get(url);

    return response.data;
}

export const platformsService = {
    readPlatforms
}