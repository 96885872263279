
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const MaxPriceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 36 36" fill="none">
            <path d="M22.2239 11.578C22.235 5.20207 17.2605 0.0115283 11.1287 1.93158e-05C4.99678 -0.0114896 0.0111475 5.1215 1.88888e-05 11.4629C-0.0111098 17.8619 4.89662 22.9719 11.0396 22.9834C16.9156 23.156 22.2128 18.0345 22.2239 11.578ZM12.9649 17.7238C12.1636 17.7698 12.1636 17.7698 12.1525 18.6215C12.1525 19.8299 12.2415 19.6803 11.0953 19.6918C11.0174 19.6918 10.9506 19.6918 10.8727 19.6918C10.0603 19.6918 10.0603 19.6918 10.0603 18.8287C10.0603 17.7353 10.0603 17.7353 9.00309 17.7353C8.46892 17.7353 7.92361 17.7238 7.38944 17.7353C7.08896 17.7353 6.94429 17.6087 6.94429 17.2865C6.94429 16.8491 6.95542 16.4003 6.94429 15.9629C6.93316 15.6062 7.11122 15.5026 7.42282 15.5026C8.33537 15.5141 9.23679 15.5026 10.1493 15.5026C11.0062 15.5026 11.852 15.5141 12.7089 15.5026C13.6549 15.4911 14.2224 14.9041 14.2113 13.9834C14.2002 13.0972 13.6326 12.5793 12.6867 12.5563C11.5627 12.5218 10.4276 12.6714 9.30356 12.4412C8.00151 12.188 6.49915 10.8069 6.58818 8.79286C6.66608 7.06652 7.7233 5.71997 9.42598 5.38621C9.90451 5.29414 10.0714 5.07547 10.0603 4.58058C10.0492 3.29158 9.90451 3.37214 11.2177 3.37214C12.1414 3.37214 12.1414 3.37214 12.1414 4.36191C12.1414 5.2481 12.1414 5.2481 12.9983 5.2481C13.4212 5.2481 13.8552 5.25961 14.2781 5.2481C14.5563 5.2481 14.6898 5.3747 14.6787 5.66242C14.6787 5.69695 14.6787 5.74299 14.6787 5.77751C14.7343 7.49235 14.7343 7.49235 13.0762 7.49235C12.1859 7.49235 11.2956 7.49235 10.4053 7.49235C9.37034 7.49235 8.70262 8.03327 8.69149 8.87342C8.68036 9.71358 9.34808 10.2775 10.3719 10.289C11.1843 10.289 12.0078 10.2775 12.8202 10.289C14.8122 10.3236 16.3925 11.9578 16.4148 13.9834C16.437 15.9629 14.9013 17.6087 12.9649 17.7238Z" />
            <path d="M35.667 22.316L28.6671 10.9221C27.799 9.50653 25.7625 9.5986 25.028 11.1063C25.028 11.1063 25.1616 18.0577 20.5766 21.729C20.5766 21.729 19.3302 22.9605 17.5384 23.8812C17.0154 24.1574 17.2046 25.4925 18.5845 25.4925C19.6974 25.4925 21.0885 25.4925 22.5463 25.4925C22.6354 25.527 22.7244 25.5615 22.8245 25.5845V33.0423C22.8245 34.6766 24.1043 36.0001 25.6846 36.0001H26.9088C28.489 36.0001 29.7688 34.6766 29.7688 33.0423V25.6536H33.9087C35.5557 25.6536 36.5573 23.7661 35.667 22.316Z"  />
        </SvgIcon>
  );
}