
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const AveragePriceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 36 36" fill="none">
            <path d="M36 18.1319C36.018 8.14674 27.9599 0.018054 18.027 3.02497e-05C8.09416 -0.0179935 0.0180576 8.02058 3.05975e-05 17.9516C-0.0179964 27.9728 7.93191 35.9753 17.8828 35.9934C27.4011 36.2637 35.9819 28.2432 36 18.1319ZM21.0015 27.7565C19.7036 27.8286 19.7036 27.8286 19.6855 29.1624C19.6855 31.0549 19.8297 30.8206 17.973 30.8386C17.8468 30.8386 17.7386 30.8386 17.6124 30.8386C16.2964 30.8386 16.2964 30.8386 16.2964 29.4868C16.2964 27.7746 16.2964 27.7746 14.5839 27.7746C13.7186 27.7746 12.8353 27.7565 11.97 27.7746C11.4832 27.7746 11.2489 27.5763 11.2489 27.0716C11.2489 26.3867 11.2669 25.6838 11.2489 24.9989C11.2309 24.4402 11.5193 24.278 12.024 24.278C13.5023 24.296 14.9624 24.278 16.4407 24.278C17.8287 24.278 19.1988 24.296 20.5869 24.278C22.1192 24.2599 23.0385 23.3407 23.0205 21.8988C23.0025 20.511 22.0831 19.6999 20.5508 19.6639C18.7301 19.6098 16.8913 19.8441 15.0706 19.4837C12.9615 19.0871 10.5278 16.9243 10.672 13.7701C10.7982 11.0666 12.5108 8.95781 15.2689 8.43512C16.0441 8.29093 16.3145 7.94848 16.2964 7.17346C16.2784 5.15481 16.0441 5.28097 18.1713 5.28097C19.6675 5.28097 19.6675 5.28097 19.6675 6.83101C19.6675 8.21884 19.6675 8.21884 21.0556 8.21884C21.7406 8.21884 22.4437 8.23686 23.1287 8.21884C23.5794 8.21884 23.7957 8.4171 23.7777 8.86769C23.7777 8.92176 23.7777 8.99386 23.7777 9.04793C23.8678 11.7335 23.8678 11.7335 21.1818 11.7335C19.7396 11.7335 18.2974 11.7335 16.8553 11.7335C15.1788 11.7335 14.0972 12.5806 14.0791 13.8963C14.0611 15.212 15.1427 16.0952 16.8012 16.1132C18.1172 16.1132 19.4512 16.0952 20.7671 16.1132C23.994 16.1673 26.5538 18.7267 26.5899 21.8988C26.6259 24.9989 24.1382 27.5763 21.0015 27.7565Z" fill="#B0B0B0" />
        </SvgIcon>
  );
}