import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MonitorRunResultDetailCardHostServiceListCard } from '../../MonitorRunResultDetailCardHostServiceListCard';
import { useReadHostServicesByMonitorRunIdQuery } from 'services/hostService';
import { useReadHostServiceByMonitorIdQuery } from 'services/hostService';
import { selectMonitorRunForCompetitors, setMonitorRunForCompetitors } from 'store/applicationSlice';
import { selectHostServiceForAvailability } from 'store/applicationSlice';
import { useAppSelector } from 'shared/hooks';
import { useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { HOSTSERVICE_SORT_FIELDS } from 'shared/constants';
import { SortOrder } from 'shared/enums';
import { CalendarDialog } from './components/CalendarDialog';
import { ReadHostServiceByMonitorIdParams, ReadHostServicesByMonitorRunIdParams } from 'services/hostService/qhostService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import type { MonitorRunsResultCard } from 'shared/types';

type MonitorRunResultDetailCardHostServicesProps = {
    monitorRunId?: number,
    monitorId?: number,
    show?: Boolean,
    monitorLaunchTaskResult: any,
    onTitleClick?: Function,
}

export const MonitorRunResultDetailCardHostServices = (
    {
        monitorRunId,
        monitorId,
        show = true,
        monitorLaunchTaskResult,
        onTitleClick
    }: MonitorRunResultDetailCardHostServicesProps): JSX.Element => {  
    const [skip, setSkip] = useState<boolean>(monitorRunId === undefined);
    const monitorRunForCompetitors = useAppSelector(selectMonitorRunForCompetitors);
    const hostServiceForAvailability = useAppSelector(selectHostServiceForAvailability);
    const [orderField, setOrderField] = useState('');

    const [readHostServicesByMonitorRunIdParams, setReadHostServicesByMonitorRunIdParams] = useState<ReadHostServicesByMonitorRunIdParams>({
        monitorRunId: monitorLaunchTaskResult?.monitorRunsResult?.id || -1,
        sorting: {
            orderField: '',
            sortOrder: SortOrder.Ascending
        }
    })

    const [order, setOrder] = useState<SortOrder>(SortOrder.Ascending);
    const [openCalendarDialog, setOpenCalendarDialog] = useState<boolean>(false);
    const useReadHostServicesByMonitorRunIdQueryOptions = {
        skip: skip
    }

    const {
        data: readHostServicesByMonitorRunIdData,
        isLoading: readHostServicesByMonitorRunIdIsLoading,
        isFetching: readHostServicesByMonitorRunIdisFetching
    } = useReadHostServicesByMonitorRunIdQuery(
            readHostServicesByMonitorRunIdParams,
            useReadHostServicesByMonitorRunIdQueryOptions
    );


    const [readHostServicesByMonitorIdParams, setReadHostServicesByMonitorIdParams] = useState<ReadHostServiceByMonitorIdParams>({
        monitorId: -1,
        hostServiceId: -1
    })

    const [useReadHostServiceByMonitorIdQueryOptions, setUseReadHostServiceByMonitorIdQueryOptions] = useState<object>({
        skip: true
    })

    const {
        data: readHostServiceByMonitorIdData,
        isLoading: readHostServiceByMonitorIdIsLoading,
        isFetching: readHostServiceByMonitorIdIsFetching
    } = useReadHostServiceByMonitorIdQuery(
        readHostServicesByMonitorIdParams,
        useReadHostServiceByMonitorIdQueryOptions
    );


    const ref = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
/*        ref.current?.scrollIntoView({ behavior: 'smooth' });*/
        if (monitorLaunchTaskResult !== undefined){
            setReadHostServicesByMonitorRunIdParams(params => ({
                ...params,
                monitorRunId: monitorLaunchTaskResult?.monitorRunsResult?.id
            }));
        }

        setSkip(monitorLaunchTaskResult === undefined);
    }, [monitorLaunchTaskResult]);

    useEffect(() => {
        setSkip(monitorRunForCompetitors === undefined);
    }, []);

    const handleSelectHostServiceForAvailability = (hostService: any) => {
        if (monitorId === undefined) {
            return;
        }

        setReadHostServicesByMonitorIdParams({
            hostServiceId: hostService.id,
            monitorId: monitorId
        });

        setUseReadHostServiceByMonitorIdQueryOptions({
            skip: false
        })

        setOpenCalendarDialog(true);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setReadHostServicesByMonitorRunIdParams((params) => {
            let sortOrder = SortOrder.Ascending;
            if (params.sorting !== undefined
                && params.sorting.sortOrder !== undefined) {
                sortOrder = params.sorting.sortOrder;
            }

            return {
                ...params,
                sorting: {
                    orderField: event.target.value,
                    sortOrder: sortOrder
                }
            }
        });
    };

    const onOrderChangeHandler = (event: SelectChangeEvent) => {
        const sortOrder: SortOrder = parseInt(event.target.value) as SortOrder;

        setReadHostServicesByMonitorRunIdParams(params => ({
            ...params,
            sorting: {
                orderField: params.sorting?.orderField || '',
                sortOrder: sortOrder
            }
        }));
    };

    const renderOrderItems = () => {
        const menuItems: any = [];

        for (const key in SortOrder) {
            if (isNaN(Number(key))) {
                continue;
            }

            menuItems.push(<MenuItem key="key" value={key}>{SortOrder[key]}</MenuItem>)
        }

        return menuItems;
    }

    const handleCalendarCloseDialog = () => {
        setOpenCalendarDialog(false);
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const renderAccordionSummaryHeading = () => {
        if (monitorLaunchTaskResult !== undefined) {
            const checkinUtc = DateTime.fromISO(monitorLaunchTaskResult?.monitorRunsResult?.checkinUtc).toFormat('M/dd/yyyy');
            const checkOutUtc = DateTime.fromISO(monitorLaunchTaskResult?.monitorRunsResult?.checkOutUtc).toFormat('M/dd/yyyy');

            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-between',width: '100%'}}>
                    <Typography sx={{
                        fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '24px',
                        },
                        lineHeight: '28px',
                        color: '#FFF',
                        marginRight: 2
                    }}
                    >Competitors: {monitorLaunchTaskResult.monitorRunsResult.resultsCount}
                    </Typography>
                    <Box sx={{
                        fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '24px',
                        },
                        lineHeight: '28px',
                        color: '#FFF',
                        marginRight: 2,
                        display: 'flex',
                        gap: '6px'
                    }}
                        onClick={handleTitleCLick}
                    >
                        <Box>{`${checkinUtc}`}</Box>
                        <CalendarMonthIcon></CalendarMonthIcon>
                    </Box>
                </Box>
            )
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={{
                    fontSize: {
                        xs: '16px',
                        sm: '18px',
                        md: '24px',
                    },
                    lineHeight: '28px',
                    color: '#c62828',
                    marginRight: 2
                }}
                >Competitors
                </Typography>
                <Typography sx={{
                    fontSize: {
                        xs: '16px',
                        sm: '18px',
                        md: '24px',
                    },
                    lineHeight: '28px',
                    color: '#c62828',
                    marginRight: 2
                }}
                >{`Select period above`}
                </Typography>
            </Box>


        )

    }

    const handleTitleCLick = (params: any) => {
        if (typeof onTitleClick === "function") {
            onTitleClick(params);
        }
    }

    const renderAccordion = () => {

        if (monitorLaunchTaskResult !== undefined) {
            return (
                <>
                    <Accordion disabled={monitorLaunchTaskResult === undefined} expanded={monitorLaunchTaskResult !== undefined}  disableGutters defaultExpanded={false} ref={ref}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#FFF' }} />}
                            aria-controls="panel1a-content"
                            sx={{
                                backgroundColor: '#B0B0B0',
                                paddingRight: { xs: '12px', md: '24px' },
                                paddingLeft: { xs: '12px', md: '24px' },
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                    padding: '12px 12px 12px 12px',
                                },
                            }}
                        >
                            {renderAccordionSummaryHeading() }
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#EEE', p: 0 }}>
                            <Container sx={{ ml: 0, py: '24px' }} maxWidth={false} >
                                <FormControl sx={{ m: 1, minWidth: 160 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Field</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={readHostServicesByMonitorRunIdParams.sorting?.orderField}
                                        label="Field"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="none" value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            HOSTSERVICE_SORT_FIELDS.map((sortField) => {
                                                return <MenuItem key={sortField.value} value={sortField.value}>{sortField.label}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText></FormHelperText>
                                </FormControl>
                                <FormControl disabled={readHostServicesByMonitorRunIdParams?.sorting?.orderField === ''} sx={{ m: 1, minWidth: 130 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Order</InputLabel>
                                    <Select

                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={readHostServicesByMonitorRunIdParams.sorting?.sortOrder.toString()}
                                        label="Order"
                                        onChange={onOrderChangeHandler}
                                    >
                                        {
                                            renderOrderItems()
                                        }
                                    </Select>
                                    <FormHelperText></FormHelperText>
                                </FormControl>
                                <MonitorRunResultDetailCardHostServiceListCard
                                    hostServices={readHostServicesByMonitorRunIdData}
                                    monitorId={monitorId}
                                    loading={readHostServicesByMonitorRunIdisFetching}
                                    onSelectHostServiceForAvailability={handleSelectHostServiceForAvailability}
                                />
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <CalendarDialog
                        keepMounted
                        open={openCalendarDialog}
                        value={readHostServiceByMonitorIdData}
                        onClose={handleCalendarCloseDialog}
                        fullScreen
                        loading={readHostServiceByMonitorIdIsFetching}
                    />
                </>
            )
        }

        return (
            <>
                <Accordion disabled={monitorLaunchTaskResult === undefined} expanded={monitorLaunchTaskResult !== undefined}  disableGutters ref={ref}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#FFF' }} />}
                        aria-controls="panel1a-content"
                        sx={{
                            backgroundColor: '#B0B0B0',
                            paddingRight: { xs: '12px', md: '24px' },
                            paddingLeft: { xs: '12px', md: '24px' },
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                padding: '12px 12px 12px 12px',
                            },
                        }}
                    >
                        {renderAccordionSummaryHeading()}
                    </AccordionSummary>
                    <AccordionDetails sx={{ bgcolor: '#EEE', p: 0 }}>
                     
                    </AccordionDetails>
                </Accordion>
                <CalendarDialog
                    keepMounted
                    open={openCalendarDialog}
                    value={readHostServiceByMonitorIdData}
                    onClose={handleCalendarCloseDialog}
                    fullScreen
                    loading={readHostServiceByMonitorIdIsFetching}
                />

            </>
        )
    }

    return (
        <>
            {renderAccordion()}         
        </>
    )
}