import React from 'react';
import { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { Card } from '@mui/material';
import { CardActions } from '@mui/material';
import { CardHeader } from '@mui/material';
import { CardContent } from '@mui/material';
import { Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import { useSetMineHostServiceMutation } from 'services/hostService';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import classNames from 'classnames';
import { setHostServiceForAvailability } from 'store/applicationSlice';
import type { MonitorRunResultHostService } from 'shared/types'

type MonitorRunResultDetailCardHostServiceCardProps = {
    hostService: any,
    monitorId?: number,
    onSelectHostServiceForAvailability: (hostService: any) => void
}

const ParameterLabel = styled('span')({
    fontWeight: '700',
});

export const MonitorRunResultDetailCardHostServiceCard = ({ hostService, monitorId, onSelectHostServiceForAvailability }: MonitorRunResultDetailCardHostServiceCardProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [setMineHostService] = useSetMineHostServiceMutation();

    const cardClassNames = classNames(
        {
            isMine: hostService.hostService.isMine,
        }
    );

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleItsMine = async () => {
        setOpen(false);
        await setMineHostService({
            hostServiceId: hostService.hostService.id
        })
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);



    const selectHostServiceForAvailability = (hostService: any) => {
        onSelectHostServiceForAvailability(hostService);
        //dispatch(setHostServiceForAvailability(hostService));
    }

    const renderPicture = () => {
        if (hostService.hostService?.strHostservicePictures
            && Array.isArray(hostService.hostService?.strHostservicePictures)
            && hostService.hostService?.strHostservicePictures.length > 0
        ) {
            return (
                <>      
                    <Box sx={
                        {
                            height: '240px',
                            width: '100%',
                            position: 'relative',
                        }
                    }
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                        >
                            {hostService.hostService.superHost === true ?
                                <Box sx={{ padding: '4px 16px', backgroundColor: '#D62424', minWidth: '150px', color: '#FFF' }}>
                                    Super Host
                                </Box>
                                : ''
                            }
                            {hostService.hostService.guestFavorite === true ?
                                <Box sx={{ padding: '4px 16px', backgroundColor: '#56A96D', minWidth: '150px', color: '#FFF' }}>
                                    Guest Favorite
                                </Box>
                                : ''
                            }
                        </Box> 


                        <Box sx={{
                            position: 'absolute', 
                            bottom: 0,
                            right: 0,
                            }}
                        >
                            {hostService.mostExpensive ?
                                <Box sx={{ padding: '4px 16px', backgroundColor: '#D62424', minWidth: '150px', color: '#FFF' }}>
                                    Most expensive
                                </Box>
                                : ''
                            }
                            {hostService.theCheapest ?
                                <Box sx={{ padding: '4px 16px', backgroundColor: '#56A96D', minWidth: '150px', color: '#FFF' }}>
                                    Least expensive
                                </Box>
                                : ''
                            }
                        </Box>                 
                            <Box
                                component="img"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'cover'
                                }}
                                alt="The house from the offer."
                                src={hostService?.hostService?.strHostservicePictures[0].url}>
                            </Box>
                    </Box>
                </>
            )
        } else {
            return (
                <>
                    <Skeleton variant="rounded" width={270} height={250} animation={false} />
                </>
            )
        }
    }

    const getAvailableValue = (hostService: any): string => {
        if (hostService === undefined || hostService.wasFoundByTheMonitor === undefined || hostService.overallRuns === undefined) {
            return '';
        }
        const percent: number = hostService.wasFoundByTheMonitor / hostService.overallRuns * 100;

        return `${hostService.wasFoundByTheMonitor} of ${hostService.overallRuns} days (${percent.toFixed(0)}%)`
    }

    const getOccupiedValue = (hostService: any): string => {
        if (hostService === undefined || hostService.wasFoundByTheMonitor === undefined || hostService.overallRuns === undefined) {
            return '';
        }

        const occupied = hostService.overallRuns - hostService.wasFoundByTheMonitor;
        const percent: number = (occupied / hostService.overallRuns) * 100;
        return `${occupied} of ${hostService.overallRuns} days (${percent.toFixed(0)}%)`
    }
  
    return (
        <>
            <Card className={cardClassNames} sx={{
                maxWidth: 285,
                '& .MuiCardHeader-root': {
                    padding: '16px 16px 0 16px',
                },
                '& .MuiCardHeader-content': {
                    padding: '0 0 0 0',
                },
                '&.isMine': {
                    color: '#FFF',
                },
                '&.isMine .MuiCardHeader-root': {
                    backgroundColor: '#1e1e1e',
                },
                '&.isMine .MuiCardContent-root': {
                    backgroundColor: '#1e1e1e',
                },
                '&.isMine .MuiList-root': {
                    backgroundColor: '#1e1e1e',
                },
                '&.isMine .MuiCardActions-root': {
                    backgroundColor: '#1e1e1e',
                },
                '&.isMine .MuiSvgIcon-root': {
                    color: '#FFF'
                },             
                  
            }}>
                <CardHeader
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleToggle}
                            ref={anchorRef}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={
                        <Box sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            height: '48px',
                            maxHeight: '48px',
                            fontWeight: '600', fontSize: '18px', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
                        }}>
                            {hostService?.hostService.name}
                        </Box>
                    }
                />

                <CardContent sx={{ p: 0 }}>
                    <List sx={{ width: '100%', maxWidth: 285, bgcolor: 'background.paper', py: '6px' }}>
                        <ListItem key="picture">
                            {
                                renderPicture()
                            }
                        </ListItem>
                    </List>

                    <Grid container spacing={0} sx={{px: '16px', py: '6px' }}>
                        <Grid item xs={12}>
                            <div>
                                <ParameterLabel>City</ParameterLabel>: {`${hostService?.hostService && hostService?.hostService.city}`}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <ParameterLabel>Bedrooms</ParameterLabel>: {hostService?.hostService.bedrooms}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                 <ParameterLabel>Beds</ParameterLabel>: {hostService?.hostService.beds}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                 <ParameterLabel>Pets</ParameterLabel>: {hostService?.hostService.pets}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <ParameterLabel>
                                    Rating
                                </ParameterLabel>: {hostService?.hostService.rating}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <ParameterLabel>
                                    Reviews
                                </ParameterLabel>: {hostService?.hostService.ratingsCount}
                            </div>
                        </Grid>     
                        <Grid item xs={12}>
                            <div>
                                <ParameterLabel>
                                    Available
                                </ParameterLabel>
                                <Typography component="span">:</Typography>
                                <Typography
                                    component="span"
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#1976d2',
                                        margin: '0 0 0 6px'
                                    }}
                                    onClick={() => selectHostServiceForAvailability(hostService?.hostService)}>
                                     {getAvailableValue(hostService)}
                                </Typography>
                            </div>
                        </Grid>  
                        <Grid item xs={12}>
                            <div>
                                <ParameterLabel>
                                    Occupied
                                </ParameterLabel>
                                <Typography component="span">:</Typography>
                                <Typography
                                    component="span"
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#1976d2',
                                        margin: '0 0 0 6px'
                                    }}
                                    onClick={() => selectHostServiceForAvailability(hostService?.hostService)}>
                                    {getOccupiedValue(hostService)}
                                </Typography>
                            </div>
                        </Grid> 

                        

                        <Grid item xs={12}>
                            <Box>
                                <ParameterLabel>Original price</ParameterLabel>: {`$${hostService?.originalPrice}`}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <ParameterLabel>Discounted price</ParameterLabel>: {hostService?.discountedPrice === null ? '' : `$${hostService?.discountedPrice}`}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <ParameterLabel>Price Total</ParameterLabel>: {`$${hostService?.priceTotal}`}
                            </Box>
                        </Grid>        
                    </Grid>
                </CardContent>
                <CardActions sx={{ p: 0 }}>
                    <Box sx={{ width: '100%' }}>
                        <Divider variant="middle" sx={{ m: 0, borderBottomWidth: 1, bgcolor: (theme) => theme.palette.divider }} />
                        <Stack direction="row" spacing={2} sx={{ p: '8px' }}>
                            <Link to={`/hostservice/${hostService.hostService.id}?monitoridforprices=${monitorId}`}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<OpenInNewIcon />}
                                    sx={{
                                        minWidth: 110,
                                    }}
                                >
                                    Details
                                </Button >
                            </Link>
                        </Stack>
                    </Box>
                </CardActions>
            </Card>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem key="itsmine" onClick={handleItsMine}>It's mine</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>

    )
}