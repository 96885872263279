import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Calendar } from 'components/Calendar';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { ReadMonitorRunResultParams, useReadMonitorRunResultGroupedByMonitorIdQuery } from 'services/monitorRunResult';
import { useState } from 'react';
import { useEffect } from 'react';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { MonitorRunsResultDetailCard } from 'shared/types';
import { useReadUserSettingsQuery } from 'services/user';
import type { UserSettings } from 'shared/types'
import { createBlankUserSettings } from 'domain/userSettings';
import { Box } from '@mui/material';
import { useReadMonitorByIdQuery } from 'services/monitor';

type Progress = {
    value: number,
    maxValue: number,
}

type LoadingDataDialogProps = {
    open?: boolean,
    progress: Progress,
    onOpenChange?: Function,
    monitorId?: number,
    onSelectedMonitorLaunchTaskChange?: Function
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        width: 'auto',
        maxHeight: '590px',
        maxWidth: '100%'
    }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#648AAF' : '#648AAF',
    },
}));

export const CalendarDialogWidget = (props: LoadingDataDialogProps): JSX.Element => {
    const {
        monitorId,
        onSelectedMonitorLaunchTaskChange
    } = props;

    const [selectedMonitorLaunchTasksId, setSelectedMonitorLaunchTasksId] = useState<Array<number>>([]);
    const [hasUserSettings, setHasUserSettings] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined);
    const [readMonitorRunResultByMonitorIdQueryParams, setReadMonitorRunResultByMonitorIdQueryParams] = useState<any>(undefined);
    const [readMonitorRunResultByMonitorIdQueryOptions, setReadMonitorRunResultByMonitorIdQueryOptions] = useState<any>(
        {
            skip: true
        }
    );
    const [readMonitorByIdQueryParams, setReadMonitorByIdQueryParams] = useState<any>(undefined);
    const [readMonitorByIdOptions, setReadMonitorByIdOptions] = useState<any>(
        {
            skip: true
        }
    );

    const useReadUserSettingsQueryOptions = {
        skip: false
    }

    const {
        data: readMonitorRunResultGroupedByMonitorIdData,
        isLoading: readMonitorRunResultGroupedByMonitorIdIsLoading,
        isFetching: readMonitorRunResultGroupedByMonitorIdIsFetching,
        refetch
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        readMonitorRunResultByMonitorIdQueryParams,
        readMonitorRunResultByMonitorIdQueryOptions
    );

    useEffect(() => {
        setReadMonitorRunResultByMonitorIdQueryParams({
            ...readMonitorRunResultByMonitorIdQueryParams,
            monitorId: monitorId
        });

        setReadMonitorRunResultByMonitorIdQueryOptions(
            {
                ...readMonitorRunResultByMonitorIdQueryOptions,
                skip: monitorId === undefined,
            }
        );
    }, [monitorId]);

    const {
        data: readUserSettingsData,
        isLoading: readUserSettingsIsLoading,
        isFetching: readUserSettingsIsFetching,
        error: readUserSettingsError
    } = useReadUserSettingsQuery(useReadUserSettingsQueryOptions);

    const {
        data: readMonitorByIdQueryData,
        isLoading: readMonitorByIdQueryIsLoading
    } = useReadMonitorByIdQuery(readMonitorByIdQueryParams, readMonitorByIdOptions);


    useEffect(() => {
        setReadMonitorByIdQueryParams({
            ...readMonitorByIdQueryParams,
            monitorId: monitorId
        });

        setReadMonitorByIdOptions(
            {
                ...readMonitorByIdOptions,
                skip: monitorId === undefined,
            }
        );
    }, [monitorId]);

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData === undefined || userSettings === undefined || readMonitorByIdQueryData === undefined) {
            setShowLoader(true);
            return;
        }

        setShowLoader(false);

    }, [readMonitorRunResultGroupedByMonitorIdData, userSettings, readMonitorByIdQueryData])


    const handleSelectedMonitorLaunchTaskChange = (params: any) => {
        if (typeof onSelectedMonitorLaunchTaskChange === "function") {
            onSelectedMonitorLaunchTaskChange(params);
        }
    }

    const handleClickDay = ({ event, monitorLaunchTask }: any) => {
        if (monitorId === undefined) {
            return;
        }

        setSelectedMonitorLaunchTasksId([
            monitorLaunchTask.id
        ]);

        handleSelectedMonitorLaunchTaskChange({ monitorLaunchTask });
    }

    useEffect(() => {
        if (readUserSettingsError !== undefined
            && 'data' in readUserSettingsError
            && readUserSettingsError.status === 404
            && readUserSettingsIsFetching === false) {
            setHasUserSettings(false);
            setUserSettings(createBlankUserSettings());
        } else {
            setHasUserSettings(true)
            setUserSettings(readUserSettingsData)
        }
    }, [readUserSettingsIsFetching, readUserSettingsError]);


    const isSameDay = (a: DateTime, b: DateTime): boolean => {
        return a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
    };

    const customDay = (props: PickersDayProps<DateTime>) => {
        const updatedProps = { ...props, selected: false }
        if (readMonitorRunResultGroupedByMonitorIdData === undefined || userSettings === undefined || readMonitorByIdQueryData === undefined) {
            return <PickersDay {...updatedProps} />;
        }

        const foundGroupedByMonitorIdDataRow = readMonitorRunResultGroupedByMonitorIdData.list
            .find((groupedByMonitorIdDataRow: any) => {
                return isSameDay(DateTime.fromISO(groupedByMonitorIdDataRow.checkinUtc), props.day);
            })

        const matchedStyles: any = {};
        const recommendations: any = [];
        const classes: any = [];

        if (foundGroupedByMonitorIdDataRow) {
            if (readMonitorByIdQueryData.priceForComparison !== null) {
                const priceForComparisonDeviation = readMonitorByIdQueryData.priceForComparison * (userSettings.warningPriceDeviationPercent / 100);
                const diffPrices = foundGroupedByMonitorIdDataRow.averagePrice - readMonitorByIdQueryData.priceForComparison;

                if (Math.abs(diffPrices) > priceForComparisonDeviation) {
                    const percent = foundGroupedByMonitorIdDataRow.averagePrice * 0.01;
                    const newValue = foundGroupedByMonitorIdDataRow.averagePrice - (userSettings.warningPriceDeviationPercent / 2 * percent);
                    classes.push('MuiPickersDay--error');

                    if (diffPrices > 0) {
                        recommendations.push(`we recommend that you raise your price by $${newValue.toFixed(0)}`);
                    } else {
                        recommendations.push(`We recommend that you lower your price by $${newValue.toFixed(0)}`);
                    }
                } else {
                    classes.push('MuiPickersDay--ok');
                }
            }

            if (selectedMonitorLaunchTasksId.includes(foundGroupedByMonitorIdDataRow.id)) {
                classes.push('MuiPickersDay--selected');
            }
        } else {
            matchedStyles['color'] = '#83686899';
        }

        return (
            <Box sx={{ padding: '2px' }}>
                <PickersDay
                    className={classes.join(' ')}
                    onFocus={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        event.currentTarget.blur();
                    }
                    }
                    onFocusCapture={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        event.currentTarget.blur();
                    }
                    }
                    onClick={
                        (event: any) => {
                            if (foundGroupedByMonitorIdDataRow !== undefined) {
                                handleClickDay({ event, monitorLaunchTask: foundGroupedByMonitorIdDataRow });
                            }
                        }
                    }
                    {...updatedProps} sx={{ ...matchedStyles }} />
            </Box>
        );
    };

    const {
        open = false,
        onOpenChange,
        progress
    } = props;

    const handleClose = () => {
        if (typeof onOpenChange === "function") {
            onOpenChange(false);
        }
    };

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Calendar
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Calendar
                        loading={showLoader || readMonitorRunResultGroupedByMonitorIdData === undefined}
                        slots={{
                            calendarHeader: () => (null),
                            day: customDay,
                        }}
                    />             
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}
