/* eslint-disable react/jsx-no-undef */
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import type { Monitor } from 'shared/types'
import { DateTime } from 'luxon';

type MonitorCardProps = {
    loading: boolean,
    value?: Monitor,
}

export const MonitorCard = (props: MonitorCardProps): JSX.Element => {
    const {
        loading,
        value
    } = props;

    return (
        <>
            <Accordion disableGutters>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#FFF' }} />}
                    aria-controls="panel1a-content"
                    sx={{
                        backgroundColor: '#B0B0B0',
                        paddingRight: { xs: '12px', md: '24px' },
                        paddingLeft: { xs: '12px', md: '24px' },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            padding: '12px 12px 12px 12px',
                        },
                        '& .MuiAccordionSummary-content': {
                            flexDirection: {
                                xs: 'column',
                                sm: 'row'
                            }
                        }
                    }}
                >
                    <Typography sx={
                        {
                            fontSize: {
                                xs: '16px',
                                sm: '18px',
                                md: '24px',
                            }, 
                            lineHeight: '28px',
                            color: '#FFF',
                            marginRight: 2
                        }
                    }
                    >Spy</Typography>
        
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#EEE', p: 0 }}>
                    <Grid container sx={{p: '16px' }}>
                        <Grid item xs={12}>
                            <div>
                                <Box sx={{ fontWeight: '700' }}>
                                    Location
                                </Box>
                                <Box>
                                    {`${value?.locations?.join(', ')}`}
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <div>
                                <Box sx={{ fontWeight: '700' }}>
                                    Platforms
                                </Box>
                                <Box>
                                    {`AirBnb`}
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <div>
                                <Box sx={{ fontWeight: '700' }}>
                                    Bedrooms
                                </Box>
                                <Box>
                                    {
                                        value?.bedrooms ? value.bedrooms : 'any'
                                    }
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <div>
                                <Box sx={{ fontWeight: '700' }}>
                                    Beds
                                </Box>
                                <Box>
                                    {
                                        value?.beds ? value.beds : 'any'
                                    }
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <div>
                                <Box sx={{ fontWeight: '700' }}>
                                    Pets
                                </Box>
                                <Box>
                                    {value?.pets}
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Box sx={{ fontWeight: '700' }}>
                                    Created
                                </Box>
                                <Box>
                                    {value?.timestampCreatedUtc ? DateTime.fromISO(value?.timestampCreatedUtc).toFormat('M/dd/yyyy') : ''}
                                </Box>
                            </div>
                        </Grid>
                        
                    </Grid>

                    <Grid container sx={{ p: '16px' }}>
                        <Grid item xs={12}>
                            <div>
                                <Link to={`/spy/${value?.id}`}>
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        startIcon={<EditIcon />}
                                        sx={{
                                            minWidth: {
                                                sx: 70,
                                                md: 110
                                            }
                                        }}
                                    >
                                        Edit
                                    </Button >
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>  
     
    )
}