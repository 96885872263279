import { useEffect } from 'react';
import { HostServiceDetail } from 'components/HostServiceDetail';
import { useParams, useSearchParams } from 'react-router-dom';
import { reset } from 'store/monitorRunResultSlice';
import { useAppDispatch } from 'shared/hooks'

import type { HostServiceDetailProps } from 'components/HostServiceDetail';

export const HostServiceDetailPage = () => {
    const { hostServiceId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const convertToInt = (value: string | undefined | null): number => {
        if (value === undefined || value === null) {
            return NaN;
        }

        return parseInt(value);
    } 

    const createHostServiceDetailProps = (): HostServiceDetailProps => {
        const hostServiceIdNumber: number = convertToInt(hostServiceId);  
        const monitorIdforPrices: number = convertToInt(searchParams.get('monitoridforprices'));  

        if (isNaN(hostServiceIdNumber)) {
            throw new Error('HostServiceId has not correct value');
        }

        const hostServiceDetailProps: HostServiceDetailProps = {
            hostServiceId: hostServiceIdNumber,
        }

        if (isNaN(monitorIdforPrices) === false) {
            hostServiceDetailProps.monitorIdforPrices = monitorIdforPrices
        }

        return hostServiceDetailProps
    }

    const hostServiceDetailProps: HostServiceDetailProps = createHostServiceDetailProps();


    useEffect(() => {
        //dispatch(reset())

        return () => {
            //dispatch(reset())
        }
    }, [dispatch])

    return (
        <HostServiceDetail {...hostServiceDetailProps}></HostServiceDetail>
    )
}