import { Box } from '@mui/material';
import { useRef } from 'react';
import { useState } from 'react';
import { PhotoListCarouselItem } from './PhotoListCarouselItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Fingerprint from '@mui/icons-material/Fingerprint';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './index.css';

export type Photo = {
    description: string,
    id: number,
    url: string,
}
export interface PhotoListCarouselProps {
    photos?: Photo[];
    monitorId ?: number;
}

export const PhotoListCarousel = (props: PhotoListCarouselProps): JSX.Element => {
    const {
        photos = []
    } = props;
    const containerRef = useRef<HTMLElement>(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

    const prevSlide = () => {
        if (currentSlideIndex === 0) {
            setCurrentSlideIndex(photos.length - 1);
            return;
        }

        setCurrentSlideIndex(currentSlideIndex - 1);
        return;
    }

    const nextSlide = () => {
        if (currentSlideIndex === photos.length - 1) {
            setCurrentSlideIndex(0);
            return;
        }

        setCurrentSlideIndex(currentSlideIndex + 1);
    }

    return (
        <Box ref={containerRef} sx={{ maxHeight: '100%', height: '100%' }} >
            <Box sx={{ position: 'absolute', top: '50%', width: '100%', padding: '0 10px;', transform: `translateY(-50%)` }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <IconButton sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                        }
                    }}
                        aria-label="fingerprint" color="secondary" onClick={prevSlide}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                        }
                    }} aria-label="fingerprint" color="secondary" onClick={nextSlide}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Box>
  
            {
                photos?.map((photo, index) => {
                    return (
                        <Fade key={photo.id}
                            in={index === currentSlideIndex}
                            mountOnEnter unmountOnExit
                            timeout={0}
                            >
                            <Box sx={{ height: '100%', maxHeight: '100%'} }>
                                <PhotoListCarouselItem key={photo.id} photo={photo}></PhotoListCarouselItem>
                            </Box>
                        </Fade>                      
                    )
                })
            }
        </Box>
    )
}

export default PhotoListCarousel