import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { MonitorRunResultDetailCardHostServiceCard } from './MonitorRunResultDetailCardHostServiceCard';
import { useReadHostServicesByMonitorRunIdQuery } from 'services/hostService';
import CircularProgress from '@mui/material/CircularProgress';
import { CalendarDialog } from './components/MonitorRunResultDetailCardHostServices/components/CalendarDialog';

type MonitorRunResultDetailCardHostServiceListCardProps = {
    hostServices?: any;
    monitorId?: number;
    loading?: boolean,
    onSelectHostServiceForAvailability: (hostService: any) => void
}

export const MonitorRunResultDetailCardHostServiceListCard = ({ hostServices, monitorId, loading = false, onSelectHostServiceForAvailability }: MonitorRunResultDetailCardHostServiceListCardProps): JSX.Element => {

    const handleSelectHostServiceForAvailability = (hostService: any) => {
        onSelectHostServiceForAvailability(hostService)
    }

    const dataRender = () => {
        if (loading === true) {
            return (
                <Box sx={{ height: '300px' } }>
                    <CircularProgress
                        size={68}
                        sx={{
                            color: '#4caf50',
                        }}
                        />
                </Box>
            )
        }
        return hostServices?.list?.map((hostService: any, index: number) => {
            return (              
                <Grid item key={index}>
                        <MonitorRunResultDetailCardHostServiceCard
                            hostService={hostService}
                            monitorId={monitorId}
                            onSelectHostServiceForAvailability={ handleSelectHostServiceForAvailability }
                        />
                    </Grid>
            )
        })
    }

    return (
        <>
            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                {
                    dataRender()
                }
            </Grid>

        </>
    )
}