import { DataGrid } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { Duration } from 'luxon';
import { useAppSelector } from 'shared/hooks';
import { useAppDispatch } from 'shared/hooks';
import { selectMonitorRunForCompetitors } from 'store/applicationSlice';
import { setMonitorRunForCompetitors } from 'store/applicationSlice';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { ScrollableHorizontallyIcon } from 'shared/icons/ScrollableHorizontallyIcon';
import { useState } from 'react';
import { useEffect } from 'react';
import { useReadMonitorRunResultGroupedByMonitorIdQuery } from 'services/monitorRunResult';
import { useUpdateMonitorRunResultByIdMutation } from 'services/monitorRunResult';
import type { GridColDef } from '@mui/x-data-grid';
import type { GridValueGetterParams } from '@mui/x-data-grid';
import type  { ReadMonitorRunResultParams } from 'services/monitorRunResult';

type ParametersListTableProps = {
    monitorId: number,
}

export const ParametersListTable = (
    {
        monitorId
    }: ParametersListTableProps
): JSX.Element => {
    const dispatch = useAppDispatch();
    const monitorRunForCompetitors = useAppSelector(selectMonitorRunForCompetitors);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState<number>(0);
    const [isParametersLoading, setIsParametersLoading] = useState<boolean>(true);
    const [skip, setSkip] = useState<boolean>(true);
    const [parameters, setParameters] = useState<[]>([]);
    const [updateMonitorRun] = useUpdateMonitorRunResultByIdMutation();

    const sameDay = (a: DateTime, b: DateTime): boolean => {
        return a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
    };

    const yesterday = DateTime.local().minus(Duration.fromObject({ day: 1 }));
    const today = DateTime.local();

    const readMonitorRunResultGroupedByMonitorIdQueryParams: ReadMonitorRunResultParams = {
        monitorId: monitorId,
        pagination: paginationModel
    };

    const readMonitorRunResultGroupedByMonitorIdQueryOptions = {
        skip: skip,
        refetchOnMountOrArgChange: true
    };

    const {
        data: readMonitorRunResultGroupedByMonitorIdData,
        isLoading: readMonitorRunResultGroupedByMonitorIdIsLoading,
        isFetching: readMonitorRunResultGroupedByMonitorIdIsFetching,
        refetch
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        readMonitorRunResultGroupedByMonitorIdQueryParams,
        readMonitorRunResultGroupedByMonitorIdQueryOptions
     );

    useEffect(() => {
        setSkip(monitorId === undefined || monitorId === -1);
    }, [monitorId]);

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdIsFetching === true) {
            setParameters([]);
            setIsParametersLoading(true);
            return;
        }

        setParameters(readMonitorRunResultGroupedByMonitorIdData?.list || []);
        setIsParametersLoading(false);
        setRowCount(readMonitorRunResultGroupedByMonitorIdData?.count || 0);
    }, [readMonitorRunResultGroupedByMonitorIdIsFetching]);

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData?.list) {
            readMonitorRunResultGroupedByMonitorIdData?.list?.forEach((item: any) => {
                if (item.viewed === false) {
                    updateMonitorRun({
                        monitorRunId: item.id,
                        monitorRun: {
                            viewed: true
                        }
                    })
                }
            })
        }
    }, [readMonitorRunResultGroupedByMonitorIdData])

    useEffect(() => {
        setSkip(monitorId === undefined || monitorId === -1);
    }, [monitorId]);

    const columns: GridColDef[] = [
        {
            field: 'period',
            headerName: 'Date',
            minWidth: 120,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
                const timestampUtc = DateTime.fromISO(params.row.timestampUtc);

                if (sameDay(today, timestampUtc)) {
                    return `today`;
                }

                if (sameDay(yesterday, timestampUtc)) {
                    return `yesterday`;
                }

                const timestampUtcString = timestampUtc.toFormat('M/dd/yyyy');

                return `${timestampUtcString}`
            }
        },
        {
            field: 'resultsCount',
            minWidth: 120,
            flex: 1,
            headerName: 'Comps',
            renderCell: (cellValues) => {

                const onShowCompetitorsHandler = async () => {
                    dispatch(setMonitorRunForCompetitors(cellValues.row));
                }

                const onHideCompetitorsHandler = async () => {
                    dispatch(setMonitorRunForCompetitors(undefined));
                }

                const onClickHandler = () => {
                    if (cellValues?.row?.id === monitorRunForCompetitors?.id) {
                        onHideCompetitorsHandler();
                        return;
                    }

                    onShowCompetitorsHandler();
                }

                return (
                    <Typography sx={{
                        cursor: 'pointer',
                        fontSize: {
                            xs: '14px',
                            sm: '16px',
                            md: '18px',
                        },
                        color: '#1976d2'
                    }} onClick={onClickHandler}>
                        {cellValues.row.resultsCount}
                    </Typography>
                )
            }
        },
        {
            field: 'maxPrice',
            minWidth: 110,
            flex: 1,
            headerName: 'Max price',
            valueGetter: (params: GridValueGetterParams) => {
                return `$${params.row.maxPrice}`
            }
        },
        {
            field: 'minPrice',
            minWidth: 110,
            flex: 1,
            headerName: 'Min price',
            valueGetter: (params: GridValueGetterParams) => {
                return `$${params.row.minPrice}`
            }
        },
        {
            field: 'averagePrice',
            minWidth: 130,
            flex: 1.5,
            headerName: 'Average price',
            valueGetter: (params: GridValueGetterParams) => {
                return `$${params.row.averagePrice}`
            }
        },
    ];

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'end',
                paddingBottom: '8px',
                paddingRight: '16px'
            }}>
                <Box sx={{
                    display: 'inline-flex',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'

                    }}>
                        <ScrollableHorizontallyIcon sx={{
                            fontSize: '32px'
                        }}></ScrollableHorizontallyIcon>
                        <Typography sx={{
                            fontSize: '10px',
                        }}
                        >Scroll for more
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <DataGrid
                autoHeight={true}
                sx={{
                    height: isParametersLoading ? '180px' : 'auto',
                    minHeight: 180,
                    maxHeight: 400,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                        fontSize: {
                            xs: '14px',
                            sm: '16px',
                            md: '18px',
                        },
                        fontWeight: '600'
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontSize: {
                            xs: '14px',
                            sm: '16px',
                            md: '18px',
                        }
                    },

                    "& .MuiDataGrid-columnHeader": {
                        // Forced to use important since overriding inline styles
                        height: "unset !important"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        // Forced to use important since overriding inline styles
                        maxHeight: "168px !important"
                    }
                }}
                rowCount={rowCount}
                loading={isParametersLoading}
                rows={parameters}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                pageSizeOptions={[5]}
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick
            />
        </>
    )
}










