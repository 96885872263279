import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box, SxProps, Theme } from '@mui/material';
import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { CalendarProps, SubCalendarProps } from '../../Calendar';
import { CircularProgress } from '@mui/material';
import { Backdrop } from '@mui/material';

export const MobileCalendar = (props: SubCalendarProps): JSX.Element => {
    const {
        onMonthDecrease,
        onMonthIncrease,
        startDate,
        endDate,
        slots,
        loading = false,
        sxCalendar
    } = props;

    const onMonthDecreaseHandler = (date: any) => {
        if (onMonthDecrease === undefined) {
            return;
        }

        onMonthDecrease(date);
    }

    const onMonthIncreaseHandler = (date: any) => {
        if (onMonthIncrease === undefined) {
            return;
        }

        onMonthIncrease(date);
    }

    const Day = slots?.day ?? PickersDay;

    return (
        <>
            <Box sx={{
                display: 'grid',
                'justify-content': 'start',
                position: 'relative',
            }}>
                <Box sx={{
                    display: 'grid',
                }}>
                    <Box sx={
                        {
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }>
                        <IconButton
                            onClick={ onMonthDecreaseHandler }
                            sx={
                                {
                                    position: 'absolute',
                                    left: '0'
                                }
                            }
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <h2>{startDate.toFormat('MMMM yyyy')}</h2>
                        <IconButton
                            onClick={onMonthIncreaseHandler}
                            sx={
                                {
                                    position: 'absolute',
                                    right: '0'
                                }
                            }
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                    <DateCalendar
                        dayOfWeekFormatter={(day, date) => {
                            return date.toFormat('ccc').substring(0, 2);
                        }}
                        sx={sxCalendar}
                        value={startDate}
                        loading={loading}
                        slots={{
                            calendarHeader: () => (null),   
                            day: Day 
                        }}
                        slotProps={{
                        
                        }}
                    />
                </Box>      

                    <Backdrop
                        sx={{
                            color: '#fff', height: '100%', position: 'absolute', width: '100%',
                        }}
                        open={loading}
                    >
                        <CircularProgress size="6rem" color="success" />
                    </Backdrop>

            </Box>
        </>
    )
}