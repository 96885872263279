import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Chart as ChartJS } from 'chart.js';
import { LineController } from 'chart.js';
import { CategoryScale } from 'chart.js';
import { LinearScale } from 'chart.js';
import { PointElement } from 'chart.js';
import { LineElement } from 'chart.js';
import { Title } from 'chart.js';
import { Tooltip } from 'chart.js';
import { Legend } from 'chart.js';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Line, Chart as ReactChart } from 'react-chartjs-2';

declare module 'chart.js' {
    interface ChartTypeRegistry {
        CustomLine: ChartTypeRegistry['line']
    }
}


class CustomLine extends LineController {
    draw() {
        super.draw();
    }
};
CustomLine.id = 'CustomLine';
CustomLine.defaults = LineController.defaults;

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    CustomLine,

);

type ParametersChartProps = {
    data?: any,
    options?: any,
    pointsPerPageOptions?: any
}

interface PointsPerPageOption {

}

interface ChartPagination {
    /**
     * Set the number of rows in one page.
     * If some of the rows have children (for instance in the tree data), this number represents the amount of top level rows wanted on each page.
     * @default 100
     */
    pageSize: number;
    /**
     * The zero-based index of the current page.
     * @default 0
     */
    page: number;
}

export const ParametersChart = (
    {
        data = null,
        options,
        pointsPerPageOptions = [10, 25, 50, 100],
    }: ParametersChartProps
): JSX.Element => {
    const chartRef = useRef<any>(null);
    const upperLayerRef = useRef<any>(null);
    const chartContainerRef = useRef<any>(null); 
    const [rectangleSet, setRectangleSet] = useState<boolean>(false);
    const [chartContainerWidth, setChartContainerWidth] = useState<string>('auto');
    const theme = useTheme();
    const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        if (isUpLg) {
            setChartContainerWidth('auto');
            return;
        }

        let maxElementsCount: number = 0;
        data.datasets
            .forEach((dataset: any) => {
                if (dataset.data.length > maxElementsCount) {
                    maxElementsCount = dataset.data.length;
                }
            });

        const width = maxElementsCount * 60;

        if (width < chartContainerRef.current.offsetWidth) {
            setChartContainerWidth('auto');
            return;
        }

        if (width > theme.breakpoints.values.lg) {
            setChartContainerWidth(`${theme.breakpoints.values.lg}px`);
        }

        setChartContainerWidth(`${width}px`);
    }, [isUpLg]);


    const onCompleteHandler = () => {
        if (chartRef.current !== null && upperLayerRef.current !== null) {
            const scale = window.devicePixelRatio;
            const chartInstance = chartRef.current;
            const sourceCanvas = chartInstance.canvas;
            const copyWidth = chartInstance.scales['y'].left + chartInstance.scales['y'].width - 10;
            const copyHeight = chartInstance.scales['y'].height + chartInstance.scales['y'].top + 10;
            const targetCtx = upperLayerRef.current.getContext("2d");

            targetCtx.scale(scale, scale);
            targetCtx.canvas.width = copyWidth * scale;
            targetCtx.canvas.height = copyHeight * scale;

            targetCtx.canvas.style.width = `${copyWidth}px`;
            targetCtx.canvas.style.height = `${copyHeight}px`;
            targetCtx.clearRect(0, 0, upperLayerRef.current.width, upperLayerRef.current.height);
            targetCtx.rect(0, (chartInstance.scales['y'].top - chartInstance.scales['y'].paddingTop + 2) * scale, copyWidth * scale, copyHeight * scale);
            targetCtx.fillStyle = "white";
            targetCtx.fill();
            targetCtx.drawImage(
                sourceCanvas,
                0, (chartInstance.scales['y'].top - chartInstance.scales['y'].paddingTop + 2) * scale, copyWidth * scale, copyHeight * scale,
                0, (chartInstance.scales['y'].top - chartInstance.scales['y'].paddingTop + 2) * scale, copyWidth * scale, copyHeight * scale
            );
        }
    }

    const animation = {
        onComplete: onCompleteHandler,
    }

    options.animation = animation;

    return (
        <>

            <Box sx={{
                position: 'relative',
        
            }}
            >
                <Box sx={{
                    width: 'auto',                
                    overflowX: 'scroll'
                }}
                >
                    <Box
                        ref={chartContainerRef}
                        sx={{
                        width: chartContainerWidth
                    }}>
                        <ReactChart
                            type="CustomLine"    
                            height="400"
                            width="100%"
                            ref={chartRef}
                            options={options}
                            data={data}
                        />
                    </Box>
                </Box>
                <Box
                    component="canvas"
                    height="600px"
                    ref={upperLayerRef}
                    sx={{
                        position: `absolute`,
                        left: `0`,
                        top: `0`,
                        pointerEvents: `none`
                    }}
                />  
            </Box>
        </>
    )
}