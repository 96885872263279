
import { useState } from 'react';
import { useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Calendar } from 'components/Calendar'; 
import { DateTime } from 'luxon';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { MonitorRunResultHostService } from 'shared/types';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { useDeleteMonitorMutation } from 'services/monitor'
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BedIcon from '@mui/icons-material/Bed';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FlakyIcon from '@mui/icons-material/Flaky';
import DescriptionIcon from '@mui/icons-material/Description';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { CompetitorsIcon } from 'shared/icons/CompetitorsIcon';
import { MaxPriceIcon } from 'shared/icons/MaxPriceIcon';
import { MinPriceIcon } from 'shared/icons/MinPriceIcon';
import { AveragePriceIcon } from 'shared/icons/AveragePriceIcon';
import { useAppSelector } from 'shared/hooks';
import { useAppDispatch } from 'shared/hooks';
import { selectMonitorRunForCompetitors } from 'store/applicationSlice';
import { setMonitorRunForCompetitors } from 'store/applicationSlice';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#EEEEEE',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary,
}));


export interface DayDialogProps {
    id?: string;
    monitor?: any;
    keepMounted: boolean;
    loading?: boolean;
    value?: MonitorRunResultHostService;
    open: boolean;
    fullScreen: boolean,
    onClose: () => void;
}

export const DayDialog = (props: DayDialogProps): JSX.Element => {
    const { monitor, onClose, value: valueProp, open, keepMounted, loading = false, ...other } = props;
    const dispatch = useAppDispatch();
    const monitorRunForCompetitors = useAppSelector(selectMonitorRunForCompetitors);

    const handleClose = () => {
        onClose();
    };

    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleClickAway = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenMenu(false);
    };

    const renderRecommendations = () => {
        const hasRecommendations = valueProp?.recommendations?.length > 0;
        const checkinUtc = DateTime.fromISO(valueProp?.monitorRunsResult?.checkinUtc).toFormat('M/dd/yyyy');
        const checkOutUtc = DateTime.fromISO(valueProp?.monitorRunsResult?.checkOutUtc).toFormat('M/dd/yyyy');

        if (hasRecommendations) {
            return (
                <>
                    <h3>Recommendations for {`${checkinUtc} - ${checkOutUtc}`}</h3>
                    {
                        valueProp?.recommendations.map((recommendation: any, index: any) => {
                            return (
                                <Typography key={index} variant="h5" gutterBottom>{recommendation}</Typography>
                            )
                        })
                    }
                    <Divider></Divider>
                </>
            )
        } else {
            return <>
                <h3>Recommendations for {`${checkinUtc} - ${checkOutUtc}`}</h3>
                <Typography variant="h5" gutterBottom>Everything is fine</Typography>
                <Divider></Divider>
            </>
        }
    }      

    const showCompetitorsHandler = async () => {
        dispatch(setMonitorRunForCompetitors(valueProp?.monitorRunsResult));
    }

    const hideCompetitorsHandler = async () => {
        dispatch(setMonitorRunForCompetitors(undefined));
    }

    const handleCompetitorsClick = () => {
        if (valueProp?.monitorRunsResult.id === monitorRunForCompetitors?.id) {
            hideCompetitorsHandler();
            return;
        }


        showCompetitorsHandler();
        handleClose();
    }


    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: 'auto', maxHeight: '590px', height: 'auto' } }}
            keepMounted 
            maxWidth="xs"
            open={open}

            {...other}
        >
            <DialogContent dividers>
                {
                    renderRecommendations()
                }
                <Grid container spacing={2} sx={{my: '10px', width: 'auto'}}>
                    <Grid item xs={12} md={6}>
                        <Item sx={{ display: 'flex', cursor: 'pointer' }} onClick={handleCompetitorsClick}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <CompetitorsIcon sx={{
                                    fontSize: '32px',
                                    fill: '#1976d2',
                                    
                                }} />
                                <Box component="span" sx={{ fontSize: '24px', color: '#1976d2' }}>Competitors</Box>
                            </Box>
                            <Box component="span" sx={{
                                fontSize: '24px',
                                cursor: 'pointer',
                                 color: '#1976d2'
                            }} >{valueProp?.monitorRunsResult?.resultsCount}</Box>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Item sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <MaxPriceIcon sx={{
                                    fontSize: '32px',
                                    fill: '#B0B0B0'
                                }} />
                                <Box component="span" sx={{fontSize: '24px'}}>Max price</Box>
                            </Box>
                            <Box component="span" sx={{ fontSize: '24px' }}>${valueProp?.monitorRunsResult?.maxPrice}</Box>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Item sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <MinPriceIcon sx={{
                                    fontSize: '32px',
                                    fill: '#B0B0B0'
                                }} />
                                <Box component="span" sx={{ fontSize: '24px' }}>Min price</Box>
                            </Box>
                            <Box component="span" sx={{ fontSize: '24px' }}>${valueProp?.monitorRunsResult?.minPrice}</Box>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Item sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <AveragePriceIcon sx={{
                                    fontSize: '32px',
                                    fill: '#B0B0B0'
                                }} />
                                <Box component="span" sx={{ fontSize: '24px' }}>Average price</Box>
                            </Box>
                            <Box component="span" sx={{ fontSize: '24px' }}>${valueProp?.monitorRunsResult?.averagePrice}</Box>
                        </Item>
                    </Grid>
                </Grid>

               

            </DialogContent>
            <DialogActions>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    gap={2}
                >
                    <Box
                        sx={{
                            flexShrink: 1,
                            alignSelf: { xs: 'flex-start', sm: 'center' },
                        }}
                    >
                        {/*
                        <Button  autoFocus onClick={handleClose}>
                         Competitors
                        </Button>

                        */}
                    </Box>
                    <Stack
                        gap={2}
                        direction={{
                            xs: 'row-reverse',
                            sm: 'row',
                        }}
                        sx={{
                            flexShrink: 0,
                            alignSelf: { xs: 'flex-end', sm: 'center' },
                        }}
                    >
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={handleClose}>Close
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}