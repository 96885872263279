import { useEffect } from 'react';
import { MonitorRunResultListCard } from 'components/MonitorRunResultListCard'
import { reset } from 'store/monitorRunResultSlice';
import { useAppDispatch } from 'shared/hooks'

export const MonitorRunResultListPage = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(reset())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <>
            <MonitorRunResultListCard></MonitorRunResultListCard>
        </>
    )
}