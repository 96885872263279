import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'queries';

import type { Monitor } from 'shared/types';
import { SortOrder } from 'shared/enums';

export interface ReadHostServicesByMonitorRunIdParams {
    monitorRunId: number,
    sorting?: {
        orderField: string,
        sortOrder: SortOrder
    },
    pagination?: {
        page: number,
        pageSize: number
    }
}

export interface ReadHostServicePricesByMonitorIdParams {
    monitorId: number,
    hostServiceId: number,
    pagination?: {
        page: number,
        pageSize: number
    }
}

export interface ReadHostServiceByMonitorIdParams {
    monitorId: number,
    hostServiceId: number
}

interface SetMineHostServiceQueryParams {
    hostServiceId: number
}

export interface ReadHostServiceByIdParams {
    hostServiceId: number;
    monitorId: number;
}

export const hostServiceApi = createApi({
    reducerPath: 'hostServiceApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['HostServices'],
    endpoints: (builder) => ({
        readHostServicesByMonitorRunId: builder
            .query({
                query: (params: ReadHostServicesByMonitorRunIdParams) => {
                    let url = `/monitorruns/${params.monitorRunId}/results`;
                    const additionalParams: any[] = [];

                    if (params?.pagination) {
                        const skip: number = params.pagination.page * params.pagination.pageSize;
                        const take: number = params.pagination.pageSize;

                        additionalParams.push({
                            name: 'skip',
                            value: skip
                        });

                        additionalParams.push({
                            name: 'take',
                            value: take
                        });
                    }

                    if (params.sorting !== undefined
                        && params.sorting.orderField !== undefined
                        && params.sorting.sortOrder !== undefined) {
                        additionalParams.push({
                            name: 'orderField',
                            value: params?.sorting.orderField
                        });

                        additionalParams.push({
                            name: 'sortOrder',
                            value: params?.sorting.sortOrder
                        });
                    }

                    if (additionalParams.length > 0) {
                        const paramsString: string = additionalParams
                            .map((additionalParam) => {
                                return `${additionalParam.name}=${additionalParam.value}`;
                            })
                            .join('&');

                        url = `${url}?${paramsString}`
                    }

                    return url;
                },
                providesTags: (result = { list: [], count: 0 }) => result
                    ? [
                        ...result.list.map(({ id }: any) => ({ type: 'Monitors', id })),
                        { type: 'HostServices', id: 'LIST' },
                    ]
                    : [
                        { type: 'HostServices', id: 'LIST' }
                    ],
            }),
        readHostServicePricesByMonitorId: builder
            .query({
                query: (params: ReadHostServicePricesByMonitorIdParams) => {                
                    let url = `/monitor/${params.monitorId}/hostservices/${params.hostServiceId}/prices`;
                    
                    if (params?.pagination) {
                        const skip: number = params.pagination.page * params.pagination.pageSize;
                        const take: number = params.pagination.pageSize;
                        url += `?skip=${skip}&take=${take}`;
                    }

                    return url;
                },
            }),
        readHostServiceByMonitorId: builder
            .query({
                query: (params: ReadHostServicePricesByMonitorIdParams) => {
                    let url = `/hostservices/${params.hostServiceId}?monitorId=${params.monitorId}`;

                    return url;
                },
            }),
        setMineHostService: builder
            .mutation({
                query: ({ hostServiceId }: SetMineHostServiceQueryParams) => ({
                    url: `/hostservices/${hostServiceId}/setmine`,
                    method: 'GET',
                }),
                invalidatesTags: [
                    {
                        type: 'HostServices',
                        id: 'LIST'
                    },
                ]
            })
    }),
});

export const {
    useReadHostServicesByMonitorRunIdQuery,
    useReadHostServiceByMonitorIdQuery,
    useReadHostServicePricesByMonitorIdQuery,
    useSetMineHostServiceMutation,
} = hostServiceApi;
