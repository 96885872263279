import { mainApi } from 'services/mainApi';
import type { Monitor, MonitorCreateResource, MonitorUpdateResource } from 'shared/types';

interface CreateMonitorQueryParams {
    monitor: MonitorCreateResource
}
interface UpdateMonitorOwnPriceQueryParams {
    monitorId: number,
    price: number
    date: string
}

interface ReadMonitorByIdParams {
    monitorId: number
}


interface ReadMonitorsParams {
    pagination?: {
        page: number,
        pageSize: number
    }
}

interface UpdateMonitorQueryParams {
   monitor: MonitorUpdateResource
}

interface DeleteMonitorQueryParams {
    monitorId: number
}

interface RunMonitorQueryParams {
    monitorId: number
}

export const monitorApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        readMonitors: builder.query({
            query: (params: ReadMonitorsParams) => {
                let url = `/monitor`;

                if (params?.pagination) {
                    const skip: number = params.pagination.page * params.pagination.pageSize;
                    const take: number = params.pagination.pageSize;
                    url = `/monitor?skip=${skip}&take=${take}`;
                }

                return url;
            },
            providesTags: (result = { list: [], count: 0 }) => result
                ? [
                    ...result.list.map(({ id }: any) => ({ type: 'Monitors', id })),
                    { type: 'Monitors', id: 'LIST' },
                ]
                : [
                    { type: 'Monitors', id: 'LIST' }
                ],
        }),
        readMonitorsShort: builder.query<any, void>({
            query: () => `/monitor/resultscounts`,
            providesTags: (result) => result
                ? [
                    ...result.list.map(({ id }: any) => ({ type: 'MonitorsShort', id })),
                    { type: 'MonitorsShort', id: 'LIST' },
                ]
                : [
                    { type: 'MonitorsShort', id: 'LIST' }
                ],
        }),
        readMonitorById: builder.query({
            query: ({ monitorId }: ReadMonitorByIdParams) => `/monitor/${monitorId}`,
            providesTags: (result, error, arg) => ['Monitor'],               
        }),
        readMonitorCurrentlaunchStatus: builder.query({
            query: ({ monitorId }: ReadMonitorByIdParams) => `/monitor/${monitorId}/currentlaunchstatus`
        }),
        createMonitor: builder.mutation({
            query: ({ monitor }: CreateMonitorQueryParams) => ({
                url: `monitor`,
                method: 'POST',
                body: monitor
            }),
            invalidatesTags: [
                {
                    type: 'MonitorsShort',
                    id: 'LIST'
                },
                {
                    type: 'Monitors',
                    id: 'LIST'
                },
            ]
        }),
        updateMonitorOwnPrice: builder.mutation({
            query: ({ monitorId, date, price }: UpdateMonitorOwnPriceQueryParams) => ({
                url: `monitor/${monitorId}/prices`,
                method: 'POST',
                body: {
                    monitorId,
                    date,
                    price
                }
            }),
            invalidatesTags: [
                {
                    type: 'MonitorsShort',
                    id: 'LIST'
                },
                {
                    type: 'Monitors',
                    id: 'LIST'
                },
            ]
        }),
        updateMonitor: builder.mutation({
            query: ({ monitor }: UpdateMonitorQueryParams) => ({
                url: `monitor/${monitor.id}`,
                method: 'put',
                body: monitor
            }),
            invalidatesTags: [
                {
                    type: 'MonitorsShort',
                    id: 'LIST'
                },
                {
                    type: 'Monitors',
                    id: 'LIST'
                },
                'MonitorRunsResultCard',
                'Monitor'
            ]
        }),
        deleteMonitor: builder.mutation({
                query: ({ monitorId }: DeleteMonitorQueryParams) => ({
                    url: `monitor/${monitorId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [
                    {
                        type: 'MonitorsShort',
                        id: 'LIST'
                    },
                    {
                        type: 'Monitors',
                        id: 'LIST'
                    },
                ]
        }),
        runMonitor: builder.query({
            query: ({ monitorId }: RunMonitorQueryParams) => ({
                url: `monitor/${monitorId}/run`,
            })
        })
    }),
});

export const {
    useReadMonitorsShortQuery,
    useReadMonitorsQuery,
    useReadMonitorByIdQuery,
    useRunMonitorQuery,
    useLazyRunMonitorQuery,
    useLazyReadMonitorByIdQuery,
    useDeleteMonitorMutation,
    useCreateMonitorMutation,
    useUpdateMonitorMutation,
    useReadMonitorCurrentlaunchStatusQuery,
    useUpdateMonitorOwnPriceMutation,
} = monitorApi;
