import { Box } from '@mui/material';
import {
    useCreateUserSettingsMutation,
    useReadUserSettingsQuery,
    useUpdateUserSettingsMutation
} from 'services/user';
import { UserSettingsForm } from '../RecommendationsSettingsView/components/UserSettingsForm';
import { useEffect, useState } from 'react';
import type { UserSettings } from 'shared/types'
import { createBlankUserSettings } from 'domain/userSettings';

export const RecommendationsSettingsView = (): JSX.Element => {
    const useReadHostServicesByMonitorRunIdQueryOptions = {
        skip: false
    }

    const {
        data: readUserSettingsData,
        isLoading: readUserSettingsIsLoading,
        isFetching: readUserSettingsIsFetching,
        error: readUserSettingsError
    } = useReadUserSettingsQuery(useReadHostServicesByMonitorRunIdQueryOptions);

    const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined)
    const [hasUserSettings, setHasUserSettings] = useState<boolean>(false)


    useEffect(() => {
        if (readUserSettingsError !== undefined
            && 'data' in readUserSettingsError
            && readUserSettingsError.status === 404
            && readUserSettingsIsFetching === false) {
            setHasUserSettings(false);
            setUserSettings(createBlankUserSettings());
        } else {
            setHasUserSettings(true)
            setUserSettings(readUserSettingsData)
        }
    }, [readUserSettingsIsFetching, readUserSettingsError]);

    const [createUserSettings] = useCreateUserSettingsMutation();
    const [updateUserSettings] = useUpdateUserSettingsMutation();

    const handleSubmit = async ({ event, value }: any) => {
        const params = {
            settings: value
        }

        if (hasUserSettings === true) {
            await updateUserSettings(params);

            return;
        }

        await createUserSettings(params);
    }

    return (
        <>
            <h2>User settings</h2>
            <Box sx={{ width: '100%' }}>
                <UserSettingsForm
                    loading={!(readUserSettingsIsLoading === false && readUserSettingsIsFetching === false)}
                    value={userSettings}
                    onSubmit={handleSubmit}
                />
            </Box>
        </>
    );

}