import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { ApplicationMainDrawerMenu } from './ApplicationMainDrawerMenu'
import { useReadMonitorsShortQuery } from 'services/monitor';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { drawerWidth } from 'config';
import { useInterval } from 'usehooks-ts';
import { readMonitorsCount } from 'services/monitor';
import { readMonitorRunsCount } from 'services/monitorRun';

import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { selectDrawerOpen, setDrawerOpen } from 'store/applicationSlice';


export const ApplicationMainDrawer = () => { 
    const location = useLocation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xs'));
    const dispatch = useAppDispatch();
    const drawerOpen = useAppSelector(selectDrawerOpen);

    const handleDrawerToggle = () => {
        dispatch(setDrawerOpen(false))
    };

    const useReadMonitorsShortQueryOptions = {
        pollingInterval: 5000,
    };

    const {
        data: readMonitorsShortQueryData,
        isLoading: readMonitorsShortQueryIsLoading
    } = useReadMonitorsShortQuery(undefined, useReadMonitorsShortQueryOptions);



    useEffect(() => {
        if (location.pathname === '/' && readMonitorsShortQueryData?.list?.length > 0) {
            dispatch(setDrawerOpen(true));
            return;
        }

        if (matches) {
            dispatch(setDrawerOpen(false))
        }
    }, [location, readMonitorsShortQueryData]);

    const drawer = (
        <ApplicationMainDrawerMenu></ApplicationMainDrawerMenu>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
            <Drawer
                container={container}
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, paddingTop: '84px' },

                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, paddingTop: '84px' },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}
