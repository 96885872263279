import { AndroidInstallInstruction } from './components/AndroidInstallInstruction';
import { IphoneAndIpadInstallInstruction } from './components/IphoneAndIpadInstallInstruction';
import { ComputerInstallInstruction } from './components/ComputerInstallInstruction';
import { useCreatePushNotificationSubscriptionMutation } from 'services/pushNotificationSubscription';
import { useEffect } from 'react';
import { useState } from 'react';

export const InstallInstruction = (): JSX.Element => {
    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [createPushNotificationSubscription] = useCreatePushNotificationSubscriptionMutation();
    const [mode, setMode] = useState<string>('short');

    const checkNotifications = async () => {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration === undefined) {
            return;
        }

        const subscribed = await registration.pushManager.getSubscription();
        if (subscribed) {
            setSubscribed(true);
            return;
        }
    }

    const subscribeToPermissionsChange = async () => {
        // @ts-ignore
        const res = await navigator.permissions.query({ name: "push", userVisibleOnly: true })
        res.addEventListener('change', (res: any) => {
            if (res.target.state === 'granted') {
                setSubscribed(true);
            }
     
        })
   
    }

    useEffect(() => {
        checkNotifications();
        subscribeToPermissionsChange();
    }, [])


    const urlB64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    const createPushNotificationSubscriptionHandler = async (subscription: any) => {
        const endpoint = subscription.endpoint;
        const subscriptionSimpleObject = JSON.parse(JSON.stringify(subscription));

        await createPushNotificationSubscription({
            endpoint: endpoint,
            p256dh: subscriptionSimpleObject.keys.p256dh,
            auth: subscriptionSimpleObject.keys.auth,
        })
    }



    const subscribeToPushNotifications = async () => {
        const result = await Notification.requestPermission();

        if (result === 'denied') {
            return;
        }

        const registration = await navigator.serviceWorker.getRegistration();

        if (registration === undefined) {
            return;
        }

        const subscribed = await registration.pushManager.getSubscription();

        if (subscribed) {
            //
            await createPushNotificationSubscriptionHandler(subscribed);
            //
            return;
        }

        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(`BHeQ1k-h3-p2egmUGJgUhVUey5Zp9BwwfPoR9nL0qzmHk6yH_8aHMgosGcQ7Ly-R1AxEDu5lLNcT8f_k83ldwcI`)
        });

        await createPushNotificationSubscriptionHandler(subscription);
    }

    const handleRequestEnableNotifications = async () => {
        await subscribeToPushNotifications();
        setSubscribed(true);
    }
    const handleRequestDisableNotifications = async () => {  
        setSubscribed(false);
    }

    const handleModeChanged = (event: any) => {
        setMode(event.value);
    }

    return (
        <>
            <IphoneAndIpadInstallInstruction
                mode={mode}
                subscribed={subscribed}
                onRequestEnableNotifications={handleRequestEnableNotifications}
                onRequestDisableNotifications={handleRequestDisableNotifications}
                onModeChanged={handleModeChanged}
            />
            <AndroidInstallInstruction
                mode={mode}
                subscribed={subscribed}
                onRequestEnableNotifications={handleRequestEnableNotifications}
                onRequestDisableNotifications={handleRequestDisableNotifications}
            />
            <ComputerInstallInstruction
                subscribed={subscribed}
                onRequestEnableNotifications={handleRequestEnableNotifications}
                onRequestDisableNotifications={handleRequestDisableNotifications}
            />          
        </>
    )
}