import React from 'react';
import { useState } from 'react';
import Chip from './../../components/Chip/Chip'
import CloseIcon from '@mui/icons-material/Close'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import type { TextFieldProps } from '@mui/material/TextField'
import { KEYBOARD_KEY, KEYBOARD_KEYCODE } from './../../shared/constants/event'
import { matchIsBoolean } from './../../shared/helpers/boolean'
import { assocRefToPropRef } from './../../shared/helpers/ref'
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import BedIcon from '@mui/icons-material/Bed';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';
import FlakyIcon from '@mui/icons-material/Flaky';
import type {
    MuiChipsInputChip,
    MuiChipsInputChipComponent,
    MuiChipsInputChipProps,
    MuiChipsInputProps
} from '../../index.types'
import Styled from './TextFieldChips.styled'

type TextFieldChipsProps = TextFieldProps & {
    chips: MuiChipsInputChip[]
    onAddChip?: (chip: MuiChipsInputChip) => void
    onEditChip?: (chip: MuiChipsInputChip, chipIndex: number) => void
    clearInputOnBlur?: boolean
    hideClearAll?: boolean
    disableDeleteOnBackspace?: boolean
    addOnWhichKey?: string | string[]
    disableEdition?: boolean
    inputValue?: string
    validate?: MuiChipsInputProps['validate']
    onInputChange?: (inputValue: string) => void
    onDeleteChip?: (chipIndex: number) => void
    onDeleteAllChips?: () => void
    autoCompleteOptions?: string[]
    renderChip?: (
        ChipComponent: MuiChipsInputChipComponent,
        key: React.Key,
        ChipProps: MuiChipsInputChipProps
    ) => JSX.Element
}

const TextFieldChips = React.forwardRef(
    (
        props: TextFieldChipsProps,
        propRef: TextFieldChipsProps['ref']
    ): React.ReactElement => {
        const {
            chips,
            onAddChip,
            onEditChip,
            onDeleteChip,
            onDeleteAllChips,
            InputProps,
            onInputChange,
            disabled,
            clearInputOnBlur,
            validate,
            error,
            helperText,
            hideClearAll,
            inputProps,
            size,
            disableDeleteOnBackspace,
            disableEdition,
            className,
            renderChip,
            addOnWhichKey,
            onFocus,
            inputRef: inputRefFromProp,
            inputValue: inputValueControlled,
            autoCompleteOptions,
            ...restTextFieldProps
        } = props;
        const [anchorProp, setAnchorProp] = useState<null | HTMLElement>(null);
        const [openProp, setOpenProp] = useState<boolean>(false);

        const [inputValueUncontrolled, setInputValueUncontrolled] =
            React.useState<string>('')
        const [textError, setTextError] = React.useState<string>('')
        const inputElRef = React.useRef<HTMLDivElement | null>(null)
        const isFocusingRef = React.useRef<boolean>(false)
        const isControlledRef = React.useRef(
            typeof inputValueControlled === 'string'
        )
        const [chipIndexEditable, setChipIndexEditable] = React.useState<
            null | number
        >(null)
        const { onKeyDown, ...restInputProps } = inputProps || {}
        const { inputRef, ...restIInputProps } = InputProps || {}

        const clearTextError = () => {
            setTextError('')
        }

        const isControlled = isControlledRef.current
        const inputValue = isControlled
            ? (inputValueControlled as string)
            : inputValueUncontrolled

        const updateInputValue = (newInputValue: string) => {
            onInputChange?.(newInputValue)
            if (!isControlled) {
                setInputValueUncontrolled(newInputValue)
            }

        }

        const updateChipIndexEditable = (chipIndex: number) => {
            updateInputValue(chips[chipIndex])
            setChipIndexEditable(chipIndex)
            clearTextError()
        }

        const clearChipIndexEditable = () => {
            setChipIndexEditable(null)
        }

        const clearInputValue = () => {
            clearTextError()
            updateInputValue('')
        }

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            updateInputValue(event.target.value)
        }

        const handleClickAway = (event: any) => {
            if (!isFocusingRef.current) {
                return
            }

            const inputValueTrimed = inputValue.trim()
            if (inputValue.length > 0) {
                if (inputValueTrimed.length === 0) {
                    clearInputValue()
                } else if (chipIndexEditable !== null) {
                    updateChip(inputValueTrimed, chipIndexEditable, event)
                } else {
                    addChip(inputValueTrimed, event)
                }
            }

            //if (chipIndexEditable !== null) {
            //  clearChipIndexEditable()
            //  clearInputValue()
            //} else if (clearInputOnBlur) {
            //  clearInputValue()
            //}


            isFocusingRef.current = false
        }

        const handleRef = (ref: HTMLDivElement | null): void => {
            // @ts-ignore
            inputElRef.current = ref
            if (inputRefFromProp) {
                assocRefToPropRef(ref, inputRefFromProp)
            }
            if (propRef) {
                assocRefToPropRef(ref, propRef)
            }
        }

        const validationGuard = (
            chipValue: MuiChipsInputChip,
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            return (callback: () => void) => {
                if (typeof validate === 'function') {
                    const validation = validate(chipValue)
                    if (validation === false) {
                        event.preventDefault()
                        return
                    }
                    if (!matchIsBoolean(validation) && validation.isError) {
                        event.preventDefault()
                        setTextError(validation.textError)
                        return
                    }
                }
                callback()
            }
        }

        const updateChip = (
            chipValue: MuiChipsInputChip,
            chipIndex: number,
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            validationGuard(
                chipValue,
                event
            )(() => {
                onEditChip?.(chipValue, chipIndex)
                clearChipIndexEditable()
                clearInputValue()
            })
        }

        const addChip = (
            chipValue: MuiChipsInputChip,
            event: React.KeyboardEvent<HTMLInputElement>,
            value?: string
        ) => {
            validationGuard(
                chipValue,
                event
            )(() => {
                if (value !== undefined) {
                    onAddChip?.(value)
                } else {
                    onAddChip?.(inputValue.trim())
                }
           
                clearInputValue()
            })
        }

        const matchIsValidKeyToAdd = (eventKey: string, eventKeyCode: number) => {
            if (eventKeyCode === KEYBOARD_KEYCODE.ime) {
                return false
            }

            if (addOnWhichKey) {
                if (Array.isArray(addOnWhichKey)) {
                    return addOnWhichKey.some((key) => {
                        return key === eventKey
                    })
                }
                return addOnWhichKey === eventKey
            }

            return eventKey === KEYBOARD_KEY.enter
        }


        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (autoCompleteOptions !== undefined && autoCompleteOptions.length > 0) {
                setOpenProp(true);
            }

            const isKeyIsAdd = matchIsValidKeyToAdd(event.key, event.keyCode)
            const isBackspace = event.key === KEYBOARD_KEY.backspace
            const inputValueTrimed = inputValue.trim()

            if (!isKeyIsAdd && event.code === 'Tab') {
                handleClickAway(event)
                return
            }

            if (isKeyIsAdd) {
                event.preventDefault()
            }

            if (inputValue.length > 0 && isKeyIsAdd) {
                if (inputValueTrimed.length === 0) {
                    clearInputValue()
                } else if (chipIndexEditable !== null) {
                    updateChip(inputValueTrimed, chipIndexEditable, event)
                } else {
                    addChip(inputValueTrimed, event)
                }
            } else if (
                isBackspace &&
                inputValue.length === 0 &&
                chips.length > 0 &&
                !disableDeleteOnBackspace
            ) {
                const chipIndex = chips.length - 1
                onDeleteChip?.(chipIndex)
                if (chipIndexEditable === chipIndex) {
                    clearChipIndexEditable()
                }
            }

            onKeyDown?.(event)
        }

        const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
            setAnchorProp(event.target);
            setOpenProp(true);
            event.preventDefault()
            onFocus?.(event)
            isFocusingRef.current = true
        }

        const handleBlur = () => {
            setOpenProp(false);
        }

        const handleClearAll = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            if (!hideClearAll && !disabled) {
                onDeleteAllChips?.()
                clearInputValue()
                clearChipIndexEditable()
            }
        }

        const handleEdit = (chipIndex: number) => {
            if (chipIndex === chipIndexEditable) {
                clearInputValue()
                clearChipIndexEditable()
            } else {
                updateChipIndexEditable(chipIndex)
            }

            inputElRef.current?.focus()
        }

        const handleDeleteChip = (chipIndex: number) => {
            if (disabled) {
                return
            }
            onDeleteChip?.(chipIndex)
            if (chipIndexEditable !== null) {
                clearChipIndexEditable()
                clearInputValue()
            }
        }

        const hasAtLeastOneChip = chips.length > 0;

        const handleClickHelperItem = (event: any) => {    

            updateInputValue(event.target.textContent);

            //if (!isFocusingRef.current) {
            //    return
            //}

            const inputValueTrimed = event.target.textContent.trim()
            if (inputValueTrimed.length > 0) {
                if (inputValueTrimed.length === 0) {
                    clearInputValue()
                } else if (chipIndexEditable !== null) {
                    updateChip(inputValueTrimed, chipIndexEditable, event)
                } else {
                    addChip(inputValueTrimed, event, inputValueTrimed)
                }
            }
        }


        const renderPopup = () => {
            if (autoCompleteOptions === undefined || autoCompleteOptions.length === 0) {
                return (
                    <>
                    </>
                )
            }

            return (<Popper
                sx={{ marginTop: '120px' }}
                open={openProp}
                anchorEl={anchorProp}
                transition
                placement={'bottom-end'}
                onClick={handleClickHelperItem}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper sx={{ marginTop: '20px', marginRight: '-20px' }}>
                            <List component="nav" >
                                {
                                    autoCompleteOptions?.map((opt: any) => {
                                        return (
                                            <ListItemButton sx={{ zIndex: 3 }} key={opt}>
                                                <ListItemText primary={opt} />
                                            </ListItemButton>
                                        )
                                    })
                                }
                            </List>
                        </Paper>
                    </Grow>
                )}
            </Popper>)
        }

        return (
            <>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Styled.TextFieldStyled
                        value={inputValue}
                        onChange={handleChange}
                        ref={propRef}
                        className={`MuiChipsInput-TextField ${className || ''}`}
                        size={size}
                        placeholder="Type and press enter"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputProps={{
                            onKeyDown: handleKeyDown,
                            ...restInputProps
                        }}
                        disabled={disabled}
                        error={Boolean(textError) || error}
                        helperText={textError || helperText}
                        InputProps={{
                            inputRef: handleRef,
                            autoComplete: 'off',
                            startAdornment: hasAtLeastOneChip
                                ? chips.map((chip, index) => {
                                    const key = `chip-${index}`
                                    const ChipProps: MuiChipsInputChipProps = {
                                        index,
                                        onEdit: handleEdit,
                                        label: chip,
                                        title: chip,
                                        isEditing: index === chipIndexEditable,
                                        size,
                                        disabled,
                                        disableEdition,
                                        onDelete: handleDeleteChip
                                    }
                                    return renderChip ? (
                                        renderChip(Chip, key, ChipProps)
                                    ) : (
                                        <Chip {...ChipProps} key={key} />
                                    )
                                })
                                : null,
                            endAdornment: !hideClearAll ? (
                                <Styled.EndAdornmentClose
                                    style={{ visibility: hasAtLeastOneChip ? 'visible' : 'hidden' }}
                                >
                                    <IconButton
                                        aria-label="Clear"
                                        title="Clear"
                                        disabled={disabled}
                                        size="small"
                                        onClick={handleClearAll}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Styled.EndAdornmentClose>
                            ) : null,
                            ...restIInputProps
                        }}
                        {...restTextFieldProps}
                    />

                </ClickAwayListener>

                {
                    renderPopup()
                }
            </>       
        )
    }
)

TextFieldChips.defaultProps = {
    onInputChange: () => { },
    clearInputOnBlur: false,
    hideClearAll: false,
    disableDeleteOnBackspace: false,
    disableEdition: false,
    addOnWhichKey: KEYBOARD_KEY.enter,
    onDeleteChip: () => { },
    onAddChip: () => { },
    inputValue: undefined,
    onEditChip: () => { },
    renderChip: undefined,
    onDeleteAllChips: () => { },
    validate: () => {
        return true
    }
}

export default TextFieldChips
