import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import applicationReducer from 'store/applicationSlice';
import monitorReducer from 'store/monitorSlice';
import monitorRunSliceReducer from 'store/monitorRunSlice';
import monitorRunResulstSliceReducer from 'store/monitorRunResultSlice';
import platformSliceReducer from 'store/platformsSlice';
import hostServiceDetailReducer from 'store/hostServiceDetailSlice';
import { mainApi } from 'services/mainApi';
import { monitorApi } from 'services/monitor';
import { monitorRunApi } from 'services/monitorRun';
import { monitorRunResultApi } from 'services/monitorRunResult';
import { hostServiceApi } from 'services/hostService';
import { platformApi } from 'services/platform';
import { userApi } from 'services/user';
import { pushNotificationSubscriptionApi } from 'services/pushNotificationSubscription';

export const store = configureStore({
    reducer: {
        application: applicationReducer,
        monitor: monitorReducer,
        monitorRun: monitorRunSliceReducer,
        monitorRunResult: monitorRunResulstSliceReducer,
        platform: platformSliceReducer,
        hostServiceDetail: hostServiceDetailReducer,
        [mainApi.reducerPath]: mainApi.reducer,
        [monitorRunApi.reducerPath]: monitorRunApi.reducer,
        [hostServiceApi.reducerPath]: hostServiceApi.reducer,
        [pushNotificationSubscriptionApi.reducerPath]: pushNotificationSubscriptionApi.reducer,
        [platformApi.reducerPath]: platformApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            mainApi.middleware,
            monitorRunApi.middleware,
            hostServiceApi.middleware,
            pushNotificationSubscriptionApi.middleware,
            platformApi.middleware,
            userApi.middleware
        ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;

