import * as React from "react";
import { MonitorListTable } from 'components/MonitorListTable';
import { MonitorListCard } from 'components/MonitorListCard';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Monitor } from 'shared/types';

export function MonitorListPage() {
    const [viewMode, setViewMode] = useState('table');
    const theme = useTheme();
    const isNarrowScreen = useMediaQuery(theme.breakpoints.down("md"));

    const viewModeChanged = (
        event: React.MouseEvent<HTMLElement>,
        newViewMode: string,
    ) => {
        setViewMode(newViewMode);
    };

    const viewTasksRender = () => {
        if (viewMode === 'table') {
            return (
                <MonitorListTable></MonitorListTable>
            )
        }

        if (viewMode === 'cards') {
            return (
                <MonitorListCard></MonitorListCard>
            )
        }
    }

    const toggleSelectorRender = () => {
        if (isNarrowScreen) {
            return ''
        }

        return (
            <>
                <ToggleButtonGroup
                    color="primary"
                    value={viewMode}
                    exclusive
                    onChange={viewModeChanged}
                    aria-label="Platform"
                >
                    <ToggleButton value="table">Table</ToggleButton>
                    <ToggleButton value="cards">Cards</ToggleButton>
                </ToggleButtonGroup>
            </>
        )
    }

    useEffect(() => {
        setViewMode(isNarrowScreen ? 'cards' : 'table')
    }, [isNarrowScreen]);

	return (
		<>
            <h2>Spies</h2>
            <Box my={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="row" spacing={2}>
                    <Link to="/spy/create">
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{
                                minWidth: 110,
                            }}
                        >
                            Create spy
                        </Button >
                    </Link>
                </Stack>
                <div>
                    {
                        toggleSelectorRender()
                    }
                </div>
            </Box>
            {
                viewTasksRender()
            }  
		</>
	);
}
