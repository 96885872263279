import * as React from 'react';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { HostServiceDetailGeneralInformation } from './HostServiceDetailGeneralInformation'
import { HostServiceDetailPrices } from './HostServiceDetailPrices'
import { HostServiceDetailAvailability } from './HostServiceDetailAvailability';
import type { HostServiceDetailGeneralInformationProps } from './HostServiceDetailGeneralInformation'
import type { HostServiceDetailPricesProps } from './HostServiceDetailPrices'

export interface HostServiceDetailProps {
    hostServiceId: number;
    monitorIdforPrices?: number;
};

export const HostServiceDetail = (props: HostServiceDetailProps): JSX.Element => {
    const {
        hostServiceId, 
        monitorIdforPrices
    } = props;


    const hostServiceDetailGeneralInformationProps: HostServiceDetailGeneralInformationProps = {
        hostServiceId: hostServiceId,
    }

    const hostServiceDetailPricesProps: HostServiceDetailPricesProps = {
        hostServiceId: hostServiceId
    }

    if (monitorIdforPrices !== undefined) {
        hostServiceDetailPricesProps.monitorId = monitorIdforPrices;
        hostServiceDetailGeneralInformationProps.monitorId = monitorIdforPrices;
    }
           
    return (
        <>
            <Card variant="outlined" sx={{ width: 1, maxWidth: '100%' }}>
                <CardContent sx={{ p: 0 }}>
                    <HostServiceDetailGeneralInformation
                        hostServiceId={hostServiceId}
                        monitorId={monitorIdforPrices}
                    />
                    <HostServiceDetailPrices
                        {...hostServiceDetailPricesProps}
                    >
                    </HostServiceDetailPrices>
                    <HostServiceDetailAvailability></HostServiceDetailAvailability>
                </CardContent>
            </Card>
        </>
    )
}