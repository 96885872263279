import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { monitorRunResultService } from 'services/monitorRunResult'
import { monitorRunService } from 'services/monitorRun'
import { monitorService } from 'services/monitor'
import { createBlankMonitorRunResultDetail } from 'domain/monitorRunResultDetail'
import { RequestState } from 'shared/enums'

import type { RootState } from 'store'
import type { MonitorRunResult } from 'shared/types'
import type { ReadMonitorRunResultsResponse } from 'shared/types'
import type { ReadMonitorRunResponse } from 'shared/types'
import type { Monitor } from 'shared/types'
import type { ReadMonitorRunResultHostServicesResponse } from 'shared/types'
import type { ReadMonitorRunResultsByMonitorIdResponse } from 'shared/types'
import type { MonitorRunResultDetail } from 'shared/types'

interface MonitorRunResultDetailState {
    monitor: {
        requestId?: number,
        status: RequestState
    },
    monitorRun: {
        requestId?: number,
        status: RequestState
    },
    hostServices: {
        requestId?: number,
        status: RequestState
    },
    monitorRuns: {
        requestId?: number,
        status: RequestState
    }
}

interface MonitorRunResultState {
    monitorRunResults: MonitorRunResult[];
    monitorRunResultsCount: number;
    monitorRunResultDetail: MonitorRunResultDetail,
    monitorRunResultDetailState: MonitorRunResultDetailState
}

export interface ReadMonitorRunResultsByMonitorIdForMonitorRunResultDetailThunkParams {
    monitorId: number;
}

export const readMonitorRunResultsByMonitorIdForMonitorRunResultDetail = createAsyncThunk(
    'monitorRunResult/readMonitorRunResultsByMonitorIdForMonitorRunResultDetail',
    async function (params: ReadMonitorRunResultsByMonitorIdForMonitorRunResultDetailThunkParams, { rejectWithValue }) {
        try {
            const response: ReadMonitorRunResultsByMonitorIdResponse = await monitorRunResultService.readMonitorRunResultsByMonitorId(params);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export interface ReadMonitorByIdForMonitorRunResultDetailThunkParams {
    id: number;
}

export const readMonitorByIdForMonitorRunResultDetail = createAsyncThunk(
    'monitorRunResult/readMonitorByIdForMonitorRunResultDetail',
    async function (params: ReadMonitorByIdForMonitorRunResultDetailThunkParams, { rejectWithValue }) {
        try {
            const response: Monitor = await monitorService.readMonitorById(params);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export interface ReadMonitorRunByIdForMonitorRunResultDetailThunkParams {
    monitorRunId: number
}

export const readMonitorRunByIdForMonitorRunResultDetail = createAsyncThunk(
    'monitorRunResult/readMonitorRunByIdForMonitorRunResultDetail',
    async function (params: ReadMonitorRunByIdForMonitorRunResultDetailThunkParams, { getState, rejectWithValue }) {
        try {
            const response: ReadMonitorRunResponse = await monitorRunService.readMonitorRunById(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
    {
        condition: (_, { getState, extra }) => {
            const state: RootState = getState() as RootState;
            const status = state.monitorRunResult.monitorRunResultDetailState.monitorRun.status;

            if (status === RequestState.Pending || status === RequestState.Success) {
                return false
            }

        },
    }
);

export interface ReadMonitorRunsResultThunkParams {
    monitorId?: number,
    pagination: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRunsResult = createAsyncThunk(
    'monitorRunResult/readMonitorRunsResult',
    async function (params: ReadMonitorRunsResultThunkParams, { getState, rejectWithValue }) {
        try {
            if (params.monitorId !== undefined) {
                const response: ReadMonitorRunResultsResponse = await monitorRunResultService.readMonitorRunResultsByMonitorId(params);
                return response;                         
            }

            const response: ReadMonitorRunResultsResponse = await monitorRunResultService.readMonitorRunResults(params);
            return response;

        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export interface ReadMonitorRunResultHostServicesByMonitorRunResultIdThunkParams {
    monitorRunId: number,
    pagination?: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRunResultHostServicesByMonitorRunResultId = createAsyncThunk(
    'monitorRunResult/readMonitorRunResultHostServicesByMonitorRunResultId',
    async function (params: ReadMonitorRunResultHostServicesByMonitorRunResultIdThunkParams, { rejectWithValue }) {
        try {
            const response: ReadMonitorRunResultHostServicesResponse = await monitorRunResultService.readMonitorRunResultHostServicesByMonitorRunResultId(params);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState: MonitorRunResultState = {
    monitorRunResults: [],
    monitorRunResultsCount: 0,
    monitorRunResultDetail: createBlankMonitorRunResultDetail(),
    monitorRunResultDetailState: {
        hostServices: {
            requestId: undefined,
            status: RequestState.Idle,
        },
        monitorRuns: {
            requestId: undefined,
            status: RequestState.Idle,
        },
        monitorRun: {
            requestId: undefined,
            status: RequestState.Idle,
        },
        monitor: {
            requestId: undefined,
            status: RequestState.Idle,
        },
    }
}

export const monitorRunResultSlice = createSlice({
    name: 'MonitorRunResult',
    initialState,
    reducers: {
        setMonitorRunResultDetail(state, action: PayloadAction<MonitorRunResultDetail>) {
            state.monitorRunResultDetail = action.payload;
        },
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(readMonitorRunsResult.pending, (state, action) => {
            })
            .addCase(readMonitorRunsResult.fulfilled, (state, action) => {
                state.monitorRunResults = action.payload.list;
                state.monitorRunResultsCount = action.payload.count;
            })
            .addCase(readMonitorRunResultHostServicesByMonitorRunResultId.pending, (state, action) => {
                state.monitorRunResultDetailState.hostServices.status = RequestState.Pending;
            })
            .addCase(readMonitorRunResultHostServicesByMonitorRunResultId.fulfilled, (state, action) => {
                state.monitorRunResultDetailState.hostServices.status = RequestState.Success;

                if (state.monitorRunResultDetail?.hostServices !== undefined) {
                    state.monitorRunResultDetail.hostServices.list = action.payload.list;
                    state.monitorRunResultDetail.hostServices.count = action.payload.count;
                }  
            })
            .addCase(readMonitorRunByIdForMonitorRunResultDetail.pending, (state, action) => {
                if (state.monitorRunResultDetailState.monitorRun.status !== RequestState.Idle) {
                    return;
                }

                state.monitorRunResultDetailState.monitorRun.status = RequestState.Pending;
            }) 
            .addCase(readMonitorRunByIdForMonitorRunResultDetail.fulfilled, (state, action) => {
                state.monitorRunResultDetailState.monitorRun.status = RequestState.Success;

                state.monitorRunResultDetail.monitorRun = action.payload?.monitorRun;           
            })    
            .addCase(readMonitorByIdForMonitorRunResultDetail.pending, (state, action) => {
                state.monitorRunResultDetailState.monitor.status = RequestState.Pending;
            })
            .addCase(readMonitorByIdForMonitorRunResultDetail.fulfilled, (state, action) => {
                state.monitorRunResultDetailState.monitor.status = RequestState.Success;

                state.monitorRunResultDetail.monitor = action.payload;
            })  
            .addCase(readMonitorRunResultsByMonitorIdForMonitorRunResultDetail.pending, (state, action) => {
                state.monitorRunResultDetailState.monitorRuns.status = RequestState.Pending;
            })
            .addCase(readMonitorRunResultsByMonitorIdForMonitorRunResultDetail.fulfilled, (state, action) => {
                state.monitorRunResultDetailState.monitorRuns.status = RequestState.Success;

                if (state.monitorRunResultDetail?.monitorRuns !== undefined) {
                    state.monitorRunResultDetail.monitorRuns.list = action.payload.list;
                    state.monitorRunResultDetail.monitorRuns.count = action.payload.count;
                }
            })   
    }       
})

export const { setMonitorRunResultDetail, reset } = monitorRunResultSlice.actions

export const selectMonitorRunResults = (state: RootState) => state.monitorRunResult.monitorRunResults;
export const selectMonitorRunResultsCount = (state: RootState) => state.monitorRunResult.monitorRunResultsCount;
export const selectMonitorRunResultDetail = (state: RootState) => state.monitorRunResult.monitorRunResultDetail;

export default monitorRunResultSlice.reducer