import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { monitorService } from 'services/monitor';
import { RequestState } from 'shared/enums';
import type { MonitorRun, ReadMonitorsResponse } from 'shared/types';
import type { PropertyStoreMeta } from 'shared/types';
import type { Monitor } from 'shared/types';


interface ApplicationStateData {
    drawerOpen: boolean;
    applicationReady: boolean;
    user?: object;
    monitors?: Monitor[];
    monitorsCount?: number;
    monitorRunForCompetitors?: MonitorRun;
    hostServiceForAvailability?: any;
}

interface ApplicationStateMeta {
    monitors: PropertyStoreMeta,
    monitorsCount: PropertyStoreMeta,
}

interface ApplicationState {
    data: ApplicationStateData
    meta: ApplicationStateMeta
}

const initialState: ApplicationState = {
    data: {
        drawerOpen: false,
        applicationReady: false,
        monitors: [],
        monitorsCount: 0,
        monitorRunForCompetitors: undefined,
        hostServiceForAvailability: undefined,
    },
    meta: {
        monitors: {
            requestId: undefined,
            status: RequestState.Idle,
        },
        monitorsCount: {
            requestId: undefined,
            status: RequestState.Idle,
        }
    }
}

export interface ReadMonitorsThunkParams {
    pagination: {
        page: number,
        pageSize: number
    }
}

export const readMonitors = createAsyncThunk(
    'application/readMonitors',
    async function (params: ReadMonitorsThunkParams, { rejectWithValue }) {
        try {
            const response: ReadMonitorsResponse = await monitorService.readMonitors(params);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const applicationSlice = createSlice({
    name: 'Application',
    initialState,
    reducers: {
        setApplicationReady(state, action: PayloadAction<boolean>) {
            state.data.applicationReady = action.payload;
        },
        setDrawerOpen(state, action: PayloadAction<boolean>) {
            state.data.drawerOpen = action.payload;
        },
        setUser: (state, action: PayloadAction<object>) => {
            state.data.user = action.payload;
        },
        setMonitorRunForCompetitors: (state, action: PayloadAction<MonitorRun | undefined>) => {
            state.data.monitorRunForCompetitors = action.payload;
        },
        setHostServiceForAvailability: (state, action: PayloadAction<MonitorRun | undefined>) => {
            state.data.hostServiceForAvailability = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(readMonitors.pending, (state, action) => {
                state.meta.monitors.status = RequestState.Pending;
                state.meta.monitorsCount.status = RequestState.Pending;
            })
            .addCase(readMonitors.fulfilled, (state, action) => {
                state.meta.monitors.status = RequestState.Success;
                state.meta.monitorsCount.status = RequestState.Success;
                state.data.monitors = action.payload.list;
                state.data.monitorsCount = action.payload.count;
            })
    }
})

export const { setApplicationReady, setDrawerOpen, setUser, setMonitorRunForCompetitors, setHostServiceForAvailability  } = applicationSlice.actions
export const selectDrawerOpen = (state: RootState) => state.application.data.drawerOpen;
export const selectApplicationReady = (state: RootState) => state.application.data.applicationReady;
export const selectUser = (state: RootState) => state.application.data.user;
export const selectMonitorRunForCompetitors = (state: RootState) => state.application.data.monitorRunForCompetitors;
export const selectHostServiceForAvailability = (state: RootState) => state.application.data.hostServiceForAvailability;

export default applicationSlice.reducer