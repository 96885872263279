import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    ScatterController,
    BubbleController,
    TimeScale,
    TimeSeriesScale
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Scatter } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import 'chartjs-adapter-luxon';

type ChartPricesProps = {
    data?: any,
    options?: any,
    loading: boolean,
    monitorId?: number,
}

ChartJS.register(
    annotationPlugin,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    ScatterController,
    BubbleController,
    BarController,
    TimeScale,
    TimeSeriesScale
);

export const ChartSupplyDemand = (props: ChartPricesProps): JSX.Element => {
    const { data, options, monitorId, loading = true } = props;

    const render = () => {
        if (loading) {
            return (<CircularProgress color="success" />)
        }
        return <Chart type='line' data={data} options={options}></Chart>
    }

    return (
        <Box sx={{flexGrow: 1} }>
            {render()}        
        </Box>
    )
}