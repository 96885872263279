import { useEffect } from 'react';
import { MonitorRunResultDetailCard } from 'components/MonitorRunResultDetailCard';
import { useParams } from 'react-router-dom';
import { reset } from 'store/monitorRunResultSlice';
import { useAppDispatch } from 'shared/hooks'
import { useReadMonitorRunsResultCardQuery } from 'services/monitorRunResult';

export const MonitorRunResultDetailPage = () => {
    const { monitorId } = useParams();
    const monitorIdInt = parseInt(monitorId || '-1');

    const {
        data: readMonitorRunsResultCardData,
        isLoading: readMonitorRunsResultCardIsLoading
    } = useReadMonitorRunsResultCardQuery(
        {
            monitorId: monitorIdInt
        },
        {
            skip: monitorIdInt === -1,
        }
    );


    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(reset())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <>
            <MonitorRunResultDetailCard
                monitorId={monitorIdInt}
                monitorRunsResultDetailCard={readMonitorRunsResultCardData}
            ></MonitorRunResultDetailCard>
        </>
    )
}