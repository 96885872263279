import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { monitorRunService } from 'services/monitorRun'

import type { ReadMonitorRunsByMonitorIdParams } from 'services/monitorRun'
import type { MonitorRun } from 'shared/types'
import type { ReadMonitorRunsResponse } from 'shared/types'

interface MonitorRunState {
    monitorRuns: MonitorRun[];
    monitorRunsCount: number;
}

export interface ReadMonitorRunsThunkParams {
    monitorId?: number,
    pagination: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRuns = createAsyncThunk(
    'monitorRun/readMonitorRuns',
    async function (params: ReadMonitorRunsThunkParams | undefined, { rejectWithValue }) {
        try {
            if (params?.monitorId !== undefined) {
                const readMonitorRunsByMonitorIdParams: ReadMonitorRunsByMonitorIdParams = {
                    monitorId: params?.monitorId
                }

                const response: ReadMonitorRunsResponse = await monitorRunService.readMonitorRunsByMonitorId(readMonitorRunsByMonitorIdParams);
                return response;
            }

            const response: ReadMonitorRunsResponse = await monitorRunService.readMonitorRuns(params);
            return response;


        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState: MonitorRunState = {
    monitorRuns: [],
    monitorRunsCount: 0
}

export const monitorRunSlice = createSlice({
    name: 'MonitorRun',
    initialState,
    reducers: {
        setMonitorRun(state, action: PayloadAction<MonitorRun[]>) {
            state.monitorRuns = action.payload;
        },
        setMonitorRunCount(state, action: PayloadAction<number>) {
            state.monitorRunsCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(readMonitorRuns.fulfilled, (state, action) => {
            state.monitorRuns = action.payload.list;
            state.monitorRunsCount = action.payload.count;
        })
    },
})

export const selectMonitorRuns = (state: RootState) => state.monitorRun.monitorRuns;
export const selectMonitorRunsCount = (state: RootState) => state.monitorRun.monitorRunsCount;

export default monitorRunSlice.reducer