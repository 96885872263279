import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup } from '@mui/material';
import { styled } from '@mui/material';
import { MonitorCreateFormView } from 'components/MonitorCreateFormView';
import { createBlankMonitorCreateViewResource } from 'domain/monitor';
import { createMonitorCreateFromMonitorCreateView } from 'domain/monitor';
import { useCreateMonitorMutation } from 'services/monitor';
import type { MonitorCreateResource } from 'shared/types';
import type { MonitorCreateViewResource } from 'shared/types';

const Container = styled(FormGroup)``;

export const MonitorCreatePage = (): JSX.Element => {
    const navigate = useNavigate();
    const [createMonitor] = useCreateMonitorMutation();
    const initialMonitor: MonitorCreateViewResource = createBlankMonitorCreateViewResource();
    const [monitor] = useState<MonitorCreateViewResource>(initialMonitor);

    const createMonitorHandler = async (monitor: MonitorCreateResource) => {
        return await createMonitor({
            monitor: monitor
        })
    }

    const onMonitorCreateViewSubmitHandler = async ({ event, value }: any) => {
        const monitor: MonitorCreateResource = createMonitorCreateFromMonitorCreateView(value);
        const createMonitorResult: any = await createMonitorHandler(monitor);
        navigate(`/spy/${createMonitorResult?.data?.id}/monitorrun`);
    }

	return (
		<>
            <Container sx={
                {
                    width: {
                        md: '50%'
                    },
                    margin: {
                        md: '5% 0 0 25%'
                    },
                }
            }>
                <MonitorCreateFormView
                    onSubmit={onMonitorCreateViewSubmitHandler}
                    value={monitor}
                />
            </Container>
		</>
	);
}
