import * as React from 'react';
import { useState, useEffect } from 'react';
import { HostServiceDetailPhotos } from './components/HostServiceDetailPhotos'
import { useAppDispatch } from 'shared/hooks';
import { useAppSelector } from 'shared/hooks';
import { readHostService } from 'store/hostServiceDetailSlice';
import { selectHostServiceDetail } from 'store/hostServiceDetailSlice';
import { Container } from '@mui/material';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { useReadHostServiceByMonitorIdQuery } from 'services/hostService';
import type { ReadHostServiceThunkParams } from 'store/hostServiceDetailSlice';

export type HostServiceDetailGeneralInformationProps = {
    hostServiceId: number,
    monitorId?: number
}

export const HostServiceDetailGeneralInformation = (props: HostServiceDetailGeneralInformationProps): JSX.Element => {
    const {
        hostServiceId = -1,
        monitorId = -1
    } = props


    const readMonitorRunResultByMonitorIdQueryOptions = {
        refetchOnMountOrArgChange: true,
    };

    const readHostServiceByMonitorIdQueryParams = {
        monitorId: monitorId,
        hostServiceId: hostServiceId
    }

    const {
        data: readHostServiceByMonitorIdQueryData,
        isLoading: readHostServiceByMonitorIdQueryDataIsLoading,
        isFetching: readHostServiceByMonitorIdQueryDataIsFetching,
        error: readHostServiceByMonitorIdQueryError
    } = useReadHostServiceByMonitorIdQuery(readHostServiceByMonitorIdQueryParams, readMonitorRunResultByMonitorIdQueryOptions);

    return (
        <>
            <Container>
                <h2>{readHostServiceByMonitorIdQueryData?.name}</h2>
                <HostServiceDetailPhotos monitorId={monitorId} hostServiceId={hostServiceId}></HostServiceDetailPhotos>
                <Grid container sx={{ p: '16px' }}>
                    <Grid item xs={6}>
                        <div>
                            <Box sx={{ fontWeight: '700' }}>
                                City
                            </Box>
                            <Box>
                                {readHostServiceByMonitorIdQueryData?.city}
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div>
                            <Box sx={{ fontWeight: '700' }}>
                                Bedrooms
                            </Box>
                            <Box>
                                {readHostServiceByMonitorIdQueryData?.bedrooms}
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div>
                            <Box sx={{ fontWeight: '700' }}>
                                Beds
                            </Box>
                            <Box>
                                {readHostServiceByMonitorIdQueryData?.beds}
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div>
                            <Box sx={{ fontWeight: '700' }}>
                                Pets
                            </Box>
                            <Box>
                                {readHostServiceByMonitorIdQueryData?.pets}
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div>
                            <Box sx={{ fontWeight: '700' }}>
                                Rating
                            </Box>
                            <Box>
                                {readHostServiceByMonitorIdQueryData?.rating}
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div>
                            <Box sx={{ fontWeight: '700' }}>
                                Reviews
                            </Box>
                            <Box>
                                {readHostServiceByMonitorIdQueryData?.ratingsCount}
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
