import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';
import authService from 'components/api-authorization/AuthorizeService';

import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { FetchArgs } from '@reduxjs/toolkit/query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const baseQuery = fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: async (headers, { getState }) => {
        const token = await authService.getAccessToken();

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        return headers
    },
    credentials: 'include'
},
)

export const baseQueryWithAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401) {
        window.location.replace(`${ApplicationPaths.Login}/?returnUrl=${encodeURI(window.location.href)}`);
    }

    return result
}