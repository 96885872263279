import { DateTime } from 'luxon';
import { DATE_PERIODS } from 'shared/constants';
import { BEDROOM_COUNTS } from 'shared/constants';
import { platformApi } from 'services/platform';
import type { Monitor, MonitorCreateResource, MonitorCreateViewResource, MonitorUpdateResource, MonitorUpdateViewResource, MonitorViewModel } from 'shared/types'
import type { MonitorFromResponse } from 'shared/types'
import type { MonitorFilterItem } from 'shared/types'

type UpdateMonitorFilterProp = {
    monitor: Monitor,
    monitorFilterItem: MonitorFilterItem
}

type UpdateMonitorCreateFilterProp = {
    monitor: MonitorCreateViewResource,
    monitorFilterItem: MonitorFilterItem
}

export const updateMonitorFilter = ({ monitor, monitorFilterItem }: UpdateMonitorFilterProp): Monitor => {
    let newMonitor = { ...monitor }

    let newValue = monitorFilterItem.value 
    const typeProperty = typeof (newMonitor[monitorFilterItem.name])

    switch (typeProperty) {
        case 'number':
            newValue = parseInt(newValue)
            break;
    }

    newMonitor[monitorFilterItem.name] = newValue;

    if (newMonitor.bedrooms > newMonitor.beds) {
        newMonitor.beds = newMonitor.bedrooms;
    }

    return newMonitor;
}

export const updateMonitorCreateFilter = ({ monitor, monitorFilterItem }: UpdateMonitorCreateFilterProp): MonitorCreateViewResource => {
    let newMonitor = { ...monitor }

    let newValue = monitorFilterItem.value
    const typeProperty = typeof (newMonitor[monitorFilterItem.name])

    switch (typeProperty) {
        case 'number':
            newValue = parseInt(newValue)
            break;
    }

    newMonitor[monitorFilterItem.name] = newValue;

    if (newMonitor.bedrooms > newMonitor.beds) {
        newMonitor.beds = newMonitor.bedrooms;
    }

    if (newMonitor.slidingPeriods !== undefined) {
        newMonitor.slidingPeriods = [];
        let daysAhead = newMonitor?.daysAhead || 0;

        for (let i = 0; i < daysAhead ; i++) {
            newMonitor?.slidingPeriods.push({
                offset: i,
                period: 1
            })
        }
    }

    return newMonitor;
}

const objectWithoutProperties = (obj: any, keys: any) => {
    let target: any = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

export const createMonitorCreateFromMonitorCreateView = (monitorCreateViewResource: MonitorCreateViewResource): MonitorCreateResource => {
    const monitor: MonitorCreateResource = objectWithoutProperties(monitorCreateViewResource, ["daysAhead", "bedrooms", "beds"]);
    monitor.bedrooms = [monitorCreateViewResource.bedrooms];
    monitor.beds = [monitorCreateViewResource.beds];

    return monitor;
}

export const createMonitorUpdateFromMonitorUpdateView = (monitorUpdateViewResource: MonitorUpdateViewResource): MonitorUpdateResource => {
    const monitor: MonitorUpdateResource = objectWithoutProperties(monitorUpdateViewResource, ["daysAhead", "bedrooms", "beds"]);
    monitor.bedrooms = [monitorUpdateViewResource.bedrooms];
    monitor.beds = [monitorUpdateViewResource.beds];

    return monitor;
}

export const createMonitorUpdateViewFromMonitorUpdate = (monitorUpdateViewResource: MonitorUpdateResource): MonitorUpdateViewResource => {
    const monitor: MonitorUpdateViewResource = objectWithoutProperties(monitorUpdateViewResource, ["bedrooms", "beds"]);
    monitor.bedrooms = monitorUpdateViewResource.bedrooms.length > 0 ? monitorUpdateViewResource.bedrooms[0] : 0;
    monitor.beds = monitorUpdateViewResource.beds.length > 0 ? monitorUpdateViewResource.beds[0] : 0;
    monitor.daysAhead = monitorUpdateViewResource.slidingPeriods?.length;

    return monitor;
}


export const createMonitorFromMonitorResponse = (monitorResponse: MonitorFromResponse): Monitor => {
    const monitor: Monitor = {
        id: monitorResponse.id,
        title: monitorResponse.title,
        description: monitorResponse.description,
        locations: monitorResponse.locations,
        platformId: monitorResponse.platformId,
        bedrooms: monitorResponse.bedrooms[0],
        beds: monitorResponse.beds[0],
        pets: monitorResponse.pets,
        scanInterval: monitorResponse.scanInterval,
        enabled: monitorResponse.enabled,
        customPeriods: [],
        slidingPeriods: monitorResponse.slidingPeriods,
        type: monitorResponse.type,
        priceForComparison: monitorResponse.priceForComparison,
        collectNearByLocations: monitorResponse.collectNearByLocations
    }

    if (monitorResponse && monitorResponse.customPeriods) {
        for (let i = 0; i < monitorResponse.customPeriods?.length; i++) {

            monitor?.customPeriods?.push({
                startDateTimeUtc: DateTime.fromISO(monitorResponse.customPeriods[i].startDateTimeUtc).toString(),
                endDateTimeUtc: DateTime.fromISO(monitorResponse.customPeriods[i].endDateTimeUtc).toString(),
            })
        }
    }

    return monitor;
}


export const periodLabel = (monitor: Monitor) => {
    if (Array.isArray(monitor?.customPeriods) && monitor.customPeriods.length > 0) {
        return DATE_PERIODS[3].name;
    }

    if (Array.isArray(monitor?.slidingPeriods) && monitor.slidingPeriods.length > 0) {
        if (monitor.slidingPeriods.length > 1) {
            return DATE_PERIODS[0].name;
        }

        if (monitor.slidingPeriods[0].offset === 1 && monitor.slidingPeriods[0].period === 7) {
            return DATE_PERIODS[2].name;
        }

        if (monitor.slidingPeriods[0].offset === 0) {
            return DATE_PERIODS[0].name;
        }

        if (monitor.slidingPeriods[0].offset === 1) {
            return DATE_PERIODS[1].name;
        }

        return DATE_PERIODS[1].name;
    }

    return DATE_PERIODS[1].name;
}

export const createBlankMonitorCreateViewResource = (): MonitorCreateViewResource => {
    const monitor: MonitorCreateViewResource = {
        title: '',
        description: '',
        locations: [],
        platformId: 1,
        bedrooms: 0,
        beds: 0,
        pets: 0,
        scanInterval: 1,
        enabled: true,
        slidingPeriods: [
            {
                offset: 0,
                period: 1
            }
        ],
        customPeriods: [],
        type: 0,
        priceForComparison: 0,
        daysAhead: 3,
        collectNearByLocations: true
    }

    return monitor;
}

export const createBlankMonitor = (): Monitor => {
    const monitor: Monitor = {
        title: '',
        description: '',
        locations: [],
        platformId: 1,
        bedrooms: 0,
        beds: 0,
        pets: 0,
        scanInterval: 1,
        enabled: true, 
        slidingPeriods: [
            {
                offset: 0,
                period: 1
            }
        ],
        customPeriods: [],
        type: 0,
        priceForComparison: 0,
        collectNearByLocations: true
    }

    return monitor;
}

export const createMonitorCalendarSimple = (): Monitor => {
    const monitor: Monitor = {
        title: 'My spy',
        description: '',
        locations: [],
        platformId: 1,
        bedrooms: 0,
        beds: 1,
        pets: 0,
        scanInterval: 1,
        enabled: true,
        customPeriods: [],
        type: 1,
        priceForComparison: 0,
        collectNearByLocations: false
    }

    monitor.slidingPeriods = [];

    for (let i = 0; i < 3; i++) {
        monitor.slidingPeriods.push({
            offset: i,
            period: 1
        })
    }

    return monitor;
}