import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useEffect } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


type LoadingDataDialogProps = {
    open?: boolean,
    value?: number,
    maxValue?: number,
    status?: number,
    onOpenChange?: Function,
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#648AAF' : '#648AAF',
    },
}));

export const LoadingDataDialog = (props: LoadingDataDialogProps): JSX.Element => {
    const [valueInPercent, setValueInPercent] = useState<number>(0);

    const {
        open = false,
        onOpenChange,
        value = 0,
        maxValue,
        status
    } = props;

    const handleClose = () => {
        if (typeof onOpenChange === "function") {
            onOpenChange(false);
        }
    };

    useEffect(() => {
        if (maxValue !== undefined) {
            setValueInPercent(Math.round((value / maxValue) * 100));
        }
       
    }, [value])


    const renderBody = () => {
        if (status === 0 || value === undefined) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <BorderLinearProgress variant="determinate" value={0} />
                        </Box>
                    </Box>
                    <Typography variant="h4" sx={{ my: 2, color: '#648AAF' }}>
                        Pending
                    </Typography>
                </Box>
            )
        }
       
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <BorderLinearProgress variant="determinate" value={valueInPercent} />
                        </Box>
                        {/*<Box sx={{ minWidth: 35 }}>*/}
                        {/*    <Typography variant="body2" color="text.secondary">{maxValue || 0}</Typography>*/}
                        {/*</Box>*/}
                    </Box>
                    <Typography variant="h4" sx={{ my: 2, color: '#648AAF' }}>
                        Day {`${Math.round(
                            value || 0,
                        )}`} of {maxValue}
                    </Typography>
                </Box>
            )       
    }


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Please wait
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        We are collecting competitive info on the Internet in real time
                    </Typography>
                    {  renderBody() } 
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}
