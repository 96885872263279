import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { CardActions } from '@mui/material'; 
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/material';
import { Skeleton } from '@mui/material';
import { List } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import { ListItemText } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { ClickAwayListener } from '@mui/material';
import { IconButton } from '@mui/material';
import { Fade } from '@mui/material';
import { Paper } from '@mui/material';
import { Popper } from '@mui/material';
import { Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import authService from 'components/api-authorization/AuthorizeService';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';
import { useLocation } from 'react-router-dom';
import GradingIcon from '@mui/icons-material/Grading';
import InfoIcon from '@mui/icons-material/Info';
import type { User } from 'shared/types';

export const ApplicationMainBarProfile = (): JSX.Element => {
    const location = useLocation();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [user, setUser] = useState<User | undefined>(undefined)
    const [subscription, setSubscription] = useState<number | undefined>(undefined)

    const fetchData = async () => {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        setIsAuthenticated(isAuthenticated);
        setUser(user);
    }

    useEffect(() => {
        const subscriptionId = authService.subscribe(() => fetchData())
        setSubscription(subscriptionId)
        fetchData()
    }, []);

    useEffect(() => {
        handleCloseUserMenu();
    }, [location]);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const renderMenu = () => {
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;

            return anonymousView(registerPath, loginPath);
        }

        if (user != undefined) {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };

            return authenticatedView(user.name, profilePath, logoutPath, logoutState);
        }
    }

    const authenticatedView = (userName: any, profilePath: any, logoutPath: any, logoutState: any) => {
        return (
            <>
                <Tooltip title="Open settings">
                    <IconButton
                        size="large"
                        onClick={handleOpenUserMenu}
                        sx={{ p: 0 }}
                    >
                        <AccountCircle fontSize="large" />
                    </IconButton>
                </Tooltip>
                <Popper
                    role="presentation"
                    open={Boolean(anchorElUser)}
                    anchorEl={anchorElUser}
                    placement="bottom-end"
                    transition
                    disablePortal
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>                     
                                <ClickAwayListener onClickAway={handleCloseUserMenu}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Skeleton variant="circular" width={32} height={32} />
                                                        <Stack>
                                                            <Typography variant="h6">{userName}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                            
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid> 
                                        </CardContent>             
                                        <CardActions sx={{ p: 0 }}>
                                            <Box sx={{ width: '100%' }}>
                                                <Divider variant="middle" sx={{ m: 0, borderBottomWidth: 1, bgcolor: (theme) => theme.palette.divider }} />
                                                <List component="nav">
                                                    <ListItemButton component={Link} to={`${profilePath}`} key="profile">
                                                        <ListItemIcon>
                                                            <ManageAccountsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Profile" />
                                                    </ListItemButton>
                                                    <ListItemButton component={Link} to={`/recommendations`} key="recommendations">
                                                        <ListItemIcon>
                                                            <SettingsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Recommendations" />
                                                    </ListItemButton>
                                                    <ListItemButton component={Link} to={`/notifications/settings`} key="notifications">
                                                        <ListItemIcon>
                                                            <SettingsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Notifications" />
                                                    </ListItemButton>
                                                    <ListItemButton component={Link} to={`/install/instruction`} key="installInstruction">
                                                        <ListItemIcon>
                                                            <GradingIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="How To Install" />
                                                    </ListItemButton>
                                                    <ListItemButton component={Link} to={`/about`} key="about">
                                                        <ListItemIcon>
                                                            <InfoIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="About" />
                                                    </ListItemButton>

                                                    <ListItemButton component={Link} to={`${logoutPath}`} key="logout">
                                                        <ListItemIcon>
                                                            <LogoutIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Logout" />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </ClickAwayListener>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </>
        );
    }

    const anonymousView = (registerPath: any, loginPath: any) => {
        return (
            <>
                <ul style={{
                    listStyle: 'none', display: 'flex', margin: '0', columnGap: '20px'
                }}>
                    <li>
                        <Link to={registerPath}>
                            Register
                        </Link>
                    </li>
                    <li>
                        <Link to={loginPath}>
                            Login
                        </Link>
                    </li>
                </ul>
            </>
        );
    }

    return (
        <Box sx={{ flexGrow: 0 }}>
            {
                renderMenu()
            }
        </Box>
    )
}
