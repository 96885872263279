import React from 'react';
import { useState } from 'react';
import { MonitorRunResultDetailCard } from 'components/MonitorRunResultDetailCard';
import { useReadMonitorsRunsResultQuery } from 'services/monitorRunResult';

import type { MonitorRunsResultCard } from 'shared/types';

export const MonitorsRunResultListCard = (): JSX.Element => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const {
        data: readMonitorsRunsResultData,
        isLoading
    } = useReadMonitorsRunsResultQuery({
        pagination: paginationModel
    });

    const monitorsRunsResult: MonitorRunsResultCard[] = readMonitorsRunsResultData?.list || [];
    const monitorsRunsResultCount = readMonitorsRunsResultData?.count || 0;

    const cardsRender = () => {
        return (
            <>        
                {
                    monitorsRunsResult
                        .map((monitorRunResult, index) => {
                            return (
                                <MonitorRunResultDetailCard key={monitorRunResult.id} monitorRunsResultDetailCard={monitorRunResult}></MonitorRunResultDetailCard>
                            )
                    })
                }
            </>
        )
    }

    return (
        <>
            {cardsRender()}
        </>
    )
}