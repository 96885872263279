import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { DateTime } from 'luxon';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ParametersChart } from './ParametersChart'
import Typography from '@mui/material/Typography';
import { ScrollableHorizontallyIcon } from 'shared/icons/ScrollableHorizontallyIcon';
import { useState } from 'react';
import { useReadMonitorRunResultGroupedByMonitorIdQuery } from 'services/monitorRunResult';
import type { ReadMonitorRunResultParams } from 'services/monitorRunResult';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


type ParametersListChartsProps = {
    monitorId: number,
}

export const ParametersListCharts = (
    {
        monitorId
    }: ParametersListChartsProps
): JSX.Element => {
    const theme = useTheme();
    const smOrMore = useMediaQuery(theme.breakpoints.up('sm'));
    const lgOrMore = useMediaQuery(theme.breakpoints.up('lg'));
    const [skip, setSkip] = useState<boolean>(true);
    const [parameters, setParameters] = useState<[]>([]);

    const readMonitorRunResultByMonitorIdQueryParams: ReadMonitorRunResultParams = {
        monitorId: monitorId,
    };

    const readMonitorRunResultByMonitorIdQueryOptions = {
        skip: skip,
        refetchOnMountOrArgChange: true
    };

    const {
        data: readMonitorRunsResultData,
        isLoading: readMonitorRunsResultDataIsLoading,
        isFetching: readMonitorRunsResultDataIsFetching,
        refetch
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        readMonitorRunResultByMonitorIdQueryParams,
        readMonitorRunResultByMonitorIdQueryOptions
    );


    useEffect(() => {
        if (readMonitorRunsResultDataIsFetching === true) {
            setParameters([]);
            return;
        }

        setParameters(readMonitorRunsResultData?.list || []);
        //setRowCount(readMonitorRunsResultData?.count || 0);
    }, [readMonitorRunsResultDataIsFetching]);


    useEffect(() => {
        setSkip(monitorId === undefined || monitorId === -1);
    }, [monitorId]);

    const pricesChartOptions = {
        animation: {

            duration: 0
        },
        hover: {
            animationDuration: 0 
        },
        responsiveAnimationDuration: 0,
        layout: {
            padding: 10
        },
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'Prices',
                font: () => {
                    if (lgOrMore) {
                        return {
                            size: 20
                        }
                    }

                    if (smOrMore) {

                        return {
                            size: 18
                        }
                    }


                    return {
                        size: 16
                    }
                }
            },
            legend: {
                labels: {
                    font: () => {
                        if (lgOrMore) {
                            return {
                                size: 18
                            }
                        }

                        if (smOrMore) {

                            return {
                                size: 16
                            }
                        }


                        return {
                            size: 14
                        }
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (context.dataset.parsing.yAxisKey === 'resultsCount') {
                            label += `: ${context.parsed.y}`;
                            return label;
                        }

                        if (context.parsed.y !== null) {
                            label += `: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)}`;
                        }

                        return label;
                    }
                }
            }
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    callback: (value: any, index: any, ticks: any) => {
                        return '$' + value;
                    }
                }
            },
            x: {
                reverse: true
            }
            //x: {
            //    ticks: {
            //        maxRotation: 90,
            //        minRotation: 90
            //    } 
            //}
        },
    };

    const optionsNumberOfCompetitors = {
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        layout: {
            padding: 10
        },
        maintainAspectRatio: false,
        responsive: true,
        stacked: false,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
                text: 'Properties',
                font: () => {
                    if (lgOrMore) {
                        return {
                            size: 20
                        }
                    }

                    if (smOrMore) {

                        return {
                            size: 18
                        }
                    }


                    return {
                        size: 16
                    }
                }
            },
            legend: {
                labels: {
                    font: () => {
                        if (lgOrMore) {
                            return {
                                size: 18
                            }
                        }

                        if (smOrMore) {

                            return {
                                size: 16
                            }
                        }


                        return {
                            size: 14
                        }
                    }
                }
            },
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            x: {
                reverse: true
            }
        }
    }

    const transormedData = parameters
        .map((resultItem: any) => {
        const dateTimeCheckIn = DateTime.fromISO(resultItem.checkinUtc).toFormat('M/dd/yyyy');
        const dateTimeCheckOut = DateTime.fromISO(resultItem.checkOutUtc).toFormat('M/dd/yyyy');
        const date = `${dateTimeCheckIn} - ${dateTimeCheckOut}`;

        return {
            date: date,
            maxPrice: `${resultItem.maxPrice}`,
            minPrice: resultItem.minPrice,
            averagePrice: resultItem.averagePrice,
            resultsCount: resultItem.resultsCount
        }
        }); 

    const chartData = {
        datasets: [
            {
                label: 'Min price',
                data: transormedData,
                parsing: {
                    yAxisKey: 'minPrice',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(100, 221, 23)',
                backgroundColor: 'rgba(100, 221, 23, 0.5)',
            },
            {
                label: 'Max price',
                data: transormedData,
                parsing: {
                    yAxisKey: 'maxPrice',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(255, 61, 1)',
                backgroundColor: 'rgba(255, 61, 1, 0.5)',
                yAxisID: 'y',
            },
            {
                label: 'Average price',
                data: transormedData,
                parsing: {
                    yAxisKey: 'averagePrice',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ],
    };

    const optionsNumberOfCompetitorsData = {
        datasets: [
            {
                label: 'Number of competitive properties',
                data: transormedData,
                parsing: {
                    yAxisKey: 'resultsCount',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(100, 221, 23)',
                backgroundColor: 'rgba(100, 221, 23, 0.5)',
                yAxisID: 'y',
            }
        ],
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'end',
                paddingBottom: '8px',
                paddingRight: '16px'
            }}>
                <Box sx={{
                    display: 'inline-flex',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'

                    }}>
                        <ScrollableHorizontallyIcon sx={{
                            fontSize: '32px'
                        }}></ScrollableHorizontallyIcon>
                        <Typography sx={{
                            fontSize: '10px',

                        }}
                        >Scroll for more
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                p: 1,
              
            }}>
                <ParametersChart options={pricesChartOptions} data={chartData}></ParametersChart>
            </Box>
            <Box sx={{
                p: 1,
              
            }}>
                <ParametersChart options={optionsNumberOfCompetitors} data={optionsNumberOfCompetitorsData}></ParametersChart>
            </Box>
            </>
    )
}
