import React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { MonitorCard } from '../MonitorCard';
import { useReadMonitorsQuery } from 'services/monitor';
import { useDeleteMonitorMutation } from 'services/monitor';
import { ConfirmationDialogForRemovingMonitor } from 'components/ConfirmationDialogForRemovingMonitor';
import { MonitorListToMonitorViewModelList } from 'components/Adapters/Monitor';
import type { Monitor, MonitorViewModel } from 'shared/types';

type MonitorListCardProps = {
};

export const MonitorListCard = (props: MonitorListCardProps): JSX.Element => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 40,
    });

    const [deleteMonitor] = useDeleteMonitorMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletedMonitor, setDeletedMonitor] = useState<Monitor | null>(null);
    const [monitors, setMonitors] = useState<MonitorViewModel[]>([]);

    const { data: readMonitorsQueryData, isLoading: readMonitorsQueryIsLoading } = useReadMonitorsQuery({
        pagination: paginationModel
    });

    const onDeleteCardHandler = (monitor: Monitor) => {
        setDeletedMonitor(monitor);
        setOpenDeleteDialog(true);
    }

    const confirmDeleteMonitorHandler = async () => {
        setOpenDeleteDialog(false);
        if (deletedMonitor && deletedMonitor.id !== undefined) {
            await deleteMonitor({ monitorId: deletedMonitor.id });
        }
    }

    const cancelDeleteMonitorHandler = () => {
        setOpenDeleteDialog(false);
    }

    const onMonitorViewModelListChangedHandler = (event: any) => {
        setMonitors(event.value);
    }

    const cardsRender = () => {
        return (
            <>
                <MonitorListToMonitorViewModelList
                    monitorList={readMonitorsQueryData?.list}
                    onMonitorViewModelListChanged={onMonitorViewModelListChangedHandler} 
                />
                <Grid container spacing={3}>
                    {
                        monitors.map((monitor: MonitorViewModel) => {
                            return (
                                    <Grid item key={monitor.id}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                md: 'initial'
                                            }
                                        }}
                                    >
                                        <MonitorCard
                                            key={monitor.id}
                                            monitor={monitor}
                                            onDelete={onDeleteCardHandler}
                                        />
                                    </Grid>
                            )
                        })
                    }                          
                </Grid>

                <ConfirmationDialogForRemovingMonitor
                    keepMounted
                    open={openDeleteDialog}
                    onConfirm={confirmDeleteMonitorHandler}
                    onCancel={cancelDeleteMonitorHandler}
                    monitor={deletedMonitor}
                />
            </>
        )
    }

    return (
        <>
            {cardsRender()}
        </>
    )
}