import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { ToggleButtonGroup } from '@mui/material';
import { ParametersListCharts } from './components/ParametersListCharts';
import { ParametersListCalendar } from './components/ParametersListCalendar';
import { ParametersListTable } from './components/ParametersListTable';
import { MonitorRunsResultDetailCard } from 'shared/types';

type MonitorRunResultDetailCardParametersProps = {
    loading?: boolean;
    monitorId?: number;
    selectedMonitorLaunchTask?: any, 
    monitorRunsResultDetailCard?: MonitorRunsResultDetailCard,
    onCompetitorsVisibileChanged?: Function,
    onSelectedMonitorLaunchTaskChange?: Function,
}

export const MonitorRunResultDetailCardParameters = (
    {
        monitorId,
        loading,
        onCompetitorsVisibileChanged,
        monitorRunsResultDetailCard,
        selectedMonitorLaunchTask,
        onSelectedMonitorLaunchTaskChange,
    }: MonitorRunResultDetailCardParametersProps
): JSX.Element => {
    const [viewMode, setViewMode] = useState('calendar');

    const handleSelectedMonitorLaunchTaskChange = (params: any) => {
        if (typeof onSelectedMonitorLaunchTaskChange === "function") {
            onSelectedMonitorLaunchTaskChange(params);
        }
    }

    const parametersRender = () => {
        if (viewMode === 'table') {
            return (
                <ParametersListTable
                    monitorId={monitorId || -1}

                />     
            )
        }

        if (viewMode === 'chart') {
            return (
                <ParametersListCharts
                    monitorId={monitorId || -1}
                 />
            )
        }

        if (viewMode === 'calendar') {
            return (
                <ParametersListCalendar
                    monitorId={monitorId || -1}
                    monitorRunsResultDetailCard={monitorRunsResultDetailCard}
                    selectedMonitorLaunchTask={selectedMonitorLaunchTask}
                    onSelectedMonitorLaunchTaskChange={handleSelectedMonitorLaunchTaskChange}
                />
            )
        }

        return (
            <>
                
            </>
        )
    }

    const viewModeChanged = (
        event: React.MouseEvent<HTMLElement>,
        newViewMode: string,
    ) => {
        setViewMode(newViewMode);
    };

    const toggleSelectorRender = () => {
        return (
            <>
                <ToggleButtonGroup
                    color="primary"
                    value={viewMode}
                    exclusive
                    onChange={viewModeChanged}
                    aria-label="view"
                >
                    <ToggleButton value="calendar">Calendar</ToggleButton>
                    <ToggleButton value="table">Table</ToggleButton>
                    <ToggleButton value="chart">Chart</ToggleButton>
                </ToggleButtonGroup>
            </>
        )
    }
    return (
        <Accordion disableGutters defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#FFF' }} />}
                aria-controls="panel1a-content"
                sx={{
                    backgroundColor: '#B0B0B0',
                    paddingRight: { xs: '12px', md: '24px' },
                    paddingLeft: { xs: '12px', md: '24px' },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        padding: '12px 12px 12px 12px',
                    },
                }}
            >
                <Typography sx={
                    {
                        fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '24px',
                        }, 
                        lineHeight: '28px',
                        color: '#FFF',
                        marginRight: 2
                    }
                }
                >Results
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>   
                <Box p={2} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>    
                    {
                        toggleSelectorRender()
                    }
                </Box>
                <div style={{
                    width: "100%" 
                 }}>
                    {
                        parametersRender()
                    }               
                </div>
            </AccordionDetails>
        </Accordion>
    )
}