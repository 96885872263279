import { useState } from 'react';
import { useEffect } from 'react';
import { DesktopCalendar } from './components/DesktopCalendar';
import { MobileCalendar } from './components/MobileCalendar';
import { DateTime } from 'luxon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { DateCalendarSlotsComponent, DateCalendarSlotsComponentsProps, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { SlotsAndSlotProps } from '@mui/x-date-pickers/internals';

export interface CalendarProps extends SlotsAndSlotProps<DateCalendarSlotsComponent<any>, DateCalendarSlotsComponentsProps<any>>  {
    onMonthDecrease?: (date: any) => void,
    onMonthIncrease?: (date: any) => void,
    value?: DateTime,
    period?: DateTime,
    loading?: boolean,
};


export interface SubCalendarProps extends CalendarProps, SlotsAndSlotProps<DateCalendarSlotsComponent<any>, DateCalendarSlotsComponentsProps<any>> {
    startDate: DateTime,
    endDate: DateTime,
    sxCalendar?: SxProps<Theme>
};

export const Calendar = (props: CalendarProps): JSX.Element => {
    const {
        value = DateTime.fromJSDate(new Date()),
        loading = false,
        slots
    } = props;


    const [sxCalendar, setSxCalendar] = useState<SxProps<Theme>>({
        width: "100%",
        "& .MuiDayCalendar-monthContainer": {
            position: 'static'
        },
        "& .MuiDayCalendar-weekDayLabel": {
            width: {
                xs: '40px',
                md: '46px',
            },
            fontSize: '0.8rem',
            color: '#000000de'
        },
        "& .MuiPickersDay-root": {
            width: {
                xs: '40px',
                md: '46px',
            },
            height: {
                xs: '40px',
                md: '46px',
            },
            fontSize: '0.8rem'
        },
        "& .MuiPickersDay-today": {
            border: '4px solid #42a5f5'
        },
        "& .MuiPickersDay-today:not(.Mui-selected)": {
            border: '4px solid #42a5f5'
        },
        "& .MuiPickersDay--ok": {
            backgroundColor: '#4caf50',
            color:'#ffffff',
        }, 
        "& .MuiPickersDay--ok:hover": {
            backgroundColor: '#648AAF',
            color: '#FFF'
        },
        "& .MuiPickersDay--error": {
            backgroundColor: '#ef5350',
            color: '#ffffff',
        },
        "& .MuiPickersDay--error:hover": {
            backgroundColor: '#648AAF',
            color: '#FFF'
        },
        "& .MuiPickersDay--selected": {
            backgroundColor: '#648aaf'
        },
    });

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [period, setPeriod] = useState<DateTime>(props.period || DateTime.fromJSDate(new Date()));
    const [startDate, setStartDate] = useState<DateTime>(period);
    const [endDate, setEndDate] = useState<DateTime>(period.plus({ months: 1 }));

    useEffect(() => {
        setStartDate(period);
        setEndDate(period.plus({ months: 1 }));
    }, [period])

    const onMonthDecreaseHandler = (date: any) => {
        setPeriod(period.minus({ months: 1 }));
    }

    const onMonthIncreaseHandler = (date: any) => {
        setPeriod(period.plus({ months: 1 }));
    }

    const additionalProps = {
        onMonthIncrease: onMonthIncreaseHandler,
        onMonthDecrease: onMonthDecreaseHandler,
        startDate: startDate,
        endDate: endDate,
        loading: loading,
        sxCalendar: sxCalendar
    }

    const render = () => {
        if (isSmallScreen) {
            return (
                <MobileCalendar {...props} {...additionalProps}></MobileCalendar>
            )
        }
        return (
            <DesktopCalendar {...props}  {...additionalProps}></DesktopCalendar>
        )
    }

    return (
        <>
            {
                render()
            }
        </>
    )
}