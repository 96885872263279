import { SortOrder } from '../../shared/enums';
import { mainApi } from 'services/mainApi';
export interface ReadMonitorRunResultParams {
    monitorId?: number,
    pagination?: {
        page: number,
        pageSize: number
    }
}

export interface UpdateMonitorRunResultByIdParams {
    monitorRunId: number,
    monitorRun: any
}

export interface ReadMonitorRunsResultCardParams {
    monitorId: number
}

export interface ReadMonitorsRunsResultParams {
    pagination?: {
        page: number,
        pageSize: number
    }
}

export interface ReadMonitorRunResultHostServicesByMonitorRunResultIdParams {
    monitorRunId: number,
    sorting?: {
        orderField: string,
        sortOrder: SortOrder
    },
    pagination?: {
        page: number,
        pageSize: number
    }
}

export const monitorRunResultApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        readMonitorsRunsResult: builder.query({
                query: ({ pagination }: ReadMonitorsRunsResultParams) => {
                    let url = `/monitor/viewcards`;

                    if (pagination) {
                        const skip: number = pagination.page * pagination.pageSize;
                        const take: number = pagination.pageSize;
                        url = `/monitor/viewcards?skip=${skip}&take=${take}`;
                    }

                    return url;
                }
            }),
        readMonitorRunResultByMonitorId: builder.query({
            query: ({ monitorId, pagination }: ReadMonitorRunResultParams) => {
                let url = `/monitor/${monitorId}/monitorruns`;

                if (pagination) {
                    const skip: number = pagination.page * pagination.pageSize;
                    const take: number = pagination.pageSize;
                    url += `?skip=${skip}&take=${take}`;
                }

                return url;                  
            }
        }),
        readMonitorRunResultGroupedByMonitorId: builder.query({
            query: ({ monitorId, pagination }: ReadMonitorRunResultParams) => {
                let url = `/monitor/${monitorId}/monitorrunsgrouped`;

                if (pagination) {
                    const skip: number = pagination.page * pagination.pageSize;
                    const take: number = pagination.pageSize;
                    url += `?skip=${skip}&take=${take}`;
                }

                return url;
            },
            providesTags: ['MonitorRunsGrouped'],
        }),
        readMonitorRunsResultCard: builder.query({
                query: (params: ReadMonitorRunsResultCardParams) => {
                    const url = `/monitor/${params.monitorId}/viewcard`;

                    return url;
            },
                providesTags: ['MonitorRunsResultCard'],
            }),
        readMonitorRunResultHostServicesByMonitorRunResultId: builder.query({
            query: (params: ReadMonitorRunResultHostServicesByMonitorRunResultIdParams) => {
                let url = `/monitorruns/${params.monitorRunId}/results`;
                const additionalParams: any[] = []; 

                if (params?.pagination) {
                    const skip: number = params.pagination.page * params.pagination.pageSize;
                    const take: number = params.pagination.pageSize;

                    additionalParams.push({
                            name: 'skip',
                            value: skip
                    });

                    additionalParams.push({
                        name: 'take',
                        value: take
                    });
                }

                if (params.sorting !== undefined
                    && params.sorting.orderField !== undefined
                    && params.sorting.sortOrder !== undefined) {
                    additionalParams.push({
                        name: 'orderField',
                        value: params?.sorting.orderField
                    });

                    additionalParams.push({
                        name: 'sortOrder',
                        value: params?.sorting.sortOrder
                    });
                }

                if (additionalParams.length > 0) {
                    const paramsString: string = additionalParams
                        .map((additionalParam) => {
                            return `${additionalParam.name}=${additionalParam.value}`;
                        })
                        .join('&');

                    url = `${url}?${paramsString}`
                }

                return url;
            }
        }),
        updateMonitorRunResultById: builder.mutation({
                query: ({ monitorRunId, monitorRun }: UpdateMonitorRunResultByIdParams) => ({
                        url: `monitorruns/${monitorRunId}`,
                        method: 'PUT',
                        body: monitorRun
                    }
                )
            })
    }),
});

export const {
    useReadMonitorsRunsResultQuery,
    useReadMonitorRunResultByMonitorIdQuery,
    useReadMonitorRunResultGroupedByMonitorIdQuery,
    useReadMonitorRunsResultCardQuery,
    useUpdateMonitorRunResultByIdMutation,
} = monitorRunResultApi;
