import { useState } from 'react';
import { useEffect } from 'react';
import { MonitorCard } from './../../components/MonitorCard';
import { useReadMonitorByIdQuery } from 'services/monitor';
import { createBlankMonitorCreateViewResource, createMonitorUpdateViewFromMonitorUpdate } from '../../../../domain/monitor';
import { MonitorUpdateViewResource } from '../../../../shared/types';

type MonitorWidgetProps = {
    monitorId?: number
}

export const MonitorWidget = (props: MonitorWidgetProps): JSX.Element => {
    const {
        monitorId
    } = props;

    const [readMonitorByIdQueryParams, setReadMonitorByIdQueryParams] = useState<any>(undefined);
    const [readMonitorByIdOptions, setReadMonitorByIdOptions] = useState<any>(
        {
            skip: true
        }
    );

    const {
        data: readMonitorByIdQueryData,
        isLoading: readMonitorByIdQueryIsLoading
    } = useReadMonitorByIdQuery(readMonitorByIdQueryParams, readMonitorByIdOptions);

    useEffect(() => {
        setReadMonitorByIdQueryParams({
            ...readMonitorByIdQueryParams,
            monitorId: monitorId
        });

        setReadMonitorByIdOptions(
            {
                ...readMonitorByIdOptions,
                skip: monitorId === undefined,
            }
        );
    }, [monitorId]);

    const [monitor, setMonitor] = useState<MonitorUpdateViewResource | undefined>(undefined);
    useEffect(() => {
        if (readMonitorByIdQueryData === undefined) {
            setMonitor((current: any) => current = createBlankMonitorCreateViewResource());
            return;
        }

        const updateViewMonitor = createMonitorUpdateViewFromMonitorUpdate(readMonitorByIdQueryData);
        setMonitor(updateViewMonitor);

    }, [readMonitorByIdQueryData]);


    return (
        <>
            <MonitorCard
                loading={true}
                value={monitor}
            />
        </>
    )
}