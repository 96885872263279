import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormGroup } from '@mui/material';
import { styled } from '@mui/material';
import { MonitorEditFormView } from 'components/MonitorEditFormView';
import { readMonitorById } from 'services/monitor';
import { createBlankMonitor, createBlankMonitorCreateViewResource, createMonitorUpdateFromMonitorUpdateView, createMonitorUpdateViewFromMonitorUpdate } from 'domain/monitor';
import { useUpdateMonitorMutation } from 'services/monitor';
import { useLazyRunMonitorQuery } from 'services/monitor';
import type { Monitor, MonitorCreateViewResource, MonitorUpdateResource, MonitorUpdateViewResource } from 'shared/types';
import { useReadMonitorByIdQuery } from 'services/monitor';

const Container = styled(FormGroup)``;

export const MonitorEditPage = (): JSX.Element => {
    const { id: monitorId } = useParams();
    const navigate = useNavigate();
    const [monitor, setMonitor] = useState<MonitorUpdateViewResource | undefined>(undefined);
    const [updateMonitor] = useUpdateMonitorMutation();
    const [useRunMonitorQueryOptions, setUseRunMonitorQueryOptions] = useState<Monitor | undefined>(undefined);

    const [
        trigger, result, lastPromiseInfo
    ] = useLazyRunMonitorQuery();

    const onMonitorEditFormViewSubmit = async ({ event, value }: any) => {    
        const monitor: MonitorUpdateResource = createMonitorUpdateFromMonitorUpdateView(value);

        if (monitor.id !== undefined) {  
            await updateMonitorHandler(monitor);

            if (monitor.enabled === true) {
                trigger({ monitorId: monitor.id });
            }

            navigate(`/spy/${monitor?.id}/monitorrun`);
        }
    }

    const updateMonitorHandler = async (monitor: MonitorUpdateResource) => {
        const updateMonitorByIdParams = {
            monitor: monitor
        }
        return await updateMonitor(updateMonitorByIdParams);
    } 

    const [readMonitorByIdQueryParams, setReadMonitorByIdQueryParams] = useState<any>(undefined);
    const [readMonitorByIdOptions, setReadMonitorByIdOptions] = useState<any>(
        {
            skip: true
        }
    );

    const {
        data: readMonitorByIdQueryData,
        isLoading: readMonitorByIdQueryIsLoading
    } = useReadMonitorByIdQuery(readMonitorByIdQueryParams, readMonitorByIdOptions);

    useEffect(() => {
        setReadMonitorByIdQueryParams({
            ...readMonitorByIdQueryParams,
            monitorId: monitorId
        });

        setReadMonitorByIdOptions(
            {
                ...readMonitorByIdOptions,
                skip: monitorId === undefined,
            }
        );
    }, [monitorId]);

    useEffect(() => {
        if (readMonitorByIdQueryData === undefined) {
            setMonitor((current) => current = createBlankMonitorCreateViewResource());
            return;
        }

        const updateViewMonitor = createMonitorUpdateViewFromMonitorUpdate(readMonitorByIdQueryData);
        setMonitor(updateViewMonitor);
    }, [readMonitorByIdQueryData]);

    return (
        <>
            <Container sx={
                {
                    width: {
                        md: '50%'
                    },
                    margin: {
                        md: '5% 0 0 25%'
                    },
                }
            }>
                {
                    <MonitorEditFormView value={monitor} onSubmit={onMonitorEditFormViewSubmit} ></MonitorEditFormView>
                }            
            </Container>
        </>
    )
}
