import { http } from 'apiClient';
import { createMonitorFromMonitorResponse } from 'domain/monitor'

import type { Monitor } from 'shared/types'
import type { MonitorFromResponse } from 'shared/types'
import type { ReadMonitorsResponse } from 'shared/types'
import type { ReadMonitorsRawResponse } from 'shared/types'

export interface CreateMonitorParams {
   monitor: Monitor
}

export const createMonitor = async ({ monitor }: CreateMonitorParams): Promise<any> => {
    let url = `/monitor`;
    const response = await http.post(url, JSON.stringify(monitor));

    return response;
}

export interface ReadMonitorsParams {
    pagination?: {
        page: number,
        pageSize: number
    }
}

export const readMonitors = async (params: ReadMonitorsParams | undefined = undefined): Promise<ReadMonitorsResponse> => {
    let url = `/monitor`;

    if (params?.pagination) {
        const skip: number = params.pagination.page * params.pagination.pageSize;
        const take: number = params.pagination.pageSize;
        url = `/monitor?skip=${skip}&take=${take}`;
    }

    const response = await http.get(url);

    const data: ReadMonitorsRawResponse = response.data;
    const result: ReadMonitorsResponse = {
        count: data.count,
        list: []
    };

    for (let i = 0; i < data.list.length; i++) {
        const monitorFromResponse: MonitorFromResponse =  data.list[i];
        const monitor = createMonitorFromMonitorResponse(monitorFromResponse);
        result.list.push(monitor);
    }

    return result;
};

export const readMonitorsCount = async (): Promise<number> => {
    const response = await http.get("/monitor/count");

    return response.data;
};

export const readMonitorById = async ({ id }: any): Promise<Monitor> => {
    const response = await http.get(`/monitor/${id}`);
    const readMonitorByIdResponse: MonitorFromResponse = response.data;
    const monitor = createMonitorFromMonitorResponse(readMonitorByIdResponse);

    return monitor;
};

export const updateMonitorById = async ({ id, monitor}: any): Promise<any> => {
    const response = await http.put(`/monitor/${id}`, JSON.stringify(monitor));

    return response;
};

export const monitorService = {
    createMonitor,
    readMonitors,
    readMonitorsCount,
    readMonitorById,
    updateMonitorById
}
