import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'queries';
import type { Platform } from 'shared/types';

export const platformApi = createApi(
    {
        reducerPath: 'platformApi',
        baseQuery: baseQueryWithAuth,
        tagTypes: ['Platforms'],
        endpoints: (builder) => (
            {
                readPlatforms: builder.query<any, void>(
                    {
                        query: () => {
                            const url = `/platform`;
                            return url;
                        },
                        transformResponse: (response: []) => {
                            return {
                                count: response.length,
                                list: response
                            }
                        }
                    }
                )
            }
        )
    }
);

export const {
    useReadPlatformsQuery,
    useLazyReadPlatformsQuery
} = platformApi;