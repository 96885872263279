import * as React from 'react';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useReadNotificationListQuery } from 'services/notification';
import { SuccessRunSpyNotification } from './components/SuccessRunSpyNotification';
import { TestNotification } from './components/TestNotification';
import { PriceWarningNotification } from './components/PriceWarningNotification';
import { SpyLaunchFinishedNotification } from './components/SpyLaunchFinishedNotification';

export const NotificationList = (): JSX.Element => {
    const [readNotificationListQueryOptions, setReadNotificationListQueryOptions] = useState<any>(
        {
            skip: false
        }
    );

    const {
        data: readNotificationListQueryData,
        isLoading: readNotificationListQueryDataIsLoading,
        isFetching: readNotificationListQueryDataIsFetching
    } = useReadNotificationListQuery(
        {},
        readNotificationListQueryOptions
        );

    const renderNotification = (notification: any) => {
        const data: any= JSON.parse(notification.data);

        switch (data.type) {
            case 0: {
                return (
                    <SuccessRunSpyNotification notificationLog={notification} />
                )
            }
            case 1: {
                return ('Spy error')
            }
            case 2: {
                return ('Spy run error')
            }
            case 3: {
                return (
                    <SpyLaunchFinishedNotification notificationLog = { notification } />
                )               
            }
            case 4: {
                return (<TestNotification notificationLog={notification} />)
            }
            case 5: {              
                return (<PriceWarningNotification notificationLog={notification} />)
            }

            default: 
        }
    }

    const renderList = () => {
        if (readNotificationListQueryData === undefined) {
            return ('');
        }


        return (
            readNotificationListQueryData.list.map((notification: any) => (
                <Box key={notification.id}>
                    {renderNotification(notification)}
                </Box>
               
            ))
        )
    }

    return (
        <>
            <h2>Notifications</h2>
            <Box sx={{ width: '100%', display: 'flex', gap: '20px', flexDirection: 'column' }}>
                {
                    renderList()
                }
            </Box>
        </>
    );
}