import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'queries';

interface ReadUserSettingsParams {
}

interface UpdatUserSettingsParams {
   settings: any
}

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['UserSettings'],
    endpoints: (builder) => ({
        readUserSettings: builder.query({ 
            query: () => {
                let url = `/user/settings`;

                return url;
            },
        }),
        updateUserSettings: builder.mutation({
            query: ({ settings }: UpdatUserSettingsParams) => ({
                url: `/user/settings`,
                method: 'PUT',
                body: settings
            }),
            invalidatesTags: [
                'UserSettings'
            ]
        }),
        createUserSettings: builder.mutation({
            query: ({ settings }: UpdatUserSettingsParams) => ({
                url: `/user/settings`,
                method: 'POST',
                body: settings
            }),
            invalidatesTags: [
                'UserSettings'
            ]
        }),
    }),
});

export const {
    useReadUserSettingsQuery,
    useUpdateUserSettingsMutation,
    useCreateUserSettingsMutation,
} = userApi;
