import { Box } from '@mui/material';
import type { Photo } from './PhotoListCarousel' 

export interface PhotoListCarouselItemProps {
    photo: Photo
}

export const PhotoListCarouselItem = (props: PhotoListCarouselItemProps): JSX.Element => {
    const {
        photo
    } = props;

    return (
        <Box sx={{height: '100%'} }>
            <picture>
                <img style={{ maxWidth: '100%', objectFit: 'cover', height: '440px', width: '100%', maxHeight: '440px' }} src={photo.url} alt="" loading="lazy" />
            </picture>
            <Box sx={{ padding: '8px 4px', height: '40px',display: 'flex', alignItems: 'center', position: 'absolute', bottom: '0px', width: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                {photo.description}
            </Box>

        </Box>

    )
}