import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { RequestState } from 'shared/enums'
import { hostServiceService } from 'services/hostService'

import type { ReadHostServiceByIdParams } from 'services/hostService'
import type { ReadHostServicePricesParams } from 'services/hostService'
import type { HostServiceDetail } from 'shared/types'

interface hostServiceDetailMeta {
    hostService: {
        requestId?: number,
        status: RequestState
    },
    hostServicePrices: {
        requestId?: number,
        status: RequestState
    }
}

interface HostServiceDetailState {
    hostServiceDetail: HostServiceDetail,
    hostServiceDetailMeta: hostServiceDetailMeta
}

export interface ReadHostServiceThunkParams {
    hostServiceId: number,
    monitorId: number
}

export const readHostService = createAsyncThunk(
    'hostServiceDetail/readHostService',
    async function ({ hostServiceId, monitorId }: ReadHostServiceThunkParams, { rejectWithValue }) {
        try {

            const readHostServiceByIdParams: ReadHostServiceByIdParams = {
                hostServiceId: hostServiceId,
                monitorId: monitorId
            };
            const response: any = await hostServiceService.readHostServiceById(readHostServiceByIdParams);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
    {
        condition: (_, { getState, extra }) => {
            const state: RootState = getState() as RootState;
            const status = state.hostServiceDetail.hostServiceDetailMeta.hostService.status;

            if (status === RequestState.Pending) {
                return false
            }

        },
    }
);

export interface ReadHostServicePricesThunkParams {
    hostServiceId: number,
    monitorId?: number 
}

export const readHostServicePrices = createAsyncThunk(
    'hostServiceDetail/readHostServicePrices',
    async function ({ hostServiceId, monitorId }: ReadHostServicePricesThunkParams, { rejectWithValue }) {
        try {

            const readHostServicePricesParams: ReadHostServicePricesParams = {
                hostServiceId: hostServiceId,
            };

            if (monitorId !== undefined) {
                readHostServicePricesParams.monitorId = monitorId;
            }

            const response: any = await hostServiceService.readHostServicePrices(readHostServicePricesParams);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);



const initialState: HostServiceDetailState = {
    hostServiceDetail: {
        hostService: undefined,
        hostServicePrices: [],
        hostServicePricesCount: 0
    },
    hostServiceDetailMeta: {
        hostService: {
            requestId: undefined,
            status: RequestState.Idle,
        },
        hostServicePrices: {
            requestId: undefined,
            status: RequestState.Idle,
        }
    }
}

export const hostServiceDetailSlice = createSlice({
    name: 'HostServiceDetail',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(readHostService.pending, (state, action) => {
                state.hostServiceDetailMeta.hostService.status = RequestState.Pending;
            })
            .addCase(readHostService.fulfilled, (state, action) => {
                state.hostServiceDetailMeta.hostService.status = RequestState.Success;
                state.hostServiceDetail.hostService = action.payload;
            })   
            .addCase(readHostServicePrices.pending, (state, action) => {
                state.hostServiceDetailMeta.hostServicePrices.status = RequestState.Pending;
            })
            .addCase(readHostServicePrices.fulfilled, (state, action) => {
                state.hostServiceDetailMeta.hostServicePrices.status = RequestState.Success;
                state.hostServiceDetail.hostServicePrices = action.payload.list;
                state.hostServiceDetail.hostServicePricesCount = action.payload.count;
            }) 
    }
})

export const selectHostServiceDetail = (state: RootState) => state.hostServiceDetail.hostServiceDetail;

export default hostServiceDetailSlice.reducer