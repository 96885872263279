import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { DateTime } from 'luxon'
import { useAppDispatch } from 'shared/hooks';
import { useAppSelector } from 'shared/hooks';
import { readMonitorRunByIdForMonitorRunResultDetail } from 'store/monitorRunResultSlice';
import { selectMonitorRunResultDetail } from 'store/monitorRunResultSlice';
import Toolbar from '@mui/material/Toolbar';
import { ReadMonitorRunByIdForMonitorRunResultDetailThunkParams } from 'store/monitorRunResultSlice';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import type { MonitorRunResultDetail } from 'shared/types'
import type { MonitorRunsResultDetailCard } from 'shared/types'

type MonitorRunResultDetailCardHeaderProps = {
    monitorRunsResultDetailCard?: MonitorRunsResultDetailCard,
}

export const MonitorRunResultDetailCardHeader = (
    { monitorRunsResultDetailCard }: MonitorRunResultDetailCardHeaderProps
): JSX.Element => {
    const loading = false;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const renderPeriod = () => {
        if (loading === false
            && monitorRunsResultDetailCard
            && monitorRunsResultDetailCard.firstRunDateTimeUTC
            && monitorRunsResultDetailCard.lastRunDateTimeUTC) {
            const dateTimeCheckIn = DateTime.fromISO(monitorRunsResultDetailCard.firstRunDateTimeUTC).toFormat('M/dd/yyyy');
            const dateTimeCheckOut = DateTime.fromISO(monitorRunsResultDetailCard.lastRunDateTimeUTC).toFormat('M/dd/yyyy');

            return (
                `${dateTimeCheckIn} - ${dateTimeCheckOut}`
            )
        }

        return (
            <>
                <Skeleton variant="text" width={270} height={50} animation={false} />
            </>
        )
    }

    return (
        <>
        <Toolbar sx={{
            bgcolor: '#648AAF',
            paddingRight: { xs: '12px', md: '24px' },
            paddingLeft: { xs: '12px', md: '24px' },
        }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Box component="h2" sx={{ fontWeight: '700', m: 0, color: '#FFF', fontSize: {xs: '24px' , md: '36px'} }}>
                     {monitorRunsResultDetailCard?.title }
                </Box>
            </Box>
            {/*<IconButton*/}
            {/*    ref={anchorRef}*/}
            {/*    size="large"*/}
            {/*    edge="start"*/}
            {/*    color="default"*/}
            {/*    aria-label="menu"*/}
            {/*    sx={{ mr: 0 }}*/}
            {/*    onClick={handleToggle}*/}
            {/*>*/}
            {/*    <MoreVertIcon sx={{ color: '#FFF' }} />*/}
            {/*</IconButton>*/}
        </Toolbar>
        <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={handleClose}>Share</MenuItem>
                                    <MenuItem onClick={handleClose}>Export</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}