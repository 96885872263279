import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { platformsService } from 'services/platform';
import { RequestState } from 'shared/enums'

import type { Platform } from 'shared/types';

interface PlatformState {
    platforms: Platform[];
    platformsRequestState: {
        requestId?: number,
        status: RequestState
    };
    platformsCount: number;
}

export const readPlatforms = createAsyncThunk(
    'platform/readPlatforms',
    async function (_, { rejectWithValue }) {
        try {
            const response: any = await platformsService.readPlatforms();

            return [response[0]]
            //return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
    {
        condition: (_, { getState, extra }) => {
            const state: RootState = getState() as RootState;
            const status = state.platform.platformsRequestState.status;

            if (status === RequestState.Pending || status === RequestState.Success) {
                return false
            }

        },
    }
);

const initialState: PlatformState = {
    platforms: [],
    platformsRequestState: {
        requestId: undefined,
        status: RequestState.Idle,
    },
    platformsCount: 0
}

export const monitorRunSlice = createSlice({
    name: 'Platform',
    initialState,
    reducers: {
        setPlatforms(state, action: PayloadAction<any>) {
            state.platforms = action.payload;
        },
        setPlatformsCount(state, action: PayloadAction<number>) {
            state.platformsCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(readPlatforms.pending, (state, action) => {
                state.platformsRequestState.status = RequestState.Pending;
            })
            .addCase(readPlatforms.fulfilled, (state, action) => {
                state.platforms = action.payload;
                //state.platformsCount = action.payload.count;

                state.platformsRequestState.status = RequestState.Success;
            })
    },
})

export const selectPlatforms = (state: RootState) => state.platform.platforms;
export const selectPlatformsCount = (state: RootState) => state.platform.platformsCount;

export default monitorRunSlice.reducer