import {useState} from 'react';
import Card from '@mui/material/Card';
import {Box} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

type PriceWarningNotificationProps = {
    notificationLog: any,
    loading?: boolean,
}

export const PriceWarningNotification = (props: PriceWarningNotificationProps): JSX.Element => {
    const {
        notificationLog,
        loading = false
    } = props;
    const [notification, setNotification] = useState<any | null>(null);

    useEffect(() => {
        if (notificationLog === undefined) {
            return;
        }

        const data: any = JSON.parse(notificationLog.data);
        const message = `Spy: ${data.MonitorName}, MedianPrice: ${data.MedianPrice},OwnPrice: ${data.OwnPrice}`;

        const notification = {
            title: `Warning`,
            date: DateTime.fromISO(notificationLog.dateTimeUtc).toFormat('M/dd/yyyy hh:mm a'),
            message: message,
            data: data,
        }

        setNotification(notification);
    }, [notificationLog]);

    if (notification === null) {
        return (<></>);
    }

    return (
        <Link to={`/spy/${notification.data.MonitorId}/monitorrun?monitorRunId=${notification.spyRunId}`}  style={{ color: '#FFF' }}>
            <Card sx={{ maxWidth: 345 }} elevation={3}>
                <CardHeader
                    action={
                        <Box>
                            {notification.date}
                        </Box>
                    }
                    title={notification.title}
                />
                <CardContent>
                    <Typography variant="body1" color="text.primary">
                        {notification.message}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}
