import { Box, Stack, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import {
    useCreatePushNotificationSubscriptionMutation,
    useDeletePushNotificationSubscriptionMutation,
    useSendTestNotificationMutation
} from 'services/pushNotificationSubscription';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';

export const NotificationsSettingsView = (): JSX.Element => {
    const [subscribeError, setSubscribeError] = useState('');
    const [blockSwitch, setBlockSwitch] = useState<boolean>(false);
    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [createPushNotificationSubscription] = useCreatePushNotificationSubscriptionMutation();
    const [deletePushNotificationSubscriptionMutation] = useDeletePushNotificationSubscriptionMutation();

    const deletePushNotificationSubscriptionHandler = async (subscription: any) => {
        const endpoint = subscription.endpoint;
        const subscriptionSimpleObject = JSON.parse(JSON.stringify(subscription));

        await deletePushNotificationSubscriptionMutation({
            endpoint: endpoint,
            p256dh: subscriptionSimpleObject.keys.p256dh,
            auth: subscriptionSimpleObject.keys.auth,
        })
    }

    const createPushNotificationSubscriptionHandler = async (subscription: any) => {
        const endpoint = subscription.endpoint;
        const subscriptionSimpleObject = JSON.parse(JSON.stringify(subscription));

        await createPushNotificationSubscription({
            endpoint: endpoint,
            p256dh: subscriptionSimpleObject.keys.p256dh,
            auth: subscriptionSimpleObject.keys.auth,
        })
    }

    const urlB64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    const subscribeToPushNotifications = async () => {
        try {
            const result = await Notification.requestPermission();
            if (result === 'denied') {
                setSubscribed(false);
                return;
            }
            const registration = await navigator.serviceWorker.getRegistration();

            if (registration === undefined) {
                setSubscribed(false);
                return;
            }

            const subscribed = await registration.pushManager.getSubscription();

            if (subscribed) {
                //
                await createPushNotificationSubscriptionHandler(subscribed);
                //
            }
            else {
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(`BHeQ1k-h3-p2egmUGJgUhVUey5Zp9BwwfPoR9nL0qzmHk6yH_8aHMgosGcQ7Ly-R1AxEDu5lLNcT8f_k83ldwcI`)
                });

                await createPushNotificationSubscriptionHandler(subscription);
            }
            setSubscribed(true);
        }
        catch (error) {
            setSubscribeError(error instanceof Error ? error.message : String(error));
        }
    }

    const unSubscribeToPushNotifications = async () => {
        const registration = await navigator.serviceWorker.getRegistration();

        if (registration === undefined) {
            return;
        }

        const subscription = await registration.pushManager.getSubscription();
        if (subscription !== null) {
            deletePushNotificationSubscriptionHandler(subscription);
            await subscription.unsubscribe();
        }
        setSubscribed(false);
    }

    const [disabledTestNotification, setDisabledTestNotification] = useState(false);

    const [
        trigger
    ] = useSendTestNotificationMutation();

    const handleTestNotification = async () => {
        setDisabledTestNotification(true);
        const registration = await navigator.serviceWorker.getRegistration();

        if (registration === undefined) {
            setDisabledTestNotification(false);
            return;
        }

        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
            trigger(subscription);
            setTimeout(() => {
                setDisabledTestNotification(false);
            }, 3000)
        }
        else {
            setDisabledTestNotification(false);
        }
    }


    const checkNotifications = async () => {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration === undefined) {
            return;
        }

        const subscribed = await registration.pushManager.getSubscription();
        if (subscribed) {
            setSubscribed(true);
            return;
        }
    }

    const handleEnableNotificationChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlockSwitch(true);
        if (event.target.checked) {
            await subscribeToPushNotifications();
        }
        else {
            await unSubscribeToPushNotifications();
        }
        setBlockSwitch(false);
    };

    useEffect(() => {
        checkNotifications();
    }, [])

    return (
        <>
            <h2>Notifications settings</h2>
            <Box sx={{ width: '100%' }}>
                <Box my={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                        !Object.hasOwn(window, 'Notification') ? <Alert severity="error">Please add to home screen first in order to turn on notifications.</Alert> :
                            <Stack spacing={2}>
                                <FormControlLabel sx={{ m: 0 }} labelPlacement="start" control={<Switch disabled={blockSwitch} checked={subscribed} color="success" onChange={handleEnableNotificationChanged} />} label="Enable Notifications" />
                                {subscribeError !== '' ? <Alert severity="error">{subscribeError}</Alert> : null}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={disabledTestNotification || !subscribed}
                                    startIcon={<AddIcon />}
                                    sx={{
                                        minWidth: 110,
                                    }}
                                    onClick={handleTestNotification}
                                >
                                    Test notification
                                </Button >
                            </Stack>
                    }
                </Box>
            </Box>
        </>
    );
}