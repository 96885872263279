import { useEffect } from 'react';
import { MonitorsRunResultListCard } from '../components/MonitorsRunResultListCard';
import { reset } from 'store/monitorRunResultSlice';
import { useAppDispatch } from 'shared/hooks';

export const MonitorsRunResultListPage = () => {
    console.log('MonitorsRunResultListPage')
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(reset());

        return () => {
            dispatch(reset());
        }
    }, [dispatch])

    return (
        <>
            <MonitorsRunResultListCard></MonitorsRunResultListCard>
        </>
    )
}