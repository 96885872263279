import { mainApi } from 'services/mainApi';

interface ReadNotificationListParams {
    pagination?: {
        page: number,
        pageSize: number
    }
}

export const notificationApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        readNotificationList : builder.query({
            query: (params: ReadNotificationListParams) => {
                let url = `/notifications`;

                if (params?.pagination) {
                    const skip: number = params.pagination.page * params.pagination.pageSize;
                    const take: number = params.pagination.pageSize;
                    url = `/notifications?skip=${skip}&take=${take}`;
                }

                return url;
            },
        }),      
    }),
});

export const {
    useReadNotificationListQuery,
} = notificationApi;
