import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { LogoIcon } from 'shared/icons';

type InvitationToInstallApplicationProps = {

}

export const InvitationToInstallApplication = (
    {
    }: InvitationToInstallApplicationProps
): JSX.Element => {
    return (
        <Box sx={{
            backgroundImage: `url(${"images/invitation-to-install-application/bg-000-0000-0000-1x.png"})`,
            backgroundSize: 'cover',
            padding: '40px',


            'flex': 1
        }}>
            <Box sx={{
                display: 'flex', 
                justifyContent: 'space-between'
            }} >
                <Box sx={{ display: 'flex', flexDirection: 'column' } }>
                    <Box component="p" sx={{ fontSize: { sx: '18px', md:'22px'}, fontWeight: '600', maxWidth: '224px' } }>
                        <Box component="span" sx={{
                            color: '#648AAF'
                        }}>Install this App</Box>  to save this information
                        and to receive notifications
                        with new data
                    </Box>
                    <Link to="/install/instruction">
                        <Button
                            color="success"
                            variant="contained"
                            size="large"
                            //startIcon={<AddIcon />}
                            sx={{
                                minWidth: 110,
                            }}
                        >
                            Install
                        </Button >
                        </Link>
                </Box>
                <Box>
                    <Link to='/'>
                        <LogoIcon sx={{
                            width: '100%',

                        }} />
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}