import {useState} from 'react';
import Card from '@mui/material/Card';
import {Box} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

type TestNotificationProps = {
    notificationLog: any,
    loading?: boolean,
}

export const TestNotification = (props: TestNotificationProps): JSX.Element => {
    const {
        notificationLog,
        loading = false
    } = props;
    const [notification, setNotification] = useState<any | null>(null);

    useEffect(() => {
        if (notificationLog === undefined) {
            return;
        }

        const message = `Test notification`;

        const notification = {
            title: `Test`,
            date: DateTime.fromISO(notificationLog.dateTimeUtc).toFormat('M/dd/yyyy hh:mm a'),
            message: message,
        }

        setNotification(notification);
    }, [notificationLog]);

    if (notification === null) {
        return (<></>);
    }

    return (
        <Card sx={{ maxWidth: 345 }} elevation={3}>
            <CardHeader
                action={
                    <Box>
                        {notification.date}
                    </Box>
                }
                title={notification.title}
            />
            <CardContent>
                <Typography variant="body1" color="text.primary">
                    {notification.message}
                </Typography>
            </CardContent>
        </Card>
    )
}
