import {useState} from 'react';
import Card from '@mui/material/Card';
import {Box} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

type SuccessRunSpyNotificationProps = {
    notificationLog: any,
    loading?: boolean,
}

export const SuccessRunSpyNotification = (props: SuccessRunSpyNotificationProps): JSX.Element => {
    const {
        notificationLog,
        loading = false
    } = props;
    const [notification, setNotification] = useState<any | null>(null);

    const getParameterGrewLabel = (grewType: number) => {
        switch (grewType) {
            case -1:
                return '↓';
            case 0:
                return '';
            case 1:
                return '↑';
            default:
                return '';
        }
    }

    useEffect(() => {
        if (notificationLog === undefined) {
            return;
        }

        const data: any = JSON.parse(notificationLog.data);
        const averagePriceGrewLabel = getParameterGrewLabel(data.AveragePriceGrew);
        const competitorsCountGrewLabel = getParameterGrewLabel(data.CompetitorsCountGrew);
        const message = `Spy:${data.MonitorName}, ${DateTime.fromISO(data.CheckInDate).toFormat('M/dd/yyyy')}, avg price:${data.AveragePrice}$${averagePriceGrewLabel}, comps:${data.NumberOfCompetitiveProperties}${competitorsCountGrewLabel}`;

        const notification = {
            title: `R: ${DateTime.fromISO(data.CheckInDate).toFormat('M/dd/yyyy')}`,
            date: DateTime.fromISO(notificationLog.dateTimeUtc).toFormat('M/dd/yyyy hh:mm a'),
            data: data,
            message: message,
        }

        setNotification(notification);
    }, [notificationLog]);

    if (notification === null) {
        return (<></>);
    }

    return (
        <Link to={`/spy/${notification.data.MonitorId}/monitorrun?checkInDate=${notification.data.CheckInDate}`} style={{ color: '#FFF' }}>
            <Card sx={{ maxWidth: 345 }} elevation={3}>
                <CardHeader
                    action={
                        <Box>
                            {notification.date}
                        </Box>
                    }
                    title={notification.title}
                />
                <CardContent>
                    <Typography variant="body1" color="text.primary">
                        {notification.message}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}
