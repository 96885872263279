import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const LogoIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 278 65" fill="none">
            <path d="M107.718 39.6104C107.758 35.3956 109.296 31.8494 112.352 29.0328C114.376 27.1685 116.501 25.3651 118.667 23.6426C120.589 22.0823 122.897 21.3123 125.325 20.9476C125.872 20.8665 126.256 20.6639 126.641 20.2789C127.369 19.5291 128.098 18.7591 128.867 18.0701C131.721 15.5372 135.303 16.064 137.306 19.3062C137.59 19.7723 137.853 19.8939 138.359 19.6912C139.593 19.1846 140.868 19.2049 142.103 19.6912C142.649 19.9141 142.852 19.7115 143.135 19.2657C145.098 16.0235 148.964 15.4561 151.635 18.1512C153.254 19.7925 154.894 21.0489 157.282 21.4339C159.913 21.8594 161.957 23.5819 163.9 25.3448C165.418 26.7227 167.118 27.9386 168.534 29.4178C172.481 33.5516 173.736 38.4959 172.015 43.9063C170.214 49.6207 166.146 52.9845 160.237 54.0179C152.991 55.2743 145.584 50.1881 144.005 42.8729C143.803 41.961 143.621 41.6166 142.609 42.0218C141.03 42.6703 139.33 42.6905 137.772 42.0016C136.719 41.5355 136.639 42.0421 136.456 42.8121C135.04 48.4454 131.559 52.1537 125.973 53.6735C117.533 55.9633 109.134 50.3097 107.859 41.6368C107.738 40.9884 107.758 40.2791 107.718 39.6104ZM122.188 49.337C127.572 49.3776 131.984 45.0816 132.024 39.7523C132.065 34.4432 127.734 30.0662 122.492 29.9244C117.25 29.8028 112.595 34.0177 112.535 39.5091C112.474 44.9803 116.744 49.2965 122.188 49.337ZM158.172 49.337C163.596 49.337 167.866 45.0614 167.887 39.6712C167.887 34.2811 163.576 29.9447 158.213 29.9447C152.769 29.9447 148.357 34.3419 148.438 39.6915C148.498 45.1424 152.748 49.337 158.172 49.337ZM143.216 35.0916C143.216 33.3895 141.84 31.9913 140.18 31.9913C138.541 32.0116 137.205 33.3692 137.185 35.0308C137.165 36.7938 138.46 38.1109 140.2 38.1312C141.961 38.1515 143.216 36.8748 143.216 35.0916Z" fill="#222831" />
            <path d="M116.115 39.0073C116.194 36.0764 118.987 33.285 121.919 33.2252C122.917 33.2053 123.695 33.7835 123.794 34.6408C123.894 35.4583 123.375 36.1761 122.338 36.3356C120.423 36.6147 119.386 37.5917 119.187 39.5456C119.087 40.5226 118.369 41.021 117.451 40.9014C116.553 40.7818 116.075 40.1039 116.115 39.0073Z" fill="#222831" />
            <path d="M151.786 39.1534C151.826 36.1794 154.637 33.3251 157.627 33.2253C158.604 33.2053 159.342 33.7442 159.461 34.5426C159.581 35.4209 159.102 36.1395 158.106 36.2991C156.112 36.5985 155.015 37.6564 154.836 39.6924C154.756 40.5307 154.019 40.9898 153.181 40.9099C152.304 40.8101 151.766 40.1315 151.786 39.1534Z" fill="#222831" />
            <path d="M15.6302 54.2088C12.6579 54.2088 9.75391 53.882 6.91828 53.2284C4.1168 52.5404 1.81071 51.6288 0 50.4936L3.89473 41.6184C5.60295 42.616 7.51615 43.4244 9.63434 44.0436C11.7525 44.6284 13.7853 44.9208 15.7327 44.9208C17.4067 44.9208 18.6025 44.766 19.3199 44.4564C20.0374 44.1124 20.3961 43.6136 20.3961 42.96C20.3961 42.2032 19.9178 41.6356 18.9612 41.2572C18.0388 40.8788 16.5014 40.466 14.349 40.0188C11.5817 39.434 9.27561 38.8147 7.43074 38.1611C5.58587 37.4731 3.98014 36.3723 2.61357 34.8587C1.247 33.3107 0.563711 31.2295 0.563711 28.6151C0.563711 26.3447 1.19575 24.2807 2.45983 22.4231C3.72391 20.5655 5.60295 19.1035 8.09694 18.0371C10.6251 16.9707 13.6828 16.4375 17.2701 16.4375C19.7299 16.4375 22.1385 16.7127 24.4958 17.2631C26.8873 17.7791 28.9884 18.5531 30.7991 19.5851L27.1606 28.4087C23.6417 26.6199 20.3107 25.7255 17.1676 25.7255C14.0586 25.7255 12.5041 26.4823 12.5041 27.9959C12.5041 28.7183 12.9654 29.2687 13.8878 29.6471C14.8102 29.9911 16.3305 30.3695 18.4487 30.7823C21.1819 31.2983 23.488 31.9003 25.367 32.5883C27.2461 33.2419 28.8689 34.3255 30.2354 35.8391C31.6362 37.3527 32.3365 39.4168 32.3365 42.0312C32.3365 44.3016 31.7045 46.3656 30.4404 48.2232C29.1763 50.0464 27.2802 51.5084 24.7521 52.6092C22.2581 53.6756 19.2174 54.2088 15.6302 54.2088Z" fill="#222831" />
            <path d="M43.2244 26.7059H32.7189V17.2631H65.8241V26.7059H55.3186V53.3832H43.2244V26.7059Z" fill="#222831" />
            <path d="M84.3393 44.3016H81.0595V53.3832H68.9654V17.2631H86.2354C89.5494 17.2631 92.4363 17.8135 94.8961 18.9143C97.3559 20.0151 99.252 21.5975 100.584 23.6615C101.917 25.7255 102.583 28.1335 102.583 30.8855C102.583 33.4655 102.002 35.7187 100.841 37.6451C99.6791 39.5716 98.005 41.1024 95.8185 42.2376L103.352 53.3832H90.4376L84.3393 44.3016ZM90.3864 30.8855C90.3864 29.5439 89.9764 28.5119 89.1565 27.7895C88.3365 27.0327 87.1066 26.6543 85.4667 26.6543H81.0595V35.1167H85.4667C87.1066 35.1167 88.3365 34.7555 89.1565 34.0331C89.9764 33.2763 90.3864 32.2271 90.3864 30.8855Z" fill="#222831" />
            <path d="M191.947 54.2088C188.974 54.2088 186.07 53.882 183.235 53.2284C180.433 52.5404 178.127 51.6288 176.316 50.4936L180.211 41.6184C181.919 42.616 183.833 43.4244 185.951 44.0436C188.069 44.6284 190.102 44.9208 192.049 44.9208C193.723 44.9208 194.919 44.766 195.636 44.4564C196.354 44.1124 196.713 43.6136 196.713 42.96C196.713 42.2032 196.234 41.6356 195.278 41.2572C194.355 40.8788 192.818 40.466 190.665 40.0188C187.898 39.434 185.592 38.8147 183.747 38.1611C181.902 37.4731 180.297 36.3723 178.93 34.8587C177.563 33.3107 176.88 31.2295 176.88 28.6151C176.88 26.3447 177.512 24.2807 178.776 22.4231C180.04 20.5655 181.919 19.1035 184.413 18.0371C186.942 16.9707 189.999 16.4375 193.587 16.4375C196.046 16.4375 198.455 16.7127 200.812 17.2631C203.204 17.7791 205.305 18.5531 207.116 19.5851L203.477 28.4087C199.958 26.6199 196.627 25.7255 193.484 25.7255C190.375 25.7255 188.821 26.4823 188.821 27.9959C188.821 28.7183 189.282 29.2687 190.204 29.6471C191.127 29.9911 192.647 30.3695 194.765 30.7823C197.498 31.2983 199.804 31.9003 201.683 32.5883C203.562 33.2419 205.185 34.3255 206.552 35.8391C207.953 37.3527 208.653 39.4168 208.653 42.0312C208.653 44.3016 208.021 46.3656 206.757 48.2232C205.493 50.0464 203.597 51.5084 201.068 52.6092C198.575 53.6756 195.534 54.2088 191.947 54.2088Z" fill="#648AAF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M166.98 2.79787C166.98 1.25265 165.728 0 164.182 0C162.637 0 161.385 1.25265 161.385 2.79787V11.8488L142.151 0.725592C141.284 0.224453 140.216 0.224453 139.349 0.725592L110.321 17.5128C108.984 18.2864 108.527 19.9979 109.3 21.3355C110.074 22.6732 111.785 23.1304 113.123 22.3569L140.75 6.37966L168.377 22.3569C169.715 23.1304 171.426 22.6732 172.2 21.3355C172.974 19.9979 172.516 18.2864 171.179 17.5128L166.955 15.07C166.972 14.9453 166.98 14.8181 166.98 14.6888V2.79787Z" fill="#222831" />
            <path d="M277.258 25.816L265.09 55.144C263.669 58.6107 261.936 61.0374 259.89 62.424C257.845 63.8454 255.349 64.556 252.402 64.556C250.912 64.556 249.404 64.3134 247.878 63.828C246.388 63.3774 245.174 62.7707 244.238 62.008L247.67 55.092C248.225 55.612 248.884 56.0107 249.646 56.288C250.444 56.6 251.241 56.756 252.038 56.756C252.974 56.756 253.737 56.5654 254.326 56.184C254.95 55.8027 255.47 55.196 255.886 54.364L243.822 25.816H253.962L260.878 43.028L267.846 25.816H277.258Z" fill="#648AAF" />
            <path d="M229.904 25.348C232.469 25.348 234.792 25.9547 236.872 27.168C238.987 28.3467 240.651 30.0454 241.864 32.264C243.112 34.448 243.736 36.996 243.736 39.908C243.736 42.82 243.112 45.3854 241.864 47.604C240.651 49.788 238.987 51.4867 236.872 52.7C234.792 53.8787 232.469 54.468 229.904 54.468C226.611 54.468 223.993 53.48 222.052 51.504V64.088H212.172V25.816H221.584V28.624C223.421 26.44 226.195 25.348 229.904 25.348ZM227.824 46.616C229.557 46.616 230.979 46.0267 232.088 44.848C233.197 43.6347 233.752 41.988 233.752 39.908C233.752 37.828 233.197 36.1987 232.088 35.02C230.979 33.8067 229.557 33.2 227.824 33.2C226.091 33.2 224.669 33.8067 223.56 35.02C222.451 36.1987 221.896 37.828 221.896 39.908C221.896 41.988 222.451 43.6347 223.56 44.848C224.669 46.0267 226.091 46.616 227.824 46.616Z" fill="#648AAF" />
        </SvgIcon>
  );
}