import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { monitorService } from 'services/monitor'
import { RequestState } from 'shared/enums'

import type { Monitor } from 'shared/types'
import type { ReadMonitorsResponse } from 'shared/types'
import type { PropertyStoreMeta } from 'shared/types'

interface MonitorState {
    data: {
        monitors: {
            list: Monitor[],
            count: number
        },
        monitorsShort: {
            list: Monitor[],
            count: number
        },
        currentPage: number;
    }
    meta: {
        monitors: PropertyStoreMeta,
        monitorsShort: PropertyStoreMeta,
    }

}

const initialState: MonitorState = {
    data: {
        monitors: {
            list: [],
            count: 0
        },
        monitorsShort: {
            list: [],
            count: 0

        },
        currentPage: 0,
    },
    meta: {
        monitors: {
            requestId: undefined,
            status: RequestState.Idle,
        },
        monitorsShort: {
            requestId: undefined,
            status: RequestState.Idle,
        },
    }
}

export interface ReadMonitorsThunkParams {
    pagination: {
        page: number,
        pageSize: number
    }
}

export const readMonitors = createAsyncThunk(
    'monitor/readMonitors',
    async function (params: ReadMonitorsThunkParams, { rejectWithValue }) {
        try {
            const response: ReadMonitorsResponse = await monitorService.readMonitors(params);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export interface ReadMonitorsShortThunkParams {
}

export const readMonitorsShort = createAsyncThunk(
    'monitor/readMonitorsShort',
    async function (params: ReadMonitorsShortThunkParams, { rejectWithValue }) {
        try {
            const response: ReadMonitorsResponse = await monitorService.readMonitors(params);

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


export const MonitorSlice = createSlice({
    name: 'Monitor',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(readMonitors.fulfilled, (state, action) => {
                state.data.monitors.list = action.payload.list;
                state.data.monitors.count = action.payload.count;
            })
            .addCase(readMonitorsShort.pending, (state, action) => {
                //state.meta.hostService.status = RequestState.Pending;
            })
            .addCase(readMonitorsShort.fulfilled, (state, action) => {
                //state.hostServiceDetailMeta.hostService.status = RequestState.Success;
                //state.hostServiceDetail.hostService = action.payload;
            })
    },
})

export const selectMonitors = (state: RootState) => state.monitor.data.monitors.list;
export const selectMonitorsCount = (state: RootState) => state.monitor.data.monitors.count;

export default MonitorSlice.reducer