import * as React from 'react';
import { ImageList } from '@mui/material';
import { ImageListItem } from '@mui/material';
import { ImageListItemBar } from '@mui/material';
import { Box } from '@mui/material';
import { IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useState, useEffect } from 'react';
import { useAppDispatch } from 'shared/hooks';
import { useAppSelector } from 'shared/hooks';
import { readHostService } from 'store/hostServiceDetailSlice';
import { selectHostServiceDetail } from 'store/hostServiceDetailSlice';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PhotoListCarousel } from './PhotoListCarousel';
import { useReadHostServiceByMonitorIdQuery } from 'services/hostService';
import type { ReadHostServiceThunkParams } from 'store/hostServiceDetailSlice';
import type { MonitorRunResultHostServicePicture } from 'shared/types'



export type HostServiceDetailPhotosProps = {
    hostServiceId: number,
    monitorId?: number
}

export const HostServiceDetailPhotos = (props: HostServiceDetailPhotosProps): JSX.Element => {
    //const dispatch = useAppDispatch();
    //const hostServiceDetail = useAppSelector(selectHostServiceDetail);
    const {
        hostServiceId = -1,
        monitorId = -1
    } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [cols, setCols] = useState<number>(1);

    //useEffect(() => {
    //    if (hostServiceId === undefined || monitorId === undefined) {
    //        return;
    //    }

    //    const readHostServiceThunkParams: ReadHostServiceThunkParams = {
    //        hostServiceId: hostServiceId,
    //        monitorId: monitorId
    //    }

    //    dispatch(readHostService(readHostServiceThunkParams))
    //}, [])

    const readMonitorRunResultByMonitorIdQueryOptions = {
        refetchOnMountOrArgChange: true,
    };

    const readHostServiceByMonitorIdQueryParams = {
        monitorId: monitorId,
        hostServiceId: hostServiceId
    }

    const {
        data: readHostServiceByMonitorIdQueryData,
        isLoading: readHostServiceByMonitorIdQueryDataIsLoading,
        isFetching: readHostServiceByMonitorIdQueryDataIsFetching,
        error: readHostServiceByMonitorIdQueryError
    } = useReadHostServiceByMonitorIdQuery(readHostServiceByMonitorIdQueryParams, readMonitorRunResultByMonitorIdQueryOptions);




    useEffect(() => {
        if (matches === true) {
            setCols(2);

            return;
        }

        setCols(1);

    }, [matches])

    const renderImageListItem = (
        hostservicePicture: MonitorRunResultHostServicePicture,
        cols: number | undefined,
        rows: number | undefined
    ) => {
        return (
            <ImageListItem key={hostservicePicture.url} cols={cols} rows={rows}>
                <img
                    src={hostservicePicture.url}
                    alt={hostservicePicture.description}
                    loading="lazy"
                />
                <ImageListItemBar
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}

                    title={hostservicePicture.description !== undefined &&
                        (
                            <Box sx={{ color: '#222' }}>{hostservicePicture.description}</Box>
                        )
                    }
                />
            </ImageListItem>
        )
    }

    const renderImageList = (cols: number) => {
        if (matches === false) {
        return (
            <PhotoListCarousel photos={readHostServiceByMonitorIdQueryData?.strHostservicePictures || undefined}></PhotoListCarousel>
            )
        }


        if (readHostServiceByMonitorIdQueryData?.strHostservicePictures !== undefined
            && Array.isArray(readHostServiceByMonitorIdQueryData?.strHostservicePictures)
            && readHostServiceByMonitorIdQueryData?.strHostservicePictures.length > 0) {
            return (
                <ImageList
                    gap={12}
                    sx={{
                        mb: 8,
                        gridTemplateColumns:
                            'repeat(auto-fill, minmax(240px, 1fr)) !important',
                    }}
                    variant="quilted"
                    cols={cols}>
                    {
                        renderImageListItem(readHostServiceByMonitorIdQueryData?.strHostservicePictures[0], cols, 2)
                    }
                    {
                        readHostServiceByMonitorIdQueryData?.strHostservicePictures
                            .slice(1, 5)
                            .map((hostservicePicture: any, index: number) => {
                                return renderImageListItem(hostservicePicture, 1, 1)
                        })               
                    }
                </ImageList>
            );
        }
    }

    return (
        <Box sx={{ minHeight: '50vh', display: 'grid', position: 'relative'} }>
            <Box sx={{
                maxWidth: '100%',
                height: '100%',
                width: '100%'
            }}>
                {
                    renderImageList(cols)
                }
            </Box>
        </Box>

    )
}
