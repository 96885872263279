import React from 'react';
import { MonitorRunResultDetailCard } from 'components/MonitorRunResultDetailCard';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useReadMonitorRunsResultCardQuery } from 'services/monitorRunResult';
import type { MonitorRunResult } from 'shared/types';

export interface MonitorRunResultData {
    result: MonitorRunResult[];
    loading: boolean;
}

export const MonitorRunResultListCard = () => {
    const { monitorId: monitorIdParams } = useParams();
    const [monitorId, setMonitorId] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (monitorIdParams === undefined) {
            setMonitorId(undefined);
            return;
        }

        setMonitorId(parseInt(monitorIdParams));
    }, [monitorIdParams]);

    const {
        data: readMonitorRunsResultCardData,
        isLoading: readMonitorRunsResultCardIsLoading
    } = useReadMonitorRunsResultCardQuery({
        monitorId: parseInt(monitorIdParams || '-1')
    });

    const cardsRender = () => {
        return (
            <>
                <MonitorRunResultDetailCard
                    monitorId={monitorId}
                    monitorRunsResultDetailCard={readMonitorRunsResultCardData}
                />
            </>
        )
    }

    return (
        <>
            {
                cardsRender()
            }
        </>
    )
}