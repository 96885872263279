import { http } from 'apiClient';

import type { ReadMonitorRunsResponse } from 'shared/types'
import type { ReadMonitorRunResponse } from 'shared/types'
import type { MonitorRun } from 'shared/types'


export interface readMonitorRunsCountParams {
    viewed?: boolean
}

export const readMonitorRunsCount = async (params?: readMonitorRunsCountParams): Promise<number> => {
    let url = `/monitorruns/count`;

    if (params?.viewed === true) {
        url+=`?viewed=true`
    }

    const response = await http.get(url);
    return response.data;
};


export interface ReadMonitorRunByIdParams {
   monitorRunId: number
}

export const readMonitorRunById = async (params: ReadMonitorRunByIdParams | undefined = undefined): Promise<ReadMonitorRunResponse> => {
    const url = `/monitorruns/${params?.monitorRunId}`;
    const response = await http.get(url);

    const result: ReadMonitorRunResponse = {
        monitorRun: response.data
    }

    return result;
}

export interface ReadMonitorRunsParams {
    pagination: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRuns = async (params: ReadMonitorRunsParams | undefined = undefined): Promise<ReadMonitorRunsResponse> => {
    let url = `/monitorruns`;

    if (params?.pagination) {
        const skip: number = params.pagination.page * params.pagination.pageSize;
        const take: number = params.pagination.pageSize;
        url = `/monitorruns?skip=${skip}&take=${take}`;
    }

    const response = await http.get(url);

    return response.data;
};

export interface ReadMonitorRunsByMonitorIdParams {
    monitorId: number,
    pagination?: {
        page: number,
        pageSize: number
    }
}

export const readMonitorRunsByMonitorId = async (params: ReadMonitorRunsByMonitorIdParams): Promise<ReadMonitorRunsResponse> => {
    let url = `monitor/${params.monitorId}/monitorruns`;

    if (params?.pagination) {
        const skip: number = params.pagination.page * params.pagination.pageSize;
        const take: number = params.pagination.pageSize;
        url+= `?skip=${skip}&take=${take}`;
    }

    const response = await http.get(url);
    return response.data;
}

export const monitorRunService = {
    readMonitorRunById,
    readMonitorRuns,
    readMonitorRunsByMonitorId,
    readMonitorRunsCount,
}