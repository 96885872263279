import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'queries';

interface DeleteMonitorQueryParams {
    monitorId: number
}

export const pushNotificationSubscriptionApi = createApi({
    reducerPath: 'pushNotificationSubscriptionApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: [],
    endpoints: (builder) => ({
        sendTestNotification: builder
            .mutation({
                query: (subscription: any) => ({
                    url: `webdeviceendpoints/test`,
                    method: 'POST',
                    body: {endpoint: subscription.endpoint}
                })
            }),
        createPushNotificationSubscription: builder
            .mutation({
                query: (subscription: any) => ({
                    url: `webdeviceendpoints`,
                    method: 'POST',
                    body: subscription
                }),
            }),
        deletePushNotificationSubscription: builder
            .mutation({
                query: (subscription: any) => ({
                    url: `webdeviceendpoints`,
                    method: 'DELETE',
                    body: subscription
                })
            })

    }),
});

export const {
    useCreatePushNotificationSubscriptionMutation,
    useDeletePushNotificationSubscriptionMutation,
    useSendTestNotificationMutation
} = pushNotificationSubscriptionApi;
