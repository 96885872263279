import * as React from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { Paper } from '@mui/material';
import { LogoIcon } from 'shared/icons/LogoIcon';
import Divider from '@mui/material/Divider';

export interface AboutProps {
}

export const About = (props: AboutProps): JSX.Element => {
    const containerStyle = {
        padding: '10px 40px'
    }

    return (
        <>
            <Paper elevation={3} >
                <Box sx={containerStyle}>
                    <LogoIcon sx={{
                        width: 'auto',
                        height: '32px'
                    }} />
                </Box>       
                <Divider></Divider>
                <Box sx={containerStyle}>
                    <Box>
                        Version {process.env.REACT_APP_VERSION || "-"}
                    </Box>
                </Box>  

            </Paper>

        </>
    )
}