import type { BedSelectItem } from 'shared/types'

export const BEDROOM_COUNTS: BedSelectItem[] = [
    {
        label: 'Any',
        value: 0
    },
    {
        label: '1',
        value: 1
    },
    {
        label: '2',
        value: 2
    },
    {
        label: '3',
        value: 3
    },
    {
        label: '4',
        value: 4
    },
    {
        label: '5',
        value: 5
    },
    {
        label: '6',
        value: 6
    },
    {
        label: '7',
        value: 7
    },
    {
        label: '8',
        value: 8
    },
];