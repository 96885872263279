import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement
} from 'chart.js';


import { LineElement } from 'chart.js';
import { Title } from 'chart.js';
import { Tooltip } from 'chart.js';
import { Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DateTime } from 'luxon';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

type HostServiceDetailPriceListChartProps = {
    data?: any
}

export const HostServiceDetailPriceListChart = (
    {
        data
    }: HostServiceDetailPriceListChartProps
): JSX.Element => {
    const theme = useTheme();
    const smOrMore = useMediaQuery(theme.breakpoints.up('sm'));
    const lgOrMore = useMediaQuery(theme.breakpoints.up('lg'));

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: false,
                text: 'Prices',
                font: () => {
                    if (lgOrMore) {
                        return {
                            size: 20
                        }
                    }

                    if (smOrMore) {

                        return {
                            size: 18
                        }
                    }


                    return {
                        size: 16
                    }
                }
            },
            legend: {
                labels: {
                    font: {
                        size: 24
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (context.dataset.parsing.yAxisKey === 'resultsCount') {
                            label += `: ${context.parsed.y}`;
                            return label;
                        }

                        if (context.parsed.y !== null) {
                            label += `: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)}`;
                        }

                        return label;
                    }
                }
            }
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    callback: (value: any, index: any, ticks: any) => {
                        return '$' + value;
                    }
                }
            }
        },
    };

    const transormedData = data.map((resultItem: any) => {
        const dateTimeCheckIn = DateTime.fromISO(resultItem.checkin).toFormat('M/dd/yyyy');
        const dateTimeCheckOut = DateTime.fromISO(resultItem.checkout).toFormat('M/dd/yyyy');
        const date = `${dateTimeCheckIn} - ${dateTimeCheckOut}`;

        return {
            date: date,
            priceTotal: `${resultItem.priceTotal}`,
            originalPrice: resultItem.originalPrice,
            discountedPrice: resultItem.discountedPrice,
        }
    });

    const chartData = {
        datasets: [
            {
                label: 'Original Price',
                data: transormedData,
                parsing: {
                    yAxisKey: 'originalPrice',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(100, 221, 23)',
                backgroundColor: 'rgba(100, 221, 23, 0.5)',
            },
            {
                label: 'Total Price',
                data: transormedData,
                parsing: {
                    yAxisKey: 'priceTotal',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(255, 61, 1)',
                backgroundColor: 'rgba(255, 61, 1, 0.5)',
                yAxisID: 'y',
            },

            {
                label: 'Discounted Price',
                data: transormedData,
                parsing: {
                    yAxisKey: 'discountedPrice',
                    xAxisKey: 'date',
                },
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ],
    };

    return (
        <>
            <Line options={options} data={chartData} />
        </>
    )
}
