import * as React from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
export interface ShareDialogProps {
    keepMounted: boolean;
    open: boolean;
    onCancel: (value?: string) => void;
    onClose: () => void;
}

export const ShareDialog = (props: ShareDialogProps): JSX.Element => {
    const {
        onCancel,
        open,
        onClose,
        ...other
    } = props;

    const cancelHandler = () => {
        onCancel();
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: 'max(20vh, 400px)'
    }

    const handleClickShareButton = () => {
        if (navigator.share) {
            navigator.share({
                title: 'StrSpy',
                text: 'Check out StrSpy',
                url: 'https://strspy.com/',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    const fallbackCopyTextToClipboard = (text: string) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    const copyTextToClipboard = (text: string) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    const handleClickCopyClipboard = () => {
        copyTextToClipboard('https://strspy.com/')
    }

    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%'} }}
                maxWidth="xs"
                open={open}
                onClose={onClose}
                
                {...other}
            >    
                <DialogContent dividers>       

                    <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }} >
                            <Box sx={{ fontWeight: '600' }}>Share this app with others.</Box>
                            <Box sx={{ fontWeight: '600' }}>It's FREE!</Box>
                        </Box>
                  
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <TextField size="small" variant="outlined" value="https://strspy.com/" />
                            <Button
                                color="success"
                                variant="contained"
                                startIcon={<ContentCopyIcon />}
                                onClick={handleClickCopyClipboard}
                            >
                                Copy
                            </Button>
                        </Box>
                        <div>
                            <Button
                                color="success"
                                variant="contained"
                                startIcon={<ShareIcon sx={{ color: '#FFF' }} />}
                                onClick={handleClickShareButton}
                            >
                                Share
                            </Button>
                        </div>
                        <Box sx={{ textAlign: 'center' }}>
                            <Divider>Scan To Go App</Divider>
                            <picture>
                                <img className="main-nav-logo__image" style={imageStyle} src="/images/share/share-qr-strspy-com.png" alt="" loading="lazy" />
                            </picture>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={cancelHandler}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}