import type { MonitorRunResultDetail } from 'shared/types'

export const createBlankMonitorRunResultDetail = (): MonitorRunResultDetail => {
    const monitorRunResultDetail: MonitorRunResultDetail = {
        id: undefined,
        monitor: undefined,
        monitorRun: undefined,
        hostServices: {
            count: 0,
            list: [],
        },
        monitorRuns: {
            count: 0,
            list: [],
        }

    }

    return monitorRunResultDetail;
}