import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import { Badge } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useReadMonitorsShortQuery } from 'services/monitor';
import { useReadMonitorRunCountQuery } from 'services/monitorRun';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShareIcon from '@mui/icons-material/Share';
import { ShareDialog } from 'components/ShareDialog';
import type { Monitor } from 'shared/types'
import type { ReadMonitorRunCountParams } from 'services/monitorRun'

export type ApplicationMainDrawerMenuProps = {
}

export const ApplicationMainDrawerMenu = (): JSX.Element => {  
    const useReadMonitorsShortQueryOptions = {
        pollingInterval: 5000,
    };

    const {
        data: readMonitorsShortQueryData,
        isLoading: readMonitorsShortQueryIsLoading
    } = useReadMonitorsShortQuery(undefined, useReadMonitorsShortQueryOptions);

    const useReadMonitorRunCountQueryParams: ReadMonitorRunCountParams = {
        viewed: false
    };

    const useReadMonitorRunCountQueryOptions = {
        pollingInterval: 5000,
    };

    const {
        data: readMonitorRunCountData,
        isLoading: readMonitorRunCountIsLoading
    } = useReadMonitorRunCountQuery(useReadMonitorRunCountQueryParams, useReadMonitorRunCountQueryOptions);

    const [open, setOpen] = useState(true);
    const [openShareDialog, setOpenShareDialog] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const styleShareButton = {
        color: '#FFF',
        backgroundColor: 'success.light',
        '&:hover': {
            backgroundColor: 'success.dark'
        }
    }

    const handleClickShareButton = () => {
        setOpenShareDialog(true);
    }

    const handleCancelShareDialog = () => {
        setOpenShareDialog(false);
    }

    return (
        <>
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                overflow: 'auto',
                '& .MuiListItemText-primary': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }
            }}
            component="ul"
        >
            <ListItem
                key={'monitors'}
                secondaryAction={
                    <Box>
                        {
                            readMonitorsShortQueryIsLoading !== true &&
                            <Badge badgeContent={readMonitorsShortQueryData?.count} sx={{
                                mr: '10px',
                                "& .MuiBadge-badge": {
                                    color: "#FFF",
                                    backgroundColor: "#222831"
                                }
                            }} />
                        }

                    </Box>
                }
                disablePadding
            >
                <ListItemButton component={Link} to="/spy" key="spy">
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Spies:" />
                </ListItemButton>
            </ListItem>
            <ListItem
                key={'reports'}
                secondaryAction={
                    <Box>
                        {
                            readMonitorRunCountIsLoading !== true &&<Badge badgeContent={ readMonitorRunCountData } sx={{
                                mr: '10px',
                                "& .MuiBadge-badge": {
                                    color: "#FFF",
                                    backgroundColor: "#222831"
                                }
                            }} />

                        }

                        <IconButton edge="end" aria-label="comments" onClick={handleClick}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                }
                disablePadding
            >
                <ListItemButton role={undefined} key="monitorrunresult">
                    <ListItemIcon>
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Results:" />
                </ListItemButton>
            </ListItem>           
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        readMonitorsShortQueryIsLoading !== true && readMonitorsShortQueryData?.list.map((monitorShort: Monitor, index: number) => {
                            const nodeId: string = monitorShort?.id?.toString() || index.toString();

                            return (
                                <ListItem
                                    sx={{
                                        backgroundColor: '#EEEEEE',
                                        paddingLeft: '24px'
                                    }}
                                    key={nodeId}
                                    secondaryAction={
                                        <Box>
                                            { 
                                                monitorShort.newRunsCount > 0 &&                                    
                                                <Badge badgeContent={ monitorShort.newRunsCount } sx={{
                                                    mr: '10px',
                                                    "& .MuiBadge-badge": {
                                                        color: "#FFF",
                                                        backgroundColor: "#222831"
                                                    }
                                                    }} />
                                            }
                                        </Box>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton component={Link} to={`/spy/${monitorShort.id}/monitorrun`} key="monitor">
                                        <ListItemIcon>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={ monitorShort.name } />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
            <ListItemButton component={Link} to="/notifications" key="notifications">
                <ListItemIcon>
                    <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="View Notifications" />
            </ListItemButton>
            <ListItemButton sx={styleShareButton} key="shareApp" onClick={handleClickShareButton}>
                <ListItemIcon color="success">
                    <ShareIcon sx={{ color: '#FFF' }} />
                </ListItemIcon>
                <ListItemText color="success" primary="Share App" />
            </ListItemButton>
            </List>


            <ShareDialog
                keepMounted={true}
                open={openShareDialog}
                onClose={handleCancelShareDialog}
                onCancel={handleCancelShareDialog}
            />
        </>
    );
}