import { useEffect } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { ApplicationMainBar } from 'components/ApplicationMainBar';
import { ApplicationMainDrawer } from 'components/ApplicationMainDrawer';
import { useServiceWorker } from 'shared/hooks';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export const MainLayout = () => {
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (showReload && waitingWorker) {
            setOpen(true);

            return;
        }

        setOpen(false);
    }, [waitingWorker, showReload, reloadPage]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const handleOk = () => {
        setOpen(false);
        reloadPage();

    }

    return (
        <> 
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <ApplicationMainBar></ApplicationMainBar>
                <ApplicationMainDrawer></ApplicationMainDrawer>
                <Box component="main" sx={{
                    display: 'grid',
                    maxWidth: '100%',
                    width: '100%',           

                    padding: {
                        xs: '8px',
                        md: '12px'
                    },
                    position: 'relative'
                }}>
                    <Toolbar sx={{height: '84px'}} />

                    <Box sx={{ margin: '0 0 80px 0' }}>
                        <Outlet />
                    </Box>                        
                </Box>              
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"New version available"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update to new version?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel}>
                         Cancel
                    </Button>
                    <Button onClick={handleOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}