import type { PetSelectItem } from 'shared/types'

export const HOSTSERVICE_SORT_FIELDS: any[] = [
    {
        label: 'City',
        value: 'CITY'
    },
    {
        label: 'Rating',
        value: 'RATING'
    },
    {
        label: 'Ratings count',
        value: 'RATINGSCOUNT'
    },

    //{
    //    label: 'Latitude',
    //    value: 'LATITUDE'
    //},
    //{
    //    label: 'Longitude',
    //    value: 'LONGITUDE'
    //},
    {
        label: 'Name',
        value: 'NAME'
    },
    {
        label: 'Bedrooms',
        value: 'BEDROOMS'
    },
    {
        label: 'Beds',
        value: 'BEDS'
    },
    {
        label: 'Pets',
        value: 'PETS'
    },
    {
        label: 'Is mine',
        value: 'ISMINE'
    },
    {
        label: 'Original price ',
        value: 'ORIGINALPRICE'
    },
    {
        label: 'Discounted price',
        value: 'DISCOUNTEDPRICE'
    },
    {
        label: 'Price Total',
        value: 'PRICETOTAL'
    },
    //{
    //    label: 'Qualifier',
    //    value: 'QUALIFIER'
    //},
    //{
    //    label: 'Short Qualifier',
    //    value: 'SHORTQUALIFIER'
    //},     
];